import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import {SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as FaceGrinHearts} from "assets/icons/face-grin-hearts.svg";
import {ReactComponent as FaceSmile} from "assets/icons/face-smile.svg";
import {ReactComponent as FaceMeh} from "assets/icons/face-meh.svg";
import {ReactComponent as FaceFrown} from "assets/icons/face-frown.svg";
import {ReactComponent as FaceAngry} from "assets/icons/face-angry.svg";
import {satisfaction} from "configuration/constants";
import PropTypes from "prop-types";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import Text from "connectui/component/display/Text";
import FlexContainer from "connectui/component/layout/FlexContainer";
import {useTheme} from "connectui/theme/ThemeProvider";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  },
  chipContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
});

const icons = {
  highly_satisfied: FaceGrinHearts,
  satisfied: FaceSmile,
  neutral: FaceMeh,
  somewhat_satisfied: FaceFrown,
  unsatisfied: FaceAngry,
};

const colors = {
  highly_satisfied: "success",
  satisfied: "bluelight",
  neutral: "bluegray",
  somewhat_satisfied: "warning",
  unsatisfied: "error",
  unknown: "blue",
};

function renderFilter({theme, fieldData, handleValueChange}) {
  const options = Object.values(satisfaction).map(({label}) => label);

  return (
    <SelectChipContainer
      options={options}
      selectedOptions={fieldData.val}
      onChange={(newValue) => handleValueChange(fieldData.type, newValue)}
      multiSelect={false}
      customChipColor={(styleProp, option, isSelected) => {
        const key = Object.keys(satisfaction).find(
          (k) => satisfaction[k].label === option,
        );
        switch (styleProp) {
          case "backgroundColor":
            return isSelected
              ? theme.colors[`${colors[key]}50`]
              : theme.colors.gray100;
          case "color":
            return isSelected
              ? theme.colors[`${colors[key]}700`]
              : theme.colors.gray700;
          case "hover-backgroundColor":
            return isSelected
              ? theme.colors[`${colors[key]}100`]
              : theme.colors.gray200;

          default:
            return theme.colors.primary;
        }
      }}
      customRender={(option, isSelected) => {
        const key = Object.keys(satisfaction).find(
          (k) => satisfaction[k].label === option,
        );
        return (
          <FlexContainer alignItems="center" gap={theme.spacing(1.5)}>
            {!!icons[key] && (
              <SvgIcon component={icons[key]} style={{width: 16, height: 16}} />
            )}
            <Text
              variant="textSM"
              weight="medium"
              color={isSelected ? `${colors[key]}700` : "gray700"}
            >
              {option}
            </Text>
          </FlexContainer>
        );
      }}
    />
  );
}

const SentimentField = ({fieldData, handleValueChange, options, ...props}) => {
  const {theme} = useTheme();
  const renderInput = () => {
    const labelToValue = Object.values(satisfaction).reduce(
      (map, {label, value}) => {
        map[label] = value;
        return map;
      },
      {},
    );

    const options = Object.values(satisfaction).map(({label}) => label);

    const classes = buildStyles(styles);
    return (
      <SelectChipContainer
        options={options}
        multiSelect={true}
        selectedOptions={
          Array.isArray(fieldData.val)
            ? fieldData.val.map(
                (v) =>
                  Object.values(satisfaction).find((s) => s.value === v)?.label,
              )
            : []
        }
        onChange={(newSelection) => {
          const newValues = newSelection.map(
            (label) =>
              Object.values(satisfaction).find((s) => s.label === label)?.value,
          );
          handleValueChange(fieldData.type, newValues);
        }}
        customRender={(option, isSelected) => {
          const key = Object.keys(satisfaction).find(
            (k) => satisfaction[k].label === option,
          );
          return (
            <div className={classes.chipContent}>
              <SvgIcon
                component={icons[key] || FaceMeh}
                style={{width: 24, height: 24}}
              />
              <Typography
                variant="body2"
                color={isSelected ? "primary" : "textSecondary"}
              >
                {option}
              </Typography>
            </div>
          );
        }}
      />
    );
  };

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  const getLabel = () => {
    let value = fieldData?.val;
    if (!value) {
      return satisfaction.unknown.label?.toLowerCase();
    }
    if (value instanceof Array) {
      return value
        .map((v) =>
          Object.values(satisfaction)
            .find((s) => s.value === v)
            ?.label.toLowerCase()
            .replace(/_/g, " "),
        )
        .join(", ");
    }
    // Just in case.....
    return (
      Object.values(satisfaction).find((s) => s.value === value)?.label || value
    );
  };

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      renderFilter={() => renderFilter({theme, fieldData, handleValueChange})}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

SentimentField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SentimentField;
