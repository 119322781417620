import React from "react";
import PropTypes from "prop-types";
import CardBase from "./CardBase";
import Container from "../../layout/Container";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import RadioButton from "../../input/RadioButton";

const useStyles = (theme) =>
  buildStyles({
    buttonLoc: {
      position: "absolute",
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
  });

/**
 * ToggleCardComponent extends CardBase with additional toggle functionality via a RadioButton.
 * It supports overriding all content with `customContent` or defaults to rendering an icon, title, description, and action row.
 *
 * @param {Object} props - The props for the ToggleCardComponent.
 * @param {React.ReactNode} [props.icon] - The icon to display in the card.
 * @param {React.ReactNode} [props.title] - The title text for the card.
 * @param {React.ReactNode} [props.description] - The description text for the card.
 * @param {React.ReactNode} [props.actionRow] - Optional action row, e.g., buttons or links.
 * @param {boolean} [props.isSelected=false] - Whether the toggle card is selected.
 * @param {function} [props.onToggle] - Callback when the toggle is changed.
 * @param {React.ReactNode} [props.customContent] - Fully overrides default behavior and renders custom content inside the card.
 * @param {Object} [props.sx={}] - Style overrides for the card container.
 * @returns {JSX.Element} The rendered ToggleCardComponent.
 */
const ToggleCardComponent = ({
  icon = null,
  title = null,
  description = null,
  actionRow = null,
  isSelected = false,
  onToggle,
  customContent = null,
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <CardBase
      icon={icon}
      title={title}
      description={description}
      actionRow={actionRow}
      onClick={() => onToggle()}
      customContent={
        customContent || (
          <>
            {icon && <Container>{icon}</Container>}
            {title && (
              <Container
                sx={{textAlign: "left", display: "block", width: "100%"}}
              >
                {title}
              </Container>
            )}
            {description && (
              <Container
                sx={{textAlign: "left", display: "block", width: "100%"}}
              >
                {description}
              </Container>
            )}
            {actionRow && (
              <Container sx={{marginTop: theme.spacing(3)}}>
                {actionRow}
              </Container>
            )}
            <Container className={classes.buttonLoc}>
              <RadioButton checked={isSelected} />
            </Container>
          </>
        )
      }
      sx={{
        ...sx,
        position: "relative",
        border: `1px solid ${
          isSelected ? theme.colors.primary : theme.colors.gray300
        }`,
        "&:hover": {
          borderColor: theme.colors.primary,
        },
      }}
      {...props}
    />
  );
};

ToggleCardComponent.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  actionRow: PropTypes.node,
  isSelected: PropTypes.bool,
  onToggle: PropTypes.func,
  customContent: PropTypes.node,
  sx: PropTypes.object,
};

ToggleCardComponent.defaultProps = {
  icon: null,
  title: null,
  description: null,
  actionRow: null,
  isSelected: false,
  onToggle: () => {},
  customContent: null,
  sx: {},
};

export default ToggleCardComponent;
