import React, {useState} from "react";
import Container from "../Container";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import SvgIcon from "../../display/SvgIcon";
import {ReactComponent as ArrowUpIcon} from "../../../icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../../icons/chevron-down.svg";
import PropTypes from "prop-types";

const useStyles = (theme, props) =>
  buildStyles({
    listItemWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "100%",
    },
    listItemContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2, 3),
      borderRadius: `${theme.radius.md}px`,
      cursor: "pointer",
      transition: "background-color 0.2s ease",
      width: "100%",
      "&:hover": {
        backgroundColor: theme.colors.gray100,
      },
    },
    nestedContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    arrow: {
      width: "10px",
      height: "10px",
      color: theme.colors.gray400,
    },
  });

/**
 * ListItemContainer is a component that renders a list item with an optional collapsible nested list.
 * It supports custom toggle components and indentation for nested items.
 *
 * @param {Object} props - The props for the ListItemContainer component.
 * @param {React.ReactNode} [props.nestedList] - The nested list to display inside the list item when expanded.
 * @param {React.ReactNode} [props.children] - The content of the list item.
 * @param {React.ReactNode} [props.collapseToggleComponent=null] - A custom component to toggle the collapse state (defaults to an arrow icon).
 * @param {boolean} [props.defaultCollapsed=false] - The initial collapsed state of the nested list.
 * @param {any} [props.indentSpacing=null] - The indentation for nested list items.
 * @param {Object} [props.sx={}] - The style object that can be used to override default styles.
 * @param {Function} [props.onClick] - The function to call when the list item is clicked.
 * @param {Object} [props.itemProps={}] - Any other props to pass to the list item content.
 *
 * @returns {JSX.Element} The rendered ListItemContainer component.
 */
const ListItemContainer = ({
  nestedList,
  children,
  collapseToggleComponent = null,
  defaultCollapsed = false,
  indentSpacing = null,
  sx,
  onClick = () => null,
  itemProps = {},
  ...props
}) => {
  const hasNestedContent = !!nestedList;
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const handleToggle = () => {
    onClick();
    if (hasNestedContent) {
      setIsCollapsed((prev) => !prev);
    }
  };

  return (
    <Container className={classes.listItemWrapper} {...props}>
      <Container
        className={classes.listItemContent}
        onClick={handleToggle}
        {...itemProps}
      >
        {children && children}

        {hasNestedContent &&
          (collapseToggleComponent ? (
            React.cloneElement(collapseToggleComponent, {isCollapsed})
          ) : (
            <SvgIcon
              component={isCollapsed ? ArrowDownIcon : ArrowUpIcon}
              className={classes.arrow}
            />
          ))}
      </Container>

      {hasNestedContent && !isCollapsed && (
        <Container
          className={classes.nestedContainer}
          sx={{marginLeft: indentSpacing ?? theme.spacing(3)}}
          onClick={onClick}
        >
          {nestedList}
        </Container>
      )}
    </Container>
  );
};

ListItemContainer.propTypes = {
  nestedList: PropTypes.node,
  children: PropTypes.node,
  collapseToggleComponent: PropTypes.node,
  defaultCollapsed: PropTypes.bool,
  indentSpacing: PropTypes.string,
  sx: PropTypes.any,
  onClick: PropTypes.func,
  itemProps: PropTypes.object,
};

export default ListItemContainer;
