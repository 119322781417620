import {useTheme} from "../../../../../connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../../connectui/component/buildStyles";
import Text from "../../../../../connectui/component/display/Text";
import React from "react";
import DropdownExpand from "../../../../../connectui/component/display/dropdown/DropdownExpand";
import BookingInfoDrawer from "../../../../../components/Panels/Booking/BookingInfoDrawer";

const useStyles = (theme) =>
  buildStyles({
    baseContainer: {},
  });

export default function ListingSection({guestId}) {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <DropdownExpand
      divider={false}
      // expanded={true}
      customLabel={<Text variant={"displayXS"}>{"Listings"}</Text>}
    >
      <BookingInfoDrawer hideRedirectIcon />
    </DropdownExpand>
  );
}
