import React, {useState} from "react";
import Container from "../Container";
import Text from "../../display/Text";
import SvgIcon from "../../display/SvgIcon";
import {ReactComponent as ArrowUpIcon} from "../../../icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../../icons/chevron-down.svg";
import {buildStyles} from "../../buildStyles";
import {useTheme} from "../../../theme/ThemeProvider";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    listContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    listHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    },
    listLabel: {
      fontSize: theme.typography.textSM.fontSize,
      fontWeight: theme.typography.textSM.semibold.fontWeight,
      color: theme.colors.textPrimary,
      display: "flex",
      alignItems: "center",
    },
    list: {
      display: "none",
      flexDirection: "column",
      padding: 0,
      margin: 0,
    },
    listVisible: {
      display: "flex",
    },
    arrow: {
      width: "10px",
      height: "10px",
      color: theme.colors.gray400,
      flexShrink: 0,
      position: "relative",
      top: "-10px",
    },
  });

/**
 * ListComponent is a component that renders a list with an optional label and custom indentation and spacing.
 * It supports collapsibility, allowing the list to be expanded or collapsed using an arrow toggle.
 *
 * @param {Object} props - The props for the ListComponent.
 * @param {string} [props.label] - The label to display above the list.
 * @param {boolean} [props.collapsible] - Whether the list is collapsible.
 * @param {Object} [props.labelProps] - Additional properties to pass to the label element.
 * @param {React.ReactNode} props.children - The list items to display inside the component.
 * @param {string} [props.indent] - The amount of indentation for the list items.
 * @param {string} [props.gap] - The gap between the list items.
 * @param listStyles - Additional styles to pass to the list container.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered ListComponent.
 */
const ListComponent = ({
  label,
  collapsible = false,
  labelProps,
  children,
  indent,
  gap,
  listStyles = {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const toggleList = () => {
    if (collapsible) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Container className={classes.listContainer} {...props}>
      {label && (
        <Container
          className={classes.listHeader}
          onClick={collapsible ? toggleList : undefined}
        >
          <Text className={classes.listLabel} {...labelProps}>
            {label}
          </Text>
          {collapsible && (
            <SvgIcon
              component={isOpen ? ArrowUpIcon : ArrowDownIcon}
              className={classes.arrow}
            />
          )}
        </Container>
      )}
      <Container
        className={`${classes.list} ${isOpen ? classes.listVisible : ""}`}
        sx={{
          marginLeft: indent ? indent : undefined,
          gap: gap ? gap : theme.spacing(3),
          ...listStyles,
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

ListComponent.propTypes = {
  label: PropTypes.string,
  collapsible: PropTypes.bool,
  listStyles: PropTypes.object,
  labelProps: PropTypes.object,
  children: PropTypes.node,
  indent: PropTypes.string,
  gap: PropTypes.string,
};

export default ListComponent;
