import React from "react";
import PropTypes from "prop-types";
import Container from "../layout/Container";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";

const useStyles = (theme) =>
  buildStyles({
    profilePicture: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      backgroundColor: theme.colors.primary100,
      color: theme.colors.primary700,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "uppercase",
      overflow: "hidden",
    },
    profileImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  });

const getInitials = (name) => {
  if (!name) return "?";
  const words = name.split(" ");
  return words
    .map((word) => word[0])
    .slice(0, 2)
    .join("");
};

const ProfilePicture = ({name, image, sx}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const initials = getInitials(name);

  return (
    <Container as="div" className={classes.profilePicture} sx={sx}>
      {image ? (
        <img src={image} alt={name} className={classes.profileImage} />
      ) : (
        initials
      )}
    </Container>
  );
};

ProfilePicture.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default ProfilePicture;
