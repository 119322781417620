import React from "react";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import PropTypes from "prop-types";

const ProductNamesField = ({
  fieldData,
  handleValueChange,
  customFieldData,
  ...props
}) => {
  const options =
    customFieldData["Product Names"] &&
    customFieldData["Product Names"].length > 0
      ? customFieldData["Product Names"].map((product) =>
          product.name?.toLowerCase(),
        )
      : [];

  const renderInput = () => (
    <SelectChipContainer
      options={options}
      selectedOptions={fieldData?.val}
      onChange={(newSelection) => {
        handleValueChange(
          fieldData.type,
          Array.isArray(newSelection)
            ? newSelection.filter((f) => f !== "")
            : newSelection,
        );
      }}
      multiSelect={true}
    />
  );

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      renderFilter={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

ProductNamesField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default ProductNamesField;
