import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AIIcon} from "assets/icons/ai.svg";
import {ReactComponent as MessagesIcon} from "assets/icons/messages-group.svg";
import {ReactComponent as BoardingPassIcon} from "assets/icons/boarding-pass.svg";
import {Skeleton} from "@material-ui/lab";
import CustomMenu from "core/menus/CustomMenu";
import {getTrimmedCommandSubheader} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  list: {
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(3) + "px!important",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderRadius: 10,
    "&.selected": {
      backgroundColor: theme.palette.secondary.main + "!important",
    },
  },
  skeleton: {
    backgroundColor: theme.palette.grey[200],
    transform: "none",
    borderRadius: 10,
  },
  itemText: {margin: 0},
  title: {
    fontWeight: 500,
    "&.selected": {color: theme.palette.common.white + "!important"},
  },
  subheader: {
    color: THEME.subdued,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    lineClamp: 3,
    "&.selected": {color: theme.palette.common.white + "!important"},
  },
  itemIcon: {
    minWidth: 0,
    "&.selected": {color: theme.palette.common.white + "!important"},
  },
  bp: {color: THEME.cannedResponseTypeColors.bp},
  ai: {color: THEME.cannedResponseTypeColors.ai},
  experience: {color: THEME.cannedResponseTypeColors.experience},
  other: {color: THEME.cannedResponseTypeColors.other},
}));

const responseTypeIcons = {
  bp: {viewBox: "0 0 21 14", component: BoardingPassIcon},
  ai: {viewBox: "0 0 18 18", component: AIIcon},
  experience: {viewBox: "0 0 24 24", component: MessagesIcon},
  other: {viewBox: "0 0 24 24", component: MessagesIcon},
};

const CommandsMenu = ({
  data = [],
  selectedIndex,
  searchText,
  menuRef,
  anchorEl,
  onClose,
  onSelect,
}) => {
  const classes = useStyles();

  const highlightText = (text, index) => {
    if (index === -1) return text;
    const length = searchText.length;
    return (
      <>
        {text.substring(0, index)}
        <span style={{backgroundColor: "yellow"}}>
          {text.substring(index, index + length)}
        </span>
        {text.substring(index + length)}
      </>
    );
  };

  return (
    <CustomMenu
      open={!!anchorEl && !!data.length}
      anchorEl={anchorEl}
      overflowAuto
      maxHeight={300}
      menuRef={menuRef}
      placement="top-start"
      transformOrigin="bottom left"
      onClose={onClose}
      content={
        <List className={classes.list}>
          {data.map((d, i) =>
            !!d.loading ? (
              <Skeleton
                key={i}
                width={200}
                height={52}
                className={classes.skeleton}
              />
            ) : (
              <ListItem
                button
                disableRipple
                key={`${d.title}-${i}`}
                component={"li"}
                onClick={() => onSelect(d)}
                className={clsx(classes.item, {selected: selectedIndex === i})}
              >
                <ListItemIcon
                  className={clsx(classes.itemIcon, classes[d.type], {
                    selected: selectedIndex === i,
                  })}
                >
                  <SvgIcon
                    viewBox={responseTypeIcons[d.type].viewBox}
                    component={responseTypeIcons[d.type].component}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={highlightText("/" + d.title, d.titleIndex)}
                  secondary={
                    d.subheaderIndex !== -1
                      ? highlightText(
                          `${d.subheaderIndex > 100 ? "..." : ""}${getTrimmedCommandSubheader(d.subheader, d.subheaderIndex)}`,
                          d.subheaderIndex > 100 ? 3 : d.subheaderIndex,
                        )
                      : d.subheader
                  }
                  className={classes.itemText}
                  primaryTypographyProps={{
                    className: clsx(classes.title, {
                      selected: selectedIndex === i,
                    }),
                  }}
                  secondaryTypographyProps={{
                    variant: "caption",
                    className: clsx(classes.subheader, {
                      selected: selectedIndex === i,
                    }),
                  }}
                />
              </ListItem>
            ),
          )}
        </List>
      }
    />
  );
};

export default CommandsMenu;
