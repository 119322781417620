import React, {useState, useEffect, useContext} from "react";
import {useDispatch} from "react-redux";
import {fieldParams} from "configuration/specs";
import {getCustomFields} from "redux/actions/settingsActions";
import {useTranslation} from "react-i18next";
import {getFilterCount} from "utilities/helperFunctions";
import {InboxContext} from "routes/MessagesPage";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";
// components
import SvgIcon from "connectui/component/display/SvgIcon";
import FlexContainer from "connectui/component/layout/FlexContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "connectui/component/input/text/OutlinedInputBase";
import ScrollableContainer from "connectui/component/layout/ScrollableContainer";
import DropdownExpand from "connectui/component/display/dropdown/DropdownExpand";
import ChipText from "connectui/component/display/chip/ChipText";
import FilterItemComponent from "../../filterlist/FilterItemComponent";

const availableFilters = Object.entries(fieldParams).filter(
  // Skip entries without filters available
  ([fieldName, fieldConfig]) => !!fieldConfig.filter_available,
);

const useStyles = (theme) =>
  buildStyles({
    searchIcon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(2),
    },
    xIcon: {
      width: "11px",
      height: "11px",
      cursor: "pointer",
      color: theme.colors.gray500,
    },
  });

const FilterList = ({filters, setFilters}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {customFields, setCustomFields} = useContext(InboxContext);
  const [expanded, setExpanded] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterOptions, setFilterOptions] = useState(availableFilters);

  const updateCustomFields = () => {
    if (Object.keys(customFields).length) return;
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: [], // Add other calls if needed
        onSuccess: (response) => {
          return !isMounted ? null : setCustomFields(response ?? {});
        },
      }),
    );

    return () => {
      isMounted = false;
    };
  };
  useEffect(updateCustomFields, []);

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchText(val);
    setFilterOptions(
      !!val
        ? availableFilters.filter(([fieldName, fieldConfig]) =>
            (fieldConfig.name ?? fieldName)
              .toLowerCase()
              .includes(val.toLowerCase()),
          )
        : availableFilters,
    );
  };

  return (
    <>
      <FlexContainer px={theme.spacing(4)} width={"100%"}>
        <TextInput
          inputBase={OutlinedInputBase}
          startIcon={
            <SvgIcon
              component={ICONS.search}
              className={classes.searchIcon}
              sx={{color: theme.colors.gray500}}
            />
          }
          inputProps={{
            onChange: handleSearchChange,
            value: searchText,
            placeholder: t("search"),
          }}
          actions={
            !!searchText && (
              <SvgIcon
                component={ICONS.x}
                className={classes.xIcon}
                onClick={() => {
                  setSearchText("");
                  setFilterOptions(availableFilters);
                }}
              />
            )
          }
        />
      </FlexContainer>
      <ScrollableContainer
        width={"100%"}
        height={"100%"}
        p={theme.spacing(0, 4, 4)}
      >
        {filterOptions.map(([fieldName, fieldConfig]) => {
          const count = getFilterCount(fieldName, filters);
          const isExpanded = expanded === fieldName;

          return (
            <DropdownExpand
              key={fieldName}
              label={fieldConfig.name || fieldName}
              actions={
                !!count && (
                  <ChipText
                    text={count}
                    sx={{
                      marginLeft: theme.spacing(2),
                      backgroundColor: "#e3edff",
                      "&:hover": {backgroundColor: "#cfe0fe"},
                    }}
                  />
                )
              }
              sx={{marginTop: theme.spacing(4.5)}}
              expanded={isExpanded}
              onExpand={() => setExpanded(isExpanded ? null : fieldName)}
            >
              {isExpanded && (
                <FilterItemComponent
                  field={fieldName}
                  selectedFilters={filters}
                  setFilters={setFilters}
                  customFields={customFields}
                />
              )}
            </DropdownExpand>
          );
        })}
      </ScrollableContainer>
    </>
  );
};

export default FilterList;
