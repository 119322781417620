import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "../../../../theme/ThemeProvider";
import {buildStyles} from "../../../buildStyles";
import Container from "../../../layout/Container";

/**
 * DropDownBase is a reusable component for dropdown containers, styled through its props.
 * Accepts `label` for the dropdown header and `children` for the dropdown content.
 *
 * @param {Object} props - Props for the DropDownBase component.
 * @param {React.ReactNode} props.label - The header or clickable label for the dropdown.
 * @param {React.ReactNode} props.children - The dropdown content (entries).
 * @param {Object} props.sx - Additional styles to override defaults for `label` and `content`.
 * @param {boolean} props.isOpen - Whether the dropdown content is visible.
 * @param {Function} props.onClick - Click handler for toggling dropdown.
 *
 * @returns {JSX.Element} The rendered DropDownBase component.
 */
const DropDownBase = ({
  label,
  children,
  sx = {},
  isOpen,
  onClick,
  ...props
}) => {
  const {theme} = useTheme();

  const classes = buildStyles({
    label: {
      minWidth: "200px",
      cursor: "select",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
      border: `1px solid ${theme.colors.gray300}`,
      borderRadius: isOpen
        ? `${theme.spacing(3)} ${theme.spacing(3)} 0 0`
        : theme.spacing(3),
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows.default,
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: theme.colors.gray100,
      },
      ...sx.label,
    },
    content: {
      minWidth: "200px",
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",

      boxShadow: theme.shadows.default,
      borderTop: "none",
      zIndex: 1000,
      backgroundColor: theme.colors.white,
      maxHeight: "300px",
      overflowY: "auto",
      borderRadius: isOpen
        ? `0 0 ${theme.spacing(3)} ${theme.spacing(3)}`
        : theme.spacing(3),
      ...sx.content,
    },
  });

  return (
    <Container {...props} style={{position: "relative"}}>
      <Container
        className={classes.label}
        onClick={onClick}
        role="button"
        aria-expanded={isOpen}
      >
        {label}
      </Container>
      {isOpen && <Container className={classes.content}>{children}</Container>}
    </Container>
  );
};

DropDownBase.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({
    label: PropTypes.object,
    content: PropTypes.object,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DropDownBase;
