import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, Redirect, Route, Switch, useLocation} from "react-router-dom";
// UI
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
//Custom Components
import Panel1 from "core/panels/Panel1.jsx";
import Panel2 from "core/panels/Panel2.jsx";
import CustomCardHeader from "core/cards/CustomCardHeader";
// routes
import Branding from "routes/BusinessSettings/Branding.jsx";
import Users from "routes/BusinessSettings/Users";
import ConnectedAccounts from "routes/BusinessSettings/ConnectedAccounts.jsx";
import MessagingSettings from "routes/BusinessSettings/MessagingSettings.jsx";
import Devices from "routes/BusinessSettings/Devices";
import Billing from "routes/BusinessSettings/Billing.jsx";
import BrandingEntryList from "routes/BusinessSettings/BrandingEntryList.jsx";
import {EmptyListPanel} from "../../components/Helpers/EmptyPanels";
// Redux
import {getBranding} from "redux/api/accountsAPI";
import {getServiceAccounts} from "redux/actions/servicesActions";
import {setSelectedViewItem} from "redux/actions/accountsActions";
// Utils
import {toSnakeCase} from "utilities/formatUtilities";
import {THEME} from "configuration/settings.js";
import {useTranslation} from "react-i18next";
import {isFirefox} from "react-device-detect";
import ConnectedAccountsRoute from "./ConnectedAccountsRoute";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(2, 4),
  },
  listItemText: {
    ...THEME.customTypography.title1,
    fontWeight: 500,
    opacity: 0.95,
  },
  cardHeader: {padding: theme.spacing(4, 4, 0)},
  panel2Container: {
    padding: theme.spacing(0, 4, 5),
    overflow: "auto",
    flex: 1,
    "&.-loading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  brandingContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  hiddenPanel3: {
    height: 0,
    width: isFirefox ? "-moz-available" : "-webkit-fill-available",
    maxWidth: 700,
    flex: "unset",
  },
}));

export default function BusinessSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  let savedItem = selectedViewItems.settings.item;
  const [redirect, setRedirect] = React.useState(savedItem);
  const [branding, setBranding] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (!Object.keys(serviceAccounts?.accounts ?? {}).length) {
      dispatch(getServiceAccounts({}));
    }
    if (!!savedItem) {
      setRedirect(null);
    } else if (
      !!location.pathname.replace("/admin/settings/", "") &&
      !savedItem
    ) {
      handleSelectRoute(location.pathname);
    }
    const fetchBranding = async () => {
      setLoading(true);
      try {
        const response = await getBranding("");
        setBranding(response);
      } catch (error) {
        console.error("Error fetching branding:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBranding();
  }, []);

  const routes = [
    {
      path: "/admin/settings/branding",
      name: "Branding",
      component: Branding,
    },
    {
      path: "/admin/settings/accounts/:redirect/:redirect_status",
      name: "Connected Accounts",
      component: ConnectedAccountsRoute,
      hide:
        (user_profile?.scopes?.listings === "hide" ||
          !!user_profile?.listing_groups?.length) &&
        user_profile?.scopes.listings !== "write",
    },
    {
      path: "/admin/settings/messaging",
      name: "Messaging",
      component: MessagingSettings,
      hide:
        (user_profile?.scopes?.messaging === "hide" ||
          !!user_profile?.listing_groups?.length) &&
        user_profile?.scopes?.messaging !== "write",
    },
    {
      path: "/admin/settings/users",
      name: "Users",
      component: Users,
      hide: user_profile?.scopes?.accounts === "hide",
    },
    {
      path: "/admin/settings/billing/:redirect",
      name: "Billing",
      component: Billing,
      hide: user_profile?.scopes?.billing === "hide",
    },
    {
      path: "/admin/settings/devices",
      name: "Devices",
      component: Devices,
      hide:
        !!user_profile?.listing_groups?.length &&
        user_profile?.scopes?.smart_devices !== "write",
    },
  ];

  const handleSelectRoute = (item) => {
    let defaultProps = {
      selectedDevice: null,
      selectedUser: null,
      connectedAccount: {
        account: null,
        accountType: null,
        integrationSpec: null,
      },
    };
    dispatch(setSelectedViewItem("settings", item, defaultProps));
  };

  const isPathSelected = React.useMemo(() => {
    const re = /^.*\/admin\/settings\/.+$/;
    return re.test(location.pathname);
  }, [location]);

  let panel1 = (
    <div>
      <CustomCardHeader
        title="Business Settings"
        className={classes.cardHeader}
      />
      <List style={{marginTop: 40}} component="nav" dense>
        {routes.map((item) => {
          if (!!item.hide) {
            return null;
          }
          return (
            <ListItem
              id={`settings-${toSnakeCase(item.name)}`}
              to={item.path}
              key={item.path}
              disableRipple
              component={Link}
              className={classes.listItem}
              selected={location.pathname === item.path}
              onClick={() => handleSelectRoute(item.path)}
              button
            >
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{className: classes.listItemText}}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  if (!!redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Panel1 content={panel1} allRounded={!isPathSelected} />
      <Switch>
        {routes.map((prop, key) =>
          prop.path === "/admin/settings/branding" ? (
            <Route
              key={key}
              path={prop.path}
              render={(props) =>
                loading ? (
                  <div className={classes.brandingContainer}>
                    <Panel2
                      flexAuto
                      content={<EmptyListPanel loading list="branding" />}
                    />
                    {!isTabletView && <div className={classes.hiddenPanel3} />}
                  </div>
                ) : branding.length === 1 ? (
                  <div className={classes.brandingContainer}>
                    <Panel2
                      flexAuto
                      content={
                        <Branding
                          {...props}
                          displayCloseButton={false}
                          branding={branding[0]}
                          setBranding={setBranding}
                          onUpdateDefaultBrand={(newItem) => {
                            setBranding((prevBranding) => {
                              let propList = [...prevBranding];
                              propList[0] = newItem;
                              return propList;
                            });
                          }}
                        />
                      }
                    />
                    {!isTabletView && <div className={classes.hiddenPanel3} />}
                  </div>
                ) : (
                  <BrandingEntryList
                    {...props}
                    itemListProp={branding}
                    listTitle={t("branding-list-panel-title")}
                    setBranding={setBranding}
                    onCloseURL={"/admin/settings/branding"}
                  />
                )
              }
            />
          ) : (
            <Route key={key} path={prop.path} component={prop.component} />
          ),
        )}
      </Switch>
    </>
  );
}
