import {API} from "aws-amplify";

export const getGuests = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/guests?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getGuest = async (guest_id) => {
  try {
    const response = await API.get("ensoAPI", `/guests/${guest_id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchGuest = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/guests", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchGuestsArchive = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/guests/archive", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getInbox = async () => {
  try {
    return await API.get("ensoAPI", `/inbox`);
  } catch (error) {
    throw error;
  }
};
