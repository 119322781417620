import React from "react";
import FlexContainer from "../layout/FlexContainer";
import PropTypes from "prop-types";

/**
 * ChipRow is a component that renders a row of chips with customizable gaps and styles.
 * It takes an array of chips and displays each chip in a row using FlexContainer.
 *
 * @param {Object} props - The props for the ChipRow component.
 * @param {Array<Object>} props.chips - The array of chips to display. Each chip is an object with a `Component` and other chip properties.
 * @param {string} [props.gap="8px"] - The gap between the chips in the row.
 * @param {boolean} [props.wrap=false] - Whether the chips should wrap to the next line.
 * @param {"left"|"right"} [props.align="left"] - Alignment of chips within the row.
 * @param {Object} [props.sx={}] - The style object that can be used to override default styles.
 * @param {Object} props.rest - Any other props that should be passed to the FlexContainer component.
 *
 * @returns {JSX.Element} The rendered ChipRow component.
 */
const ChipRow = ({
  chips = [],
  gap = "4px",
  wrap = false,
  align = "left",
  sx = {},
  ...props
}) => {
  return (
    <FlexContainer
      flexDirection="row"
      justifyContent={align === "right" ? "flex-end" : "flex-start"}
      gap={gap}
      sx={{flexWrap: wrap ? "wrap" : "nowrap", ...sx}}
      {...props}
    >
      {chips.map((chip, index) => {
        const {Component, chipBase, ...chipProps} = chip;
        return <Component key={index} chipBase={chipBase} {...chipProps} />;
      })}
    </FlexContainer>
  );
};

ChipRow.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.elementType,
      chipBase: PropTypes.elementType,
      text: PropTypes.string,
      icon: PropTypes.any,
    }),
  ).isRequired,
  gap: PropTypes.string,
  wrap: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right"]),
  sx: PropTypes.any,
};

export default ChipRow;
