import React, {useMemo} from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import FlexContainer from "connectui/component/layout/FlexContainer";
import XChipBase from "connectui/component/display/chip/base/XChipBase";
import FilterListChip from "./FilterListChip";
import Text from "connectui/component/display/Text";
import ChipRow from "connectui/component/layout/ChipRow";
import {fieldParams} from "configuration/specs";
import _ from "lodash";

const useStyles = (theme) =>
  buildStyles({
    selectedFiltersContainer: {
      width: "100%",
      maxHeight: "200px",
      flexFlow: "wrap",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(5),
      overflowY: "auto",
      flexGrow: 1,
    },
  });

const SelectedFiltersOverview = ({filters = [], setFilters}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const groupedFilters = useMemo(
    () =>
      _.chain(filters)
        .groupBy("path")
        .map((items, path) => ({
          path,
          operators: items.map((item) => item.operator),
          value:
            items.length > 1 ? items.map((item) => item.value) : items[0].value,
        }))
        .value(),
    [filters],
  );

  const handleRemoveFilter = (filter, value) => {
    let newFilters = [];
    const isArrayValue = Array.isArray(value);
    const isNumberOrDate = ["number", "date"].includes(
      fieldParams[filter.path]?.type,
    );

    if (isNumberOrDate && isArrayValue) {
      newFilters = filters.filter((f) => f.path !== filter.path);
    } else if (isArrayValue) {
      newFilters = filters.map((f) =>
        f.path === filter.path
          ? {...f, value: f.value.filter((v) => v !== value)}
          : f,
      );
    } else {
      newFilters = filters.filter(
        (f) => !(f.path === filter.path && f.value === value),
      );
    }

    setFilters(newFilters);
  };

  if (!filters.length) return null;

  return (
    <FlexContainer
      className={classes.selectedFiltersContainer}
      gap={theme.spacing(1)}
    >
      {groupedFilters.map((f, i) => (
        <FlexContainer
          key={`${f.path}-${i}`}
          alignItems="center"
          justifyContent="space-between"
          gap={theme.spacing(2)}
          width={"100%"}
        >
          <Text
            variant="textXXS"
            weight="semibold"
            color="textTertiary"
            sx={{minWidth: "30%", flex: 1}}
          >
            {fieldParams[f.path]?.name ?? f.path}
          </Text>
          <ChipRow
            align="right"
            chips={
              Array.isArray(f.value) &&
              !["number", "date"].includes(fieldParams[f.path]?.type)
                ? [
                    {
                      Component: FilterListChip,
                      chipBase: XChipBase,
                      filter: f,
                      value: f.value[0],
                      onXClick: () => handleRemoveFilter(f, f.value[0]),
                    },
                    {
                      Component: FilterListChip,
                      filter: f,
                      value: `+${f.value.length - 1}`,
                      hoverChips: (
                        <ChipRow
                          wrap
                          sx={{maxWidth: 350, maxHeight: 200, overflow: "auto"}}
                          chips={f.value.slice(1).map((v) => ({
                            Component: FilterListChip,
                            filter: f,
                            value: v,
                            chipBase: XChipBase,
                            onXClick: () => handleRemoveFilter(f, v),
                          }))}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      Component: FilterListChip,
                      filter: f,
                      value: f.value,
                      chipBase: XChipBase,
                      onXClick: () => handleRemoveFilter(f),
                    },
                  ]
            }
          />
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

export default SelectedFiltersOverview;
