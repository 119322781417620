import React from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Text from "../../../../connectui/component/display/Text";
import ButtonText from "../../../../connectui/component/input/button/ButtonText";
import PhoneField from "../../../../core/inputs/PhoneField";
import OutlinedButtonBase from "../../../../connectui/component/input/button/base/OutlinedButtonBase";

export default function DisplayedPhonePanel({setStageId}) {
  const {theme} = useTheme();
  const [phone, setPhone] = React.useState("");
  return (
    <FlexContainer
      flexDirection={"column"}
      gap={theme.spacing(5)}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{paddingBottom: theme.spacing(3), width: "460px"}}
    >
      <Text variant={"displayMD"} weight={"bold"}>
        Add a Displayed Number
      </Text>
      <Text variant={"textLG"}>
        Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum
        Lorum Ipsum Lorum Ipsum Lorum Ipsum{" "}
      </Text>
      <OutlinedButtonBase sx={{width: "100%"}}>
        <PhoneField
          edit
          phone={phone}
          onChange={(value) => setPhone(value)}
          disableEdit={false}
        />
      </OutlinedButtonBase>
      <Text variant={"textSM"}>
        Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum
        Lorum Ipsum Lorum Ipsum Lorum Ipsum{" "}
      </Text>
      <ButtonText
        label={"Continue"}
        sx={{width: "100%"}}
        typographyVariant="textSM"
        weight="semibold"
        onClick={() => setStageId(3)}
        color="white"
      />
      <ButtonText
        label={"Cancel"}
        sx={{width: "100%"}}
        buttonBase={OutlinedButtonBase}
        typographyVariant="textSM"
        weight="semibold"
        onClick={() => setStageId(1)}
      />
    </FlexContainer>
  );
}
