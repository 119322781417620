import React, {useState, useRef, useEffect} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Container from "../layout/Container";
import {buildStyles} from "../buildStyles";
import {useTheme} from "../../theme/ThemeProvider";

const useStyles = (theme) =>
  buildStyles({
    tooltipTrigger: {display: "inline-block"},
    tooltipContainer: {
      position: "absolute",
      zIndex: theme.zIndex.tooltip,
      backgroundColor: theme.colors.white,
      color: theme.colors.textPrimary,
      boxShadow: theme.shadows.md,
      borderRadius: `${theme.radius.lg}px`,
      padding: theme.spacing(1, 2),
      whiteSpace: "nowrap",
    },
    tooltipArrow: {
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
    },
  });

/**
 * Tooltip shows additional information in a floating panel when hovering over an element.
 * It positions itself above or below the trigger element,
 * and can also stay open when the mouse cursor is on the tooltip content.
 *
 * @param {Object} props - The props for the Tooltip component.
 * @param {React.ReactNode} props.children - The element that triggers the tooltip on hover.
 * @param {React.ReactNode} props.tooltipContent - The content displayed within the tooltip.
 * @param {number} [props.offset=8] - The space (in px) between the tooltip and the trigger element.
 * @param {number} [props.enterDelay=100] - The delay (in ms) before the tooltip is shown.
 * @param {number} [props.leaveDelay=150] - The delay (in ms) before the tooltip is hidden.
 * @param {Object} [props.sx={}] - The style object that can be used to override default tooltip styles.
 *
 * @returns {JSX.Element|null} The rendered Tooltip component, or null if it's not visible.
 */

const Tooltip = ({
  children,
  tooltipContent,
  offset = 8,
  enterDelay = 100,
  leaveDelay = 150,
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const [hoverTrigger, setHoverTrigger] = useState(false);
  const [hoverTooltip, setHoverTooltip] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showAbove, setShowAbove] = useState(true);
  const [position, setPosition] = useState({top: -9999, left: -9999});
  const anchorEl = useRef(null);
  const tooltipRef = useRef(null);
  // Timer refs
  const triggerEnterTimer = useRef(null);
  const triggerLeaveTimer = useRef(null);
  const tooltipEnterTimer = useRef(null);
  const tooltipLeaveTimer = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(triggerEnterTimer.current);
      clearTimeout(triggerLeaveTimer.current);
      clearTimeout(tooltipEnterTimer.current);
      clearTimeout(tooltipLeaveTimer.current);
    };
  }, []);

  useEffect(() => {
    const show = (hoverTrigger || hoverTooltip) && tooltipContent;
    setVisible(show);
  }, [hoverTrigger, hoverTooltip]);

  useEffect(() => {
    if (!visible || !anchorEl.current || !tooltipRef.current) return;

    const triggerRect = anchorEl.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const canFitAbove = triggerRect.top >= tooltipRect.height + offset;
    const maxEndPosition = window.innerWidth - tooltipRect.width;
    const top = canFitAbove
      ? triggerRect.top - tooltipRect.height - offset
      : triggerRect.bottom + offset;
    let left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
    if (left < 0) left = 0;
    if (left > maxEndPosition) left = maxEndPosition;

    setShowAbove(canFitAbove);
    setPosition({top, left});
  }, [visible, offset, tooltipContent]);

  const handleTriggerMouseEnter = () => {
    clearTimeout(triggerLeaveTimer.current);
    triggerEnterTimer.current = setTimeout(() => {
      setHoverTrigger(true);
    }, enterDelay);
  };

  const handleTriggerMouseLeave = () => {
    clearTimeout(triggerEnterTimer.current);
    triggerLeaveTimer.current = setTimeout(() => {
      setHoverTrigger(false);
    }, leaveDelay);
  };

  const handleTooltipMouseEnter = () => {
    clearTimeout(tooltipLeaveTimer.current);
    tooltipEnterTimer.current = setTimeout(() => {
      setHoverTooltip(true);
    }, enterDelay);
  };

  const handleTooltipMouseLeave = () => {
    clearTimeout(tooltipEnterTimer.current);
    tooltipLeaveTimer.current = setTimeout(() => {
      setHoverTooltip(false);
    }, leaveDelay);
  };

  return (
    <>
      <Container
        ref={anchorEl}
        className={classes.tooltipTrigger}
        onMouseEnter={handleTriggerMouseEnter}
        onMouseLeave={handleTriggerMouseLeave}
      >
        {children}
      </Container>
      {visible
        ? ReactDOM.createPortal(
            <Container
              ref={tooltipRef}
              className={classes.tooltipContainer}
              sx={{top: position.top, left: position.left, ...sx}}
              onMouseEnter={handleTooltipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
              {...props}
            >
              {tooltipContent}

              {/* Tooltip arrow */}
              <div
                className={classes.tooltipArrow}
                style={
                  showAbove
                    ? {
                        bottom: 0,
                        left: "50%",
                        transform: "translateX(-50%) translateY(100%)",
                        borderTop: `6px solid ${theme.colors.white}`,
                      }
                    : {
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%) translateY(-100%)",
                        borderBottom: `6px solid ${theme.colors.white}`,
                      }
                }
              />
            </Container>,
            document.body,
          )
        : null}
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node,
  offset: PropTypes.number,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
  sx: PropTypes.any,
};

export default Tooltip;
