import React, {Suspense, lazy, useState} from "react";
import {buildStyles} from "connectui/component/buildStyles";
import {useTheme} from "connectui/theme/ThemeProvider";
import Container from "connectui/component/layout/Container";
import Drawer from "connectui/component/modal/Drawer";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import SvgIcon from "connectui/component/display/SvgIcon";
import ICONS from "connectui/icons/iconMap";
import Text from "connectui/component/display/Text";
// Panels
const FilterSetsPanel = lazy(
  () => import("ui/component/inbox/panel/filtersets/FilterSetsPanel"),
);
const MessageThreadsPanel = lazy(
  () => import("ui/component/inbox/panel/threads/MessageThreadsPanel"),
);
const ConversationsPanel = lazy(
  () => import("ui/component/inbox/panel/conversations/ConversationsPanel"),
);
const GuestDetailsPanel = lazy(
  () => import("ui/component/inbox/panel/guestdetails/GuestDetailsPanel"),
);
const FiltersPanel = lazy(
  () => import("ui/component/inbox/panel/filterlist/FiltersPanel"),
);

const useStyles = (theme) =>
  buildStyles({
    outerContainer: {
      width: "100%",
      height: "100%",
      background: theme.colors.white,
      display: "flex",
      flexDirection: "column",
    },
    iconBtn: {
      backgroundColor: theme.colors.white,
      cursor: "pointer",
      padding: "0px",
      border: "none",
      "&:hover": {backgroundColor: theme.colors.hoverGray},
    },
    menuIcon: {
      width: "21px",
      height: "21px",
      justifyContent: "center",
      marginLeft: theme.spacing(0.5),
    },
  });

const MobileInbox = ({}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  // Panels: "views", "guestlist", "conversations", "guestdetails", "filters", "editview", "saveview"
  const [panelOpen, setPanelOpen] = useState("guestlist");

  const sidebarIcon = (
    <ButtonIcon
      className={classes.iconBtn}
      icon={<SvgIcon component={ICONS.menu} className={classes.menuIcon} />}
      onClick={() => setPanelOpen("views")}
    />
  );

  return (
    <Container className={classes.outerContainer}>
      <Suspense fallback={<div></div>}>
        {/* Main panels */}
        <Drawer
          isOpen={panelOpen === "views"}
          onClose={() => setPanelOpen("guestlist")}
        >
          <FilterSetsPanel
            customHeader={
              <>
                <Text variant="displayXS" weight="semibold">
                  {"Views"}
                </Text>
              </>
            }
            editView={() => {}}
          />
        </Drawer>
        {panelOpen === "guestlist" && (
          <MessageThreadsPanel
            onGuestClick={() => setPanelOpen("conversations")}
            openFilters={() => setPanelOpen("filters")}
            expandArrow={sidebarIcon}
          />
        )}
        {panelOpen === "conversations" && <ConversationsPanel />}
        {panelOpen === "guestdetails" && <GuestDetailsPanel />}
        {/* Sub-panels */}
        {panelOpen === "filters" && <FiltersPanel />}
      </Suspense>
    </Container>
  );
};

export default MobileInbox;
