import React, {useMemo, useCallback} from "react";
import ChipBase from "../display/chip/base/ChipBase";
import XChipBase from "../display/chip/base/XChipBase";
import Text from "../display/Text";
import PropTypes from "prop-types";
import {useTheme} from "../../theme/ThemeProvider";
import Container from "../layout/Container";

export default function SelectChipContainer({
  options = [],
  selectedOptions = [],
  onChange,
  multiSelect = true,
  sx = {},
  customChipColor,
  customRender,
}) {
  const {theme} = useTheme();

  const selectedOptionsSet = useMemo(
    () => new Set(selectedOptions),
    [selectedOptions],
  );

  const handleChipClick = useCallback(
    (option) => {
      if (multiSelect) {
        const updatedSelection = selectedOptionsSet.has(option)
          ? selectedOptions.filter((opt) => opt !== option)
          : [...selectedOptions, option];
        onChange(updatedSelection);
      } else {
        onChange(selectedOptionsSet.has(option) ? [] : [option]);
      }
    },
    [multiSelect, selectedOptions, onChange, selectedOptionsSet],
  );

  return (
    <Container
      sx={{
        display: "flex",
        flexWrap: "wrap",
        marginTop: "15px",
        gap: theme.spacing(1),
        ...sx,
      }}
    >
      {options.map((option) => {
        const isSelected = selectedOptionsSet.has(option);
        const ChipComponent = isSelected ? XChipBase : ChipBase;

        return (
          <ChipComponent
            key={option}
            sx={{
              cursor: "pointer",
              backgroundColor: customChipColor
                ? customChipColor("backgroundColor", option, isSelected)
                : isSelected
                  ? theme.colors.blue50
                  : theme.colors.gray100,
              color: customChipColor
                ? customChipColor("color", option, isSelected)
                : isSelected
                  ? theme.colors.blue700
                  : theme.colors.gray700,
              "&:hover": {
                backgroundColor: customChipColor
                  ? customChipColor("hover-backgroundColor", option, isSelected)
                  : isSelected
                    ? theme.colors.blue100
                    : theme.colors.gray200,
              },
            }}
            {...(isSelected
              ? {onXClick: () => handleChipClick(option)}
              : {onClick: () => handleChipClick(option)})}
          >
            {customRender ? (
              customRender(option, isSelected)
            ) : (
              <Text
                variant="textSM"
                weight="medium"
                color={isSelected ? "blue700" : "gray700"}
              >
                {option}
              </Text>
            )}
          </ChipComponent>
        );
      })}
    </Container>
  );
}

SelectChipContainer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  sx: PropTypes.object,
  customRender: PropTypes.func,
};
