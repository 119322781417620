import React, {useEffect, useState} from "react";
import TextInput from "../../../../connectui/component/input/text/type/TextInput";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import Text from "../../../../connectui/component/display/Text";
import ICONS from "../../../../connectui/icons/iconMap";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import InputBase from "../../../../connectui/component/input/text/InputBase";

const useStyles = (theme) =>
  buildStyles({
    hoverIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    editIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: theme.colors.textSecondary,
      display: "inline-flex !important",
      marginRight: theme.spacing(2),
      verticalAlign: "text-bottom",
      cursor: "pointer",
    },
    actionsIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      cursor: "pointer",
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "200px",
    },
  });

export default function GuestDetailsEditableValue({value, onChange}) {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const [editing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = () => {
    onChange(localValue);
    setEditing(false);
  };

  const handleCancel = () => {
    setLocalValue(value);
    setEditing(false);
  };

  return editing ? (
    <TextInput
      inputBase={InputBase}
      allignItems={"flex-end"}
      sx={{paddingLeft: theme.spacing(5)}}
      inputProps={{
        placeholder: localValue,
        value: localValue,
        onChange: (e) => setLocalValue(e.target.value),
      }}
      actions={
        <FlexContainer
          alignItems="center"
          gap={theme.spacing(1)}
          sx={{marginLeft: theme.spacing(3)}}
        >
          <SvgIcon
            className={classes.actionsIcon}
            component={ICONS.check}
            sx={{color: theme.colors.textSecondary}}
            onClick={handleSave}
          />
          <SvgIcon
            className={classes.actionsIcon}
            component={ICONS["small-x"]}
            sx={{color: theme.colors.textSecondary}}
            onClick={handleCancel}
          />
        </FlexContainer>
      }
    />
  ) : (
    <FlexContainer
      alignItems="center"
      justifyContent="flex-start"
      sx={{overflow: "hidden", maxWidth: "100%"}}
    >
      <SvgIcon
        className={classes.editIcon}
        component={ICONS.edit}
        onClick={handleEdit}
      />
      <Text
        variant="textMD"
        color="textPrimary"
        weight="semiBold"
        className={classes.textContainer}
      >
        {localValue}
      </Text>
    </FlexContainer>
  );
}
