import React, {useMemo} from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Text from "../../../../connectui/component/display/Text";
import VirtualizedTableComponent from "../../../../connectui/component/layout/table/VirtualizedTableComponent";
import OutlinedButtonBase from "../../../../connectui/component/input/button/base/OutlinedButtonBase";
import ButtonText from "../../../../connectui/component/input/button/ButtonText";

export default function NumberConnectedPanel({setStageId}) {
  const {theme} = useTheme();

  const tableColumns = useMemo(
    () => [
      {
        label: "Number",
        field: "number",
        sortable: true,
        render: (data) => (
          <div style={{display: "flex", flexDirection: "column"}}>
            <Text variant="textMD" weight="semibold">
              {data.number}
            </Text>
            <Text variant="textSM" color="textSecondary">
              {data.description}
            </Text>
          </div>
        ),
      },
      {
        label: "Status",
        field: "status",
        sortable: true,
        render: (data) => (
          <Text
            variant="textSM"
            weight="semibold"
            color={data.status === "Active" ? "success" : "primary"}
          >
            {data.status}
          </Text>
        ),
      },
      {
        label: "Features",
        field: "features",
        sortable: false,
        render: (data) => (
          <div style={{display: "flex", gap: theme.spacing(2)}}>
            {data.features.map((feature, index) => (
              <Text variant="textSM" key={index}>
                {feature}
              </Text>
            ))}
          </div>
        ),
      },
      {
        label: "Visibility",
        field: "visibility",
        sortable: false,
        render: (data) => (
          <input
            type="checkbox"
            checked={data.visibility}
            onChange={() => console.log("Toggle visibility", data.visibility)}
          />
        ),
      },
      {
        label: "Price",
        field: "price",
        sortable: true,
        render: (data) => <Text variant="textSM">{data.price}</Text>,
      },
    ],
    [theme],
  );

  const tableData = useMemo(
    () => [
      {
        number: "+1 987 654 3456",
        description: "Displayed Number",
        status: "Display-only",
        features: ["No Features"],
        visibility: true,
        price: "Free",
      },
      {
        number: "+1 987 654 3210",
        description: "Montreal, Quebec",
        status: "Active",
        features: ["SMS", "Voice"],
        visibility: true,
        price: "$2/month",
      },
      // Additional data entries here...
    ],
    [],
  );

  const isRowLoaded = ({index}) => index < tableData.length;

  return (
    <FlexContainer
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      gap={theme.spacing(5)}
      sx={{paddingBottom: theme.spacing(3), width: "80%"}}
    >
      <Text variant={"displayMD"} weight={"bold"}>
        Phone Number Connected
      </Text>
      <Text variant={"textLG"}>
        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
        Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
        Lorem Ipsum Lorem Ipsum Lorem Ipsum
      </Text>

      <div style={{height: "400px", width: "100%"}}>
        <VirtualizedTableComponent
          columns={tableColumns}
          data={tableData}
          rowHeight={80}
          sortable
          onSort={(field, direction) =>
            console.log("Sorting by", field, direction)
          }
          isRowLoaded={isRowLoaded}
          loadMoreRows={() => console.log("Loading more rows")}
          totalRows={tableData.length}
        />
      </div>

      <ButtonText
        label={"New Number"}
        typographyVariant="textSM"
        weight="semibold"
        buttonBase={OutlinedButtonBase}
      />
    </FlexContainer>
  );
}
