import React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import FlexContainer from "connectui/component/layout/FlexContainer";
import Container from "connectui/component/layout/Container";
import Text from "connectui/component/display/Text";
import ScrollableContainer from "connectui/component/layout/ScrollableContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "connectui/component/input/text/OutlinedInputBase";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import CircleButtonBase from "connectui/component/input/button/base/CircleButtonBase";
import SvgIcon from "connectui/component/display/SvgIcon";
import ICONS from "connectui/icons/iconMap";

const icons = [
  "zap",
  "eye",
  "home",
  "clock",
  "cloud-rain",
  "unlock",
  "anchor",
  "battery-charging",
  "wind",
  "volume-x",
  "user",
  "thermometer",
  "truck",
  "at-sign",
  "tool",
  "bell",
  "thumbs-up",
  "kid",
];

const colors = [
  "primary",
  "error500",
  "warning500",
  "success500",
  "bluegray500",
  "bluelight500",
  "blue500",
  "indigo500",
  "purple500",
  "pink500",
  "rose500",
  "orange500",
];

const useStyles = (theme) =>
  buildStyles({
    content: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      gap: theme.spacing(5),
      padding: theme.spacing(0, 4),
    },
    icon: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
    },
    dot: {
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      border: `2px solid ${theme.colors.white}`,
    },
  });

const ColorDot = ({color}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <Container
      className={classes.dot}
      sx={{backgroundColor: theme.colors[color]}}
    />
  );
};

const FilterSetDetailsStep = ({
  content = {},
  showErrors,
  setContent = () => {},
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();

  return (
    <ScrollableContainer className={classes.content}>
      <FlexContainer flexDirection="column" gap={theme.spacing(1.5)}>
        <Text variant="textSM" weight="medium" color="gray700">
          {"Name*"}
        </Text>
        <TextInput
          inputBase={OutlinedInputBase}
          sx={{
            borderColor:
              showErrors && !content.name
                ? theme.colors.error300
                : theme.colors.gray300,
          }}
          inputProps={{
            value: content.name ?? "",
            onChange: (e) => setContent("name", e.target.value),
            placeholder: "ex.: High-Priority Guests",
          }}
        />
        {showErrors && !content.name && (
          <Text variant="textSM" color="error">
            {t("view-name-required")}
          </Text>
        )}
      </FlexContainer>

      <FlexContainer flexDirection="column" gap={theme.spacing(1.5)}>
        <Text variant="textSM" weight="medium" color="gray700">
          {"Description (Optional)"}
        </Text>
        <TextInput
          as={"textarea"}
          inputBase={OutlinedInputBase}
          inputProps={{
            value: content.description ?? "",
            onChange: (e) => setContent("description", e.target.value),
            placeholder:
              "A little about the company and the team that you’ll be working with.",
          }}
        />
        <Text variant="textSM" color="gray600">
          {"Add a description to help EnsoAI suggest better templates."}
        </Text>
      </FlexContainer>

      <FlexContainer flexDirection="column" gap={theme.spacing(1.5)}>
        <Text variant="textSM" weight="medium" color="gray700">
          {"Select Icon*"}
        </Text>
        <FlexContainer gap={theme.spacing(3)} sx={{flexWrap: "wrap"}}>
          {icons.map((ic) => {
            const isSelected = content.icon === ic;
            return (
              <ButtonIcon
                key={ic}
                buttonBase={CircleButtonBase}
                sx={{
                  backgroundColor: isSelected
                    ? theme.colors.primary100
                    : theme.colors.gray100,
                  "&:hover": {
                    backgroundColor: isSelected
                      ? theme.colors.primary100
                      : theme.colors.gray200,
                  },
                }}
                icon={
                  <SvgIcon
                    component={ICONS[ic]}
                    className={classes.icon}
                    sx={{
                      color: isSelected
                        ? theme.colors.primary500
                        : theme.colors.gray600,
                    }}
                  />
                }
                onClick={() => setContent("icon", ic)}
              />
            );
          })}
        </FlexContainer>
        {showErrors && !content.icon && (
          <Text variant="textSM" color="error">
            {t("view-icon-required")}
          </Text>
        )}
      </FlexContainer>

      <FlexContainer flexDirection="column" gap={theme.spacing(1.5)}>
        <Text variant="textSM" weight="medium" color="gray700">
          {"Select Color*"}
        </Text>
        <FlexContainer gap={theme.spacing(3)} sx={{flexWrap: "wrap"}}>
          {colors.map((c) => {
            const isSelected = content.color === c;
            const selectedBG = theme.colors[c.replace(/\d+$/, "100")];
            return (
              <ButtonIcon
                key={c}
                buttonBase={CircleButtonBase}
                sx={{
                  backgroundColor: isSelected
                    ? selectedBG
                    : theme.colors.gray100,
                  "&:hover": {
                    backgroundColor: isSelected
                      ? selectedBG
                      : theme.colors.gray200,
                  },
                }}
                icon={<ColorDot color={c} />}
                onClick={() => setContent("color", c)}
              />
            );
          })}
        </FlexContainer>
        {showErrors && !content.color && (
          <Text variant="textSM" color="error">
            {t("view-color-required")}
          </Text>
        )}
      </FlexContainer>
    </ScrollableContainer>
  );
};

export default FilterSetDetailsStep;
