import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import Text from "../../../../connectui/component/display/Text";
import React from "react";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";

export default function GuestField({
  label,
  value = null,
  icon: Icon,
  align = "center",
}) {
  const {theme} = useTheme();

  return (
    <FlexContainer
      flexDirection="row"
      alignItems={align}
      justifyContent="space-between"
      width="100%"
    >
      <FlexContainer flexDirection="row" alignItems="center">
        {Icon && (
          <FlexContainer marginRight={theme.spacing(1)}>
            <SvgIcon
              component={Icon}
              sx={{
                color: theme.colors.textTertiary,
                width: "15px",
                height: "15px",
              }}
            />
          </FlexContainer>
        )}
        {label && (
          <Text variant="textMD" color="textTertiary" weight="semiBold">
            {label}
          </Text>
        )}
      </FlexContainer>
      {value && value}
    </FlexContainer>
  );
}
