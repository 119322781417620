import React from "react";
import Container from "../layout/Container";
import {generateStyles} from "../../theme/styleUtilities";
import {useTheme} from "../../theme/ThemeProvider";
import PropTypes from "prop-types";

/**
 * Divider is a component that renders a horizontal or vertical divider line.
 * It can be customized using the `sx` prop for additional styles.
 *
 * @param {Object} props - The props for the Divider component.
 * @param {"horizontal" | "vertical"} [props.orientation="horizontal"] - The orientation of the divider (horizontal or vertical).
 * @param noPadding
 * @param {Object} [props.sx={}] - The style object that can be used to override default divider styles.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered Divider component.
 */
const Divider = ({
  orientation = "horizontal",
  sx = {},
  noPadding = false,
  ...props
}) => {
  const {theme} = useTheme();

  const defaultStyles = {
    backgroundColor: theme.colors.divider,
    width: orientation === "horizontal" ? "100%" : "1px",
    height: orientation === "horizontal" ? "1px" : "100%",
    margin:
      orientation === "horizontal"
        ? theme.spacing(noPadding ? 0 : 3, 0)
        : theme.spacing(0, noPadding ? 0 : 3),
  };

  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return <Container sx={styles} {...props} />;
};

Divider.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  sx: PropTypes.any,
};

export default Divider;
