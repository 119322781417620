import React from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  MenuItem,
  Avatar,
  Button,
  IconButton,
} from "@material-ui/core";
import {useDispatch} from "react-redux";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import FieldTypeIcon from "core/icons/FieldTypeIcon";
import FilledTextField from "core/inputs/FilledTextField";
import ImageTextField from "components/TextFields/ImageTextField";
import GooglePlaceAutocomplete from "components/TextFields/GooglePlaceAutocomplete";
import {useTranslation} from "react-i18next";
import FilledSelect from "core/selects/FilledSelect";
import {useSelector} from "react-redux";
import {contentTypeEnum} from "configuration/enums";
import EmailEditor from "components/Misc/EmailEditor";
import SkeletonEmailEditor from "components/Misc/SkeletonEmailEditor";
import ImageIcon from "@material-ui/icons/Image";
import AddressAutocomplete from "components/TextFields/AddressAutocomplete";
import clsx from "clsx";
import {createAction} from "redux/actions/experiencesActions";
import {Skeleton} from "@material-ui/lab";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HTMLField from "components/TextFields/HTMLField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    alignItems: "center",
    gap: theme.spacing(3),
    borderRadius: 10,
    border: "1px solid #EAEAEB",
    backgroundColor: "#FBFBFC",
  },
  inputContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    position: "relative",
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
  blurredContent: {
    filter: "blur(5px)",
    pointerEvents: "none",
  },
  overlayCard: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: 10,
    maxWidth: 600,
    width: "100%",
  },
  previewText: {
    //whiteSpace: 'nowrap',
    //overflow: 'hidden',
    textOverflow: "ellipsis",
    //width: '100%',
  },
  avatar: {
    backgroundColor: "#D9D9D9",
    borderRadius: 5,
    width: 120,
    maxHeight: 120,
    height: "auto",
    alignSelf: "stretch",
    marginRight: theme.spacing(2),
  },
  imageIcon: {
    width: "100%",
    height: "100%",
    color: "#f2f2f2",
  },
}));

const fieldIconConfig = {
  place: "guest_address",
  summary: "multi_select",
  header: "text",
  list: "multi_select",
  text: "text",
  button: "number",
  video: "image",
  image: "image",
};

export default function GuidebookContentTypeInput({
  field,
  onValueChange,
  onDelete,
  selectedLang = "en",
  loading = false,
  listing,
  isCollapsed,
  setIsCollapsed,
}) {
  const classes = useStyles();
  const [assetType, setAssetType] = React.useState("picture");
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const {t} = useTranslation();
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [showMoreOptions, setShowMoreOptions] = React.useState(false);
  let dispatch = useDispatch();
  let guidebooks = listing?.listing_content?.guidebooks || []; // label = guidebook.properties.main_page.name?.[0]?.value, id = guidebook.id
  let upsells = listing?.listing_content?.upsells || []; // label = upsell.properties.name?.[0]?.value, id = upsell.id

  function selectPlace(newPlace) {
    let newField = {
      loading: true,
      ...newPlace,
      ...field,
    };
    onValueChange(null, newField);
    dispatch(
      createAction({
        customErrorMessage:
          "EnsoAI is experiencing high traffic. Please try again later",
        action: {
          action_id: "generate_text",
          prompt: newField,
          content_type: "google_guidebook",
        },
        listingId: listing.listing_id,
        onSuccess: (r) => {
          console.log("GOT GENERATE TEXT RESPONSE", r.action.response);
          newField.text = [{language: "en", value: r.action.response}];
          newField.loading = false;
          onValueChange(null, newField);
        },
        onError: (err) =>
          console.log("Sorry, something went wrong, please try again."),
      }),
    );
  }

  const handleEditOption = (ind, newVal) => {
    let newOptions = [...field.list_items];
    newOptions[ind] = newVal;
    onValueChange("list_items", newOptions);
  };

  const handleDeleteOption = (ind) => () => {
    onValueChange(
      "list_items",
      field.list_items.filter((el, i) => i !== ind),
    );
  };

  const handleAddOption = () => {
    onValueChange(
      "list_items",
      (field.list_items || []).concat([
        {
          primary_text: [{language: selectedLang, value: ""}],
          secondary_text: [{language: selectedLang, value: ""}],
        },
      ]),
    );
  };

  const handleMultilangChange = (field_key, value) => {
    // Find existing language entry
    const index = field[field_key].findIndex(
      (item) => item.language === selectedLang,
    );

    let updatedText;
    if (index === -1) {
      // Add new entry if language doesn't exist
      updatedText = [...field[field_key], {language: selectedLang, value}];
    } else {
      // Update existing entry
      updatedText = field[field_key].map((item, i) =>
        i === index ? {...item, value} : item,
      );
    }

    onValueChange(field_key, updatedText);
  };

  const getPreviewText = (field) => {
    switch (field.type) {
      case "text":
        return (
          <HTMLField
            content={
              field.text?.find((text) => text.language === selectedLang)
                ?.value || ""
            }
            multiline
          />
        );
      case "header":
      case "summary":
      case "button":
      case "image":
        return field.text?.find((text) => text.language === selectedLang)
          ?.value;
      case "place":
        return field?.display_name;
      case "list":
        return field.list_items
          ?.map(
            (item) =>
              item.primary_text?.find((text) => text.language === selectedLang)
                ?.value,
          )
          .join(", ");
      case "video":
        return field.video_url;
      default:
        return null;
    }
  };

  const renderField = () => {
    switch (field.type) {
      case "video":
        return (
          <ImageTextField
            picture={field.video_url}
            available_pictures={listing?.listing_content?.pictures}
            setPicture={(e) => onValueChange("video_url", e)}
            label={`Video URL`}
            assetType={"video"}
            asset_types={["video"]}
          />
        );
      case "image":
        return (
          <>
            <ImageTextField
              picture={field.image}
              available_pictures={listing?.listing_content?.pictures}
              setPicture={(e) => onValueChange("image", e)}
              label={`Image`}
              uploadedImage={uploadedImage}
              savePath={`${current_user}/`}
              assetType={assetType}
              setAssetType={setAssetType}
            />
            <FilledTextField
              fullWidth
              label="Image Label (Optional)"
              value={
                field.text.find((text) => text.language === selectedLang)
                  ?.value || ""
              }
              onChange={(e) => handleMultilangChange("text", e.target.value)}
            />
          </>
        );
      case "header":
        return (
          <>
            <div style={{display: "flex"}}>
              <div style={{marginRight: 5}}>
                <ImageTextField
                  picture={field.image}
                  available_pictures={listing?.listing_content?.pictures}
                  setPicture={(e) => onValueChange("image", e)}
                  label={`Icon`}
                  uploadedImage={uploadedImage}
                  asset_types={["icon_gallery", "image_url", "image"]}
                  savePath={`${current_user}/`}
                  assetType={assetType}
                  setAssetType={setAssetType}
                />
              </div>
              <FilledTextField
                fullWidth
                label="Text"
                loading={loading}
                placeholder="Enter your header text.."
                value={
                  field.text.find((text) => text.language === selectedLang)
                    ?.value || ""
                }
                onChange={(e) => handleMultilangChange("text", e.target.value)}
              />
            </div>
            {showMoreOptions ? (
              <FilledTextField
                fullWidth
                label="Label"
                placeholder="Add a label to your header..."
                value={
                  field.label.find((text) => text.language === selectedLang)
                    ?.value || ""
                }
                onChange={(e) => handleMultilangChange("label", e.target.value)}
              />
            ) : (
              <Button disableRipple onClick={() => setShowMoreOptions(true)}>
                Add label
              </Button>
            )}
          </>
        );
      case "list":
        return (
          <>
            <FilledTextField
              fullWidth
              multiline
              label="Text"
              placeholder="Add text above your list..."
              loading={loading}
              value={
                field.text.find((text) => text.language === selectedLang)
                  ?.value || ""
              }
              onChange={(e) => handleMultilangChange("text", e.target.value)}
            />
            <FilledSelect
              fullWidth
              label="List style"
              value={field.list_style}
              className={classes.select}
              onChange={(e) => onValueChange("list_style", e.target.value)}
            >
              <MenuItem value="" disabled>
                Select a style
              </MenuItem>
              {["divided", "numbered", "bulleted"].map((c) => (
                <MenuItem value={c} key={c}>
                  {c}
                </MenuItem>
              ))}
            </FilledSelect>
            <Typography variant="caption" className="label">
              {"List items"}
            </Typography>
            <div className={classes.optionsContainer}>
              {field.list_items?.map((fo, optInd) => (
                <div style={{display: "flex"}}>
                  <FilledTextField
                    key={`${optInd}-1`}
                    className={classes.option}
                    style={{marginRight: 5}}
                    fullWidth
                    placeholder={`Enter item ${optInd + 1} text...`}
                    value={
                      fo.primary_text.find(
                        (text) => text.language === selectedLang,
                      )?.value || ""
                    }
                    onChange={(e) =>
                      handleEditOption(optInd, {
                        primary_text: fo.primary_text.map((text) =>
                          text.language === selectedLang
                            ? {...text, value: e.target.value}
                            : text,
                        ),
                        secondary_text: fo.secondary_text,
                      })
                    }
                  />
                  {showMoreOptions && (
                    <FilledTextField
                      key={`${optInd}-2`}
                      placeholder={`Enter item ${optInd + 1} text..`}
                      fullWidth
                      className={classes.option}
                      value={
                        fo.secondary_text.find(
                          (text) => text.language === selectedLang,
                        )?.value || ""
                      }
                      onChange={(e) =>
                        handleEditOption(optInd, {
                          primary_text: fo.primary_text,
                          secondary_text: fo.secondary_text.map((text) =>
                            text.language === selectedLang
                              ? {...text, value: e.target.value}
                              : text,
                          ),
                        })
                      }
                    />
                  )}
                  <DeleteIconButton
                    disablePadding
                    marginLeft={2}
                    onClick={handleDeleteOption(optInd)}
                  />
                </div>
              ))}
              {(field.list_items?.length || []) < 20 && (
                <div style={{display: "flex"}}>
                  <div style={{flex: 1}}>
                    <Button fullWidth disableRipple onClick={handleAddOption}>
                      {"Add item"}
                    </Button>
                  </div>
                  {/*!showMoreOptions && (
                    <Button
                      disableRipple
                      onClick={() => setShowMoreOptions(true)}
                    >
                      {"Add second row"}
                    </Button>
                  )*/}
                </div>
              )}
            </div>
          </>
        );
      case "summary":
        return (
          <>
            <FormControlLabel
              label="Display table of contents"
              classes={{root: classes.formControlLabel}}
              control={
                <Checkbox
                  name="required"
                  checked={!!field.show_contents}
                  className={classes.checkbox}
                  onChange={() =>
                    onValueChange("show_contents", !field.show_contents)
                  }
                />
              }
            />
            <FilledTextField
              fullWidth
              multiline
              loading={loading}
              placeholder="Add summary text..."
              label="Summary"
              value={
                field.text.find((text) => text.language === selectedLang)
                  ?.value || ""
              }
              onChange={(e) => handleMultilangChange("text", e.target.value)}
              useCustomFields
              AIprops={{
                type: contentTypeEnum.guidebook,
              }}
            />
          </>
        );
      case "text":
        if (loading) {
          return <SkeletonEmailEditor />;
        }
        return (
          <EmailEditor
            outlined
            editorOnly
            useCustomFields
            email_id={`quill-toolbar-${field.id}`}
            lang={selectedLang}
            value={
              field.text.find((text) => text.language === selectedLang)
                ?.value || ""
            }
            onChange={(val) => handleMultilangChange("text", val)}
            placeholder="Text..."
            imagePath={`guidebooks`}
            AIprops={{
              type: contentTypeEnum.guidebook,
            }}
          />
        );
      case "button":
        console.log("GOT LISTING", listing);
        return (
          <>
            <div style={{display: "flex"}}>
              <FilledSelect
                label="Button type"
                value={field.button_type}
                className={classes.select}
                onChange={(e) => onValueChange("button_type", e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select a button type
                </MenuItem>
                {[
                  "external_link",
                  "boarding_pass_guidebook",
                  "boarding_pass_upsell",
                ].map((c) => (
                  <MenuItem value={c} key={c}>
                    {t(`button-type-${c}`)}
                  </MenuItem>
                ))}
              </FilledSelect>
              <FilledTextField
                fullWidth
                label="Button text"
                placeholder='i.e. "Visit Website"'
                value={
                  field.text.find((text) => text.language === selectedLang)
                    ?.value || ""
                }
                onChange={(e) => handleMultilangChange("text", e.target.value)}
              />
            </div>
            {field.button_type == "external_link" ? (
              <FilledTextField
                fullWidth
                label="Button link"
                placeholder="i.e. https://google.com"
                onChange={(e) => onValueChange("button_url", e.target.value)}
                value={field.button_url}
              />
            ) : (
              <FilledSelect
                fullWidth
                label={
                  field.button_type == "boarding_pass_upsell"
                    ? "Select an upsell"
                    : "Select a guidebook"
                }
                value={field.redirect_resource_id}
                className={classes.select}
                onChange={(e) =>
                  onValueChange("redirect_resource_id", e.target.value)
                }
              >
                <MenuItem value="" disabled>
                  {field.button_type == "boarding_pass_upsell"
                    ? "Select an upsell"
                    : "Select a guidebook"}
                </MenuItem>
                {(field.button_type == "boarding_pass_upsell"
                  ? upsells
                  : guidebooks
                ).map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {field.button_type == "boarding_pass_upsell"
                      ? c?.properties?.name?.[0]?.value
                      : c?.properties?.main_page?.name?.[0].value}
                  </MenuItem>
                ))}
              </FilledSelect>
            )}
          </>
        );
      case "place":
        let disable_inputs = !field.address;
        return (
          <div style={{position: "relative"}}>
            {disable_inputs && (
              <div className={classes.overlay}>
                <div className={classes.overlayCard}>
                  <Typography>Select a local place to get started</Typography>
                  <div style={{height: 10}} />
                  <GooglePlaceAutocomplete
                    placeName={field.place_name}
                    onPlaceChange={(newPlace) => selectPlace(newPlace)}
                  />
                </div>
              </div>
            )}
            <div className={disable_inputs && classes.blurredContent}>
              <FilledTextField
                fullWidth
                multiline
                disabled={disable_inputs}
                placeholder={"Enter a display name..."}
                label="Display Name"
                value={field.display_name}
                onChange={(e) => onValueChange("display_name", e.target.value)}
              />
              <div style={{display: "flex", marginTop: 10, marginBottom: 10}}>
                <Avatar
                  alt="Step Image"
                  src={field.image}
                  className={clsx(classes.avatar)}
                >
                  <ImageIcon className={classes.imageIcon} />
                </Avatar>
                <div style={{flex: 1}}>
                  <ImageTextField
                    picture={field.image}
                    available_pictures={listing?.listing_content?.pictures}
                    setPicture={(e) => onValueChange("image", e)}
                    disabled={disable_inputs}
                    label={`Image`}
                    uploadedImage={uploadedImage}
                    savePath={`${current_user}/`}
                    assetType={assetType}
                    setAssetType={setAssetType}
                  />
                  {/*<FilledTextField
                    fullWidth
                    multiline
                    disabled={disable_inputs}
                    placeholder={"Enter a summary..."}
                    label="Summary Text"
                    value={field.summary}
                    style={{marginTop: 10}}
                    onChange={(e) => onValueChange("summary", e.target.value)}
                  />*/}
                </div>
              </div>
              <FilledTextField
                fullWidth
                multiline
                disabled={disable_inputs}
                placeholder={"Enter a description..."}
                loading={loading}
                label="Description"
                value={
                  field.text.find((text) => text.language === selectedLang)
                    ?.value || ""
                }
                onChange={(e) => handleMultilangChange("text", e.target.value)}
                useCustomFields
                AIprops={{
                  type: contentTypeEnum.guidebook,
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.container}>
      {isCollapsed ? (
        // Collapsed view
        <div className={classes.row}>
          <div
            className={classes.inputContent}
            onClick={() => setIsCollapsed(false)}
            style={{cursor: "pointer"}}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <FieldTypeIcon type={fieldIconConfig[field.type]} />
              <Typography style={{marginLeft: 10}}>
                {t(`component-field-${field.type}`)}
              </Typography>
            </div>
            <Typography variant="body2" className={classes.previewText}>
              {field.loading || loading ? (
                <div className={classes.row}>
                  <div style={{display: "flex", width: "100%"}}>
                    <div style={{marginRight: 5}}>
                      EnsoAI is generating text...
                    </div>
                    <Skeleton variant="text" width="60px" />
                  </div>
                </div>
              ) : (
                getPreviewText(field) || "Click to add content..."
              )}
            </Typography>
          </div>
          <DeleteIconButton
            disablePadding
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </div>
      ) : (
        // Expanded view
        <div className={classes.row}>
          <div className={classes.inputContent}>
            <div style={{display: "flex", alignItems: "center"}}>
              <FieldTypeIcon type={fieldIconConfig[field.type]} />
              <Typography style={{marginLeft: 10}}>
                {t(`component-field-${field.type}`)}
              </Typography>
              {/*<IconButton onClick={() => setIsCollapsed(true)}>
                  <ExpandLessIcon />
                </IconButton>*/}
            </div>
            {renderField()}
          </div>
          <DeleteIconButton disablePadding onClick={onDelete} />
        </div>
      )}
    </div>
  );
}
