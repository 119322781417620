import React from "react";
import VirtualizedTableComponent from "connectui/component/layout/table/VirtualizedTableComponent";
import {Button} from "@material-ui/core";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Text from "../../../../connectui/component/display/Text";

export default function CampaignListTable({campaigns, onCampaignSelect}) {
  const {theme} = useTheme();

  const tableColumns = [
    {
      label: "Name",
      field: "name",
      sortable: true,
      render: (data) => <Text variant={"textMD"}>{data.name || "N/A"}</Text>,
      getSortValue: (data) => data.name?.toLowerCase() || "",
    },
    {
      label: "Send Time",
      field: "actions",
      sortable: true,
      render: (data) => {
        const action = data.actions?.[0];
        const date = action?.trigger_value
          ? new Date(Number(action.trigger_value))
          : null;
        const displayValue = date ? date.toLocaleString() : "N/A";
        const isSent = date && date < new Date();

        return (
          <>
            <Text variant={"textMD"}>{displayValue}</Text>
            {isSent && (
              <Button
                variant="contained"
                style={{
                  borderRadius: "5px",
                  background: "#DCE5EC",
                  color: "#275588",
                  padding: "1px",
                  marginLeft: theme.spacing(2),
                  fontSize: "12px",
                }}
              >
                Sent
              </Button>
            )}
          </>
        );
      },
      getSortValue: (data) => {
        const action = data.actions?.[0];
        return action?.trigger_value
          ? Number(action.trigger_value)
          : Number.MAX_SAFE_INTEGER;
      },
    },
    {
      label: "# Guests",
      field: "connected_to",
      sortable: true,
      render: (data) => (
        <Text variant={"textMD"}>{data.connected_to?.ids?.length || 0}</Text>
      ),
      getSortValue: (data) => data.connected_to?.ids?.length || 0,
    },
  ];
  const isRowLoaded = ({index}) => index < campaigns.length;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90%",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <VirtualizedTableComponent
        columns={tableColumns}
        data={campaigns}
        totalRows={campaigns.length}
        rowHeight={80}
        isRowLoaded={isRowLoaded}
        onClickRow={(index) => {
          if (index >= campaigns.length) return;
          const selectedCampaign = campaigns[index];
          onCampaignSelect(selectedCampaign);
        }}
        loadMoreRows={() => console.log("LOAD MORE ROWS")}
        defaultSortField="name"
        defaultSortDirection="ASC"
      />
    </div>
  );
}
