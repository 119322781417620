import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {InboxContext} from "routes/MessagesPage";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../../connectui/component/buildStyles";
import {ReactComponent as ArrowLeftIcon} from "connectui/icons/arrow-left.svg";
// Components
import Text from "connectui/component/display/Text";
import SvgIcon from "connectui/component/display/SvgIcon";
import FlexContainer from "connectui/component/layout/FlexContainer";
import FilterList from "./FilterList";
import Container from "connectui/component/layout/Container";
import Divider from "connectui/component/display/Divider";
import LightButtonBase from "connectui/component/input/button/base/LightButtonBase";
import ButtonText from "connectui/component/input/button/ButtonText";
import TextButtonBase from "connectui/component/input/button/base/TextButtonBase";
import SelectedFiltersOverview from "../../filterlist/SelectedFiltersOverview";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      width: "100%",
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
    icon: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    },
    actionsRow: {
      width: "100%",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(0, 4, 5),
    },
  });

const FiltersPanel = ({onClose = () => null, saveView = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const {appliedFilters, setAppliedFilters} = useContext(InboxContext);
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const applyFilters = () => {
    if (viewId) {
      window.history.pushState({}, "", window.location.pathname);
    }
    setAppliedFilters(selectedFilters);
    onClose();
  };

  const handleClearAll = () => {
    setSelectedFilters([]);
    setAppliedFilters([]);
    if (viewId) {
      window.history.pushState({}, "", window.location.pathname);
    }
    onClose();
  };

  return (
    <FlexContainer
      className={classes.container}
      flexDirection="column"
      gap={theme.spacing(4)}
    >
      <FlexContainer
        className={classes.header}
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <SvgIcon
          component={ArrowLeftIcon}
          className={classes.icon}
          onClick={() => onClose()}
        />
        <Text variant="displayXS" weight="semibold" sx={{flex: 1}}>
          {t("filters-panel-header")}
        </Text>
        <ButtonText
          buttonBase={TextButtonBase}
          typographyVariant="textSM"
          weight="semibold"
          color="primary"
          label="Clear All"
          onClick={handleClearAll}
        />
      </FlexContainer>
      <FilterList filters={selectedFilters} setFilters={setSelectedFilters} />
      <SelectedFiltersOverview
        filters={selectedFilters}
        setFilters={setSelectedFilters}
      />
      <Container className={classes.actionsRow}>
        <Divider sx={{marginTop: "0px"}} />
        <FlexContainer gap={theme.spacing(3)}>
          <ButtonText
            sx={{flex: 1}}
            typographyVariant="textSM"
            weight="semibold"
            color="white"
            label="Apply Filters"
            onClick={applyFilters}
          />
          <ButtonText
            sx={{flex: 1}}
            typographyVariant="textSM"
            weight="semibold"
            color="primary"
            disabled={!selectedFilters.length}
            buttonBase={LightButtonBase}
            label="Save as View"
            onClick={() => saveView(selectedFilters)}
          />
        </FlexContainer>
      </Container>
    </FlexContainer>
  );
};

export default FiltersPanel;
