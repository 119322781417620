import React, {useState} from "react";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import ListItemContainer from "../../../../connectui/component/layout/list/ListItemContainer";
import Container from "../../../../connectui/component/layout/Container";
import Text from "../../../../connectui/component/display/Text";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import FlexContainer from "connectui/component/layout/FlexContainer";
import SvgIcon from "connectui/component/display/SvgIcon";
import ICONS from "connectui/icons/iconMap";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";

const useStyles = (theme) =>
  buildStyles({
    itemRow: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
      width: "100%",
    },
    hoverActionsContainer: {
      width: "auto",
      overflow: "visible",
      flex: 1,
    },
    hoverIcon: {
      width: "16px",
      height: "16px",
    },
    editIcon: {
      width: "16px",
      height: "16px",
      color: theme.colors.textSecondary,
      display: "inline-flex !important",
      marginLeft: theme.spacing(2),
      verticalAlign: "text-bottom",
    },
  });

const FiltersSetItem = ({
  icon = null,
  label,
  onEditName,
  customLabel,
  dropdownContent = null,
  totalCount = null,
  hoverActions = null,
  openContextMenu,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const [isHovered, setIsHovered] = useState(false);
  const {itemProps = {}, ...rest} = props;

  return (
    <ListItemContainer
      defaultCollapsed
      nestedList={dropdownContent}
      indentSpacing={theme.spacing(8)}
      itemProps={{
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
        ...itemProps,
      }}
      {...rest}
    >
      <Container className={classes.itemRow}>
        {icon}
        <Text
          as="div"
          variant="textSM"
          weight="medium"
          color="gray700"
          sx={{display: "inline"}}
        >
          {label}
          {!!onEditName && isHovered && (
            <SvgIcon
              className={classes.editIcon}
              component={ICONS.edit}
              onClick={onEditName}
            />
          )}
        </Text>
        {totalCount !== null && (
          <ChipText
            text={totalCount}
            sx={{
              "&:hover": {backgroundColor: theme.colors.primary100},
            }}
          />
        )}
        {isHovered && (
          <FlexContainer
            className={classes.hoverActionsContainer}
            alignItems="center"
            justifyContent="flex-end"
            gap={theme.spacing(2)}
          >
            <FlexContainer alignItems="center" gap={theme.spacing(2)}>
              <ButtonIcon
                buttonBase={OutlinedButtonBase}
                sx={{
                  padding: "0px",
                  borderRadius: `${theme.radius.sm}px`,
                }}
                icon={
                  <SvgIcon
                    className={classes.hoverIcon}
                    component={ICONS["more-vertical"]}
                  />
                }
                onClick={openContextMenu}
              />
            </FlexContainer>
          </FlexContainer>
        )}
      </Container>
    </ListItemContainer>
  );
};

export default FiltersSetItem;
