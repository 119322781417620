import React, {useState} from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Grid,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import ImageCarousel from "ui/base/carousel/ImageCarousel";
import TruncatedText from "ui/base/text/TruncatedText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import ImageIcon from "@material-ui/icons/Image"; // Import ImageIcon
import HTMLContent from "./HTMLContent";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    width: "100%",
    borderRadius: "10px",
    backgroundColor: props.disableBackground
      ? "inherit"
      : theme.palette.primary.main + "1A", // 10% opacity if not disabled
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  }),
  cardContent: {
    flex: 1,
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  placeImage: {
    borderRadius: "12px",
    //width: "100%",
    height: 200, // Fixed height for images
    objectFit: "cover", // Ensure cover style for image fill
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  imagePlaceholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //width: "100%",
    height: 200, // Fixed height for placeholder
    backgroundColor: theme.palette.primary.main + "4A",
    borderRadius: "12px",
  },
  actions: {
    marginTop: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(1),
  },
  rating: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "15px",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  text: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
    fontSize: "12px",
    lineHeight: "20px",
    whiteSpace: "pre-line", // Allow newlines to be rendered
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  headerLabel: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    borderRadius: "8px",
    fontSize: "12px",
    color: theme.palette.primary.contrastText,
  },
  summaryContent: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(0.5),
    width: "100%",
  },
  accordion: {
    backgroundColor: theme.palette.primary.main + "4D", // 30% opacity
    paddingRight: 0,
    width: "100%",
    paddingLeft: 0,
  },
  list: {
    paddingRight: 0,
    width: "100%",
    paddingLeft: 0,
  },
  listBullet: {
    color: theme.palette.primary.main,
    display: "inline-block",
    width: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
  },
  accordionSummary: {
    width: "100%",
    "& .MuiAccordionSummary-content": {
      width: "100%",
      margin: 0, // Remove default margin
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  sectionHeader: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.getContrastText(theme.palette.primary.main),
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontSize: "14px",
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

// Main GuidebookCard component
const GuidebookCard = ({
  components,
  disableBackground = false,
  maxImages = 10,
  disable_card = false,
}) => {
  const classes = useStyles({disableBackground});
  const theme = useTheme();
  const {t} = useTranslation();

  // Helper function to render individual components
  const renderComponent = (component) => {
    const maxHeight = component.collapse_after_pixels || "none";
    switch (component.type) {
      case "place":
        return renderPlaceComponent(component);
      case "header":
        return (
          <>
            {component.label && component?.label?.length !== 0 && (
              <Box display="flex" mb={1}>
                <Box className={classes.headerLabel}>{component.label}</Box>
              </Box>
            )}
            <Box display="flex" alignItems="center">
              {!!component?.icon && (
                <Avatar className={classes.avatar}>{component.icon}</Avatar>
              )}
              <Box>
                <Typography style={{fontSize: "20px", fontWeight: "bold"}}>
                  {component.text || "Enter text..."} {/* Default text */}
                </Typography>
              </Box>
            </Box>
          </>
        );
      case "text":
        return (
          <TruncatedText
            text={component.text || "Enter text..."}
            maxHeight={maxHeight}
          >
            <HTMLContent v={component.text || "Enter text..."} />
          </TruncatedText>
        );
      case "image":
        return component.image ? (
          component.image.length > 1 ? (
            <ImageCarousel images={component.image} maxImages={maxImages} />
          ) : (
            <CardMedia
              className={classes.placeImage}
              image={component.image[0]}
              component="img"
              alt="Image"
            />
          )
        ) : (
          <Box className={classes.imagePlaceholder}>
            <ImageIcon
              style={{fontSize: 40, color: theme.palette.text.disabled}}
            />
          </Box>
        );
      case "button":
        return (
          <Button
            className={classes.button}
            href={
              component.button_type === "external_link"
                ? component.button_url
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
          >
            {component.text || "Enter text..."} {/* Default text */}
          </Button>
        );
      case "summary":
        return (
          <>
            <TruncatedText
              text={component.text || "Enter text..."}
              maxHeight={maxHeight}
            >
              <HTMLContent v={component.text || "Enter text..."} />
            </TruncatedText>
            {component.show_contents && component.table_of_contents && (
              <Box className={classes.summaryContent}>
                <ul style={{padding: theme.spacing(2), width: "100%"}}>
                  {component.table_of_contents.map((item, index) => (
                    <li key={index}>
                      <Typography className={classes.text}>
                        <a
                          href={`#card-${item.index}`}
                          style={{color: theme.palette.primary.main}}
                        >
                          {item.label}
                        </a>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </>
        );
      case "list":
        return renderListComponent(component);
      default:
        return null;
    }
  };

  // Render a List component with different styles
  const renderListComponent = (component) => {
    if (component.collapsable) {
      return (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Typography
              className={classes.sectionTitle}
              style={{fontWeight: "bold"}}
            >
              {component.text}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <div className={classes.list} style={{width: "100%"}}>
                {component.list_items.map((item, index) => (
                  <div
                    key={index}
                    className={classes.listItem}
                    style={{width: "100%"}}
                  >
                    <Box display="flex" style={{width: "100%"}}>
                      {component.list_style === "numbered" && (
                        <Typography
                          className={classes.text}
                          style={{minWidth: theme.spacing(3)}}
                        >
                          {index + 1}.
                        </Typography>
                      )}
                      {component.list_style === "bulleted" && (
                        <Typography className={classes.listBullet}>
                          •
                        </Typography>
                      )}
                      <Typography style={{flex: 1}} className={classes.text}>
                        {item.primary_text || "Enter text..."}{" "}
                        {/* Default text */}
                      </Typography>
                      {["divided", "numbered"].includes(component.list_style) &&
                        !!item.secondary_text &&
                        item.secondary_text?.length !== 0 && (
                          <Typography className={classes.text}>
                            {item.secondary_text}
                          </Typography>
                        )}
                    </Box>
                    {component.list_style === "bulleted" &&
                      !!item.secondary_text &&
                      item.secondary_text?.length !== 0 && (
                        <Typography
                          className={classes.text}
                          style={{marginLeft: theme.spacing(2)}}
                        >
                          {item.secondary_text}
                        </Typography>
                      )}
                    {component.list_style === "divided" &&
                      index < component.list_items.length - 1 && (
                        <Divider className={classes.divider} />
                      )}
                  </div>
                ))}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Box width="100%">
        <Typography className={classes.text}>
          {component.text || "Enter text..."}
        </Typography>
        <div className={classes.list} style={{width: "100%"}}>
          {component.list_items.map((item, index) => (
            <div
              key={index}
              className={classes.listItem}
              style={{width: "100%"}}
            >
              <Box display="flex" style={{width: "100%"}}>
                {component.list_style === "numbered" && (
                  <Typography
                    className={classes.text}
                    style={{minWidth: theme.spacing(3)}}
                  >
                    {index + 1}.
                  </Typography>
                )}
                {component.list_style === "bulleted" && (
                  <Typography className={classes.listBullet}>•</Typography>
                )}
                <Typography style={{flex: 1}} className={classes.text}>
                  {item.primary_text || "Enter text..."} {/* Default text */}
                </Typography>
                {["divided", "numbered"].includes(component.list_style) &&
                  !!item.secondary_text &&
                  item.secondary_text?.length !== 0 && (
                    <Typography className={classes.text}>
                      {item.secondary_text}
                    </Typography>
                  )}
              </Box>
              {component.list_style === "bulleted" &&
                !!item.secondary_text &&
                item.secondary_text?.length !== 0 && (
                  <Typography
                    className={classes.text}
                    style={{marginLeft: theme.spacing(2)}}
                  >
                    {item.secondary_text}
                  </Typography>
                )}
              {component.list_style === "divided" &&
                index < component.list_items.length - 1 && (
                  <Divider className={classes.divider} />
                )}
            </div>
          ))}
        </div>
      </Box>
    );
  };

  // Render a Place component separately as a detailed card
  const renderPlaceComponent = (place) => (
    <>
      {place.image ? (
        <CardMedia
          className={classes.placeImage}
          component="img"
          image={place.image}
          alt={`Image of ${place.name}`}
        />
      ) : (
        <Box className={classes.imagePlaceholder}>
          <ImageIcon
            style={{fontSize: 40, color: theme.palette.text.disabled}}
          />
        </Box>
      )}
      <Typography style={{fontSize: "20px", fontWeight: "bold"}}>
        {place.display_name || "Enter text..."} {/* Default text */}
      </Typography>
      <Box className={classes.rating}>
        {[...Array(5)].map((_, i) => (
          <StarIcon
            key={i}
            style={{
              color: i < place.star_rating ? "#c29b02" : "#4E4E4E",
            }}
          />
        ))}
        {
          <Typography style={{marginLeft: "8px"}}>
            {/*place.reviews.length*/} {place.star_rating}
          </Typography>
        }
      </Box>
      <Box className={classes.actions}>
        <Button
          fullWidth
          href={place.website}
          variant="contained"
          className={classes.button}
        >
          Website
        </Button>
        <Button
          fullWidth
          href={`https://www.google.com/maps/dir/?api=1&destination=${place.address?.lat},${place.address?.lng}`}
          variant="contained"
          className={classes.button}
        >
          Directions
        </Button>
      </Box>
      <Typography className={classes.text} style={{marginTop: 10}}>
        {place.text || "Enter text..."} {/* Default text */}
      </Typography>
    </>
  );

  // Main render logic
  return disable_card ? (
    <>
      {components.map((cardComponents, cardIndex) => (
        <Box key={cardIndex} id={`card-${cardIndex}`}>
          {cardComponents.map((component, componentIndex) => (
            <Box key={componentIndex} mb={1} width="100%">
              {renderComponent(component)}
            </Box>
          ))}
        </Box>
      ))}
    </>
  ) : (
    <Grid container spacing={1} direction="column">
      {components.map((cardComponents, cardIndex) => (
        <Grid item xs={12} key={cardIndex} id={`card-${cardIndex}`}>
          <Card className={classes.card} elevation={0}>
            <CardContent className={classes.cardContent}>
              {cardComponents.map((component, componentIndex) => (
                <Box key={componentIndex} mb={1} width="100%">
                  {renderComponent(component)}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GuidebookCard;
