import React from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Text from "../../../../connectui/component/display/Text";
import ButtonText from "../../../../connectui/component/input/button/ButtonText";
import PhoneField from "../../../../core/inputs/PhoneField";
import OutlinedButtonBase from "../../../../connectui/component/input/button/base/OutlinedButtonBase";
import Container from "../../../../connectui/component/layout/Container";
import DropdownSelect from "../../../../connectui/component/display/dropdown/DropdownSelect";

export default function MessagingFeaturesPanel({setStageId}) {
  const {theme} = useTheme();

  return (
    <FlexContainer
      flexDirection={"column"}
      gap={theme.spacing(5)}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{paddingBottom: theme.spacing(3), width: "460px"}}
    >
      <Text variant={"displayMD"} weight={"bold"}>
        Choose Your Messaging Features
      </Text>
      <Text variant={"textLG"}>
        Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum
        Lorum Ipsum Lorum Ipsum Lorum Ipsum{" "}
      </Text>

      <DropdownSelect
        label="Select Country"
        value={"Canada"}
        onChange={(value) => console.log(value)}
        customLabelComponent={(value) =>
          value ? (
            <Container sx={{display: "flex", alignItems: "center", gap: "8px"}}>
              <img
                src="https://flagcdn.com/ca.svg"
                alt="Canada"
                style={{width: "20px", height: "20px", borderRadius: "50%"}}
              />
              <Text variant="textMD" weight="semibold">
                {value}
              </Text>
            </Container>
          ) : (
            <Text variant="textMD" color="textSecondary">
              Select a country
            </Text>
          )
        }
      >
        <Container value="Canada" sx={{padding: "8px 16px", cursor: "pointer"}}>
          <img
            src="https://flagcdn.com/ca.svg"
            alt="Canada"
            style={{width: "20px", height: "20px", marginRight: "8px"}}
          />
          Canada
        </Container>
        <Container value="USA" sx={{padding: "8px 16px", cursor: "pointer"}}>
          <img
            src="https://flagcdn.com/us.svg"
            alt="USA"
            style={{width: "20px", height: "20px", marginRight: "8px"}}
          />
          USA
        </Container>
        <Container value="Mexico" sx={{padding: "8px 16px", cursor: "pointer"}}>
          <img
            src="https://flagcdn.com/mx.svg"
            alt="Mexico"
            style={{width: "20px", height: "20px", marginRight: "8px"}}
          />
          Mexico
        </Container>
      </DropdownSelect>

      <Text variant={"textSM"}>
        Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum Lorum Ipsum
        Lorum Ipsum Lorum Ipsum Lorum Ipsum{" "}
      </Text>
      <ButtonText
        label={"Continue"}
        sx={{width: "100%"}}
        typographyVariant="textSM"
        weight="semibold"
        onClick={() => setStageId(3)}
        color="white"
      />
      <ButtonText
        label={"Cancel"}
        sx={{width: "100%"}}
        buttonBase={OutlinedButtonBase}
        typographyVariant="textSM"
        weight="semibold"
        onClick={() => setStageId(1)}
      />
    </FlexContainer>
  );
}
