import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Chip,
  makeStyles,
  CircularProgress,
  IconButton,
  Typography,
  Box,
  Button,
  Divider,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import HTMLField from "components/TextFields/HTMLField";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// Actions
import {
  closeGeneralSuccessAlert,
  closeGeneralError,
  openGeneralSuccessAlert,
  openGeneralError,
} from "redux/actions/settingsActions";
import {syncAccount, signUpUrl} from "redux/actions/accountsActions";
import {deleteConnectedAccount} from "redux/actions/accountsActions";
import {setSelectedListingGroups} from "redux/actions/listingsActions";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import DeviceList from "components/Lists/DeviceList";
import WhatsappTemplateList from "components/Lists/WhatsappTemplateList";
import WarningAlert from "core/alerts/WarningAlert";
// Utilities
import {relativeTime} from "utilities/helperFunctions.js";
import CustomDialog from "core/dialogs/CustomDialog";
import MatchListingsListings from "components/Cards/MatchListingsListingsCard";
import InputField from "core/inputs/InputField";
import {patchAccount} from "redux/api/accountsAPI";
import {patchServiceStripe} from "redux/api/servicesAPI";
import {setServiceAccounts} from "../../redux/actions/servicesActions";
import ScopedListingGroupsModal from "../Dialogs/ScopedListingGroupsModal";
import {useTranslation} from "react-i18next";

const sync_label = {
  SYNC_BOOKINGS: "Bookings",
  SYNC_LISTINGS: "Listings",
  SYNC_DEVICES: "Devices",
  SYNC_TEMPLATES: "Templates",
  SYNC_PHONE_NUMBERS: "Phone Number",
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  section: {whiteSpace: "pre-wrap"},
  bold: {fontWeight: 500},
  bolder: {fontWeight: 700},
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
  },
  chipRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  listSections: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    height: "100%",
  },
  divider: {backgroundColor: "rgba(13, 86, 140, 0.05)"},
  selected: {backgroundColor: theme.palette.secondary.main + "20!important"},
  btn: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(2),
  },
  itemTextRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imgIcon: {
    height: 60,
    width: "auto",
    objectFit: "contain",
    maxWidth: "100%",
  },
  ensoIconContainer: {
    display: "flex",
    flexDirection: "column",
    width: 240,
    padding: theme.spacing(3, 5),
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    border: "1.5px solid #E5E5EA",
    background: "#FFF",
    borderRadius: 10,
    height: "fit-content",
  },
  includedServicesRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  includedService: {
    background: "#F8F8F9",
    padding: theme.spacing(3),
    borderRadius: 5,
    minWidth: "fit-content",
  },
  chip: {
    maxWidth: 150,
    backgroundColor: "rgba(13, 86, 140, 0.75)",
  },
  infoText: {
    fontWeight: 400,
    marginTop: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(4),
    flex: 1,
    overflow: "auto",
  },
  stripeButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  subheader: {marginTop: theme.spacing(3)},
  actions: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    marginTop: 0,
  },
  closeBtn: {padding: 2},
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
}));

const ConnectedAccountPanel = ({
  account,
  integration_spec,
  devices,
  onClose,
  handleDeleteAccount,
  setTriggerRefresh,
}) => {
  console.log("ConnectedAccountPanel GOT account", account);
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [syncing, setSyncing] = useState(false);
  const [reauthenticating, setReauthenticating] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [mapListingsModalOpen, setMapListingsModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [guestyReauthModalOpen, setGuestyReauthModalOpen] = useState(false);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const [guestyIntegrationToken, setGuestyIntegrationToken] = useState("");
  const [loading, setLoading] = useState(false);
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );

  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [scopedGroupsModalOpen, setScopedGroupsModalOpen] = useState(false);
  const selectedListingGroups = useSelector(
    (state) => state.defaultReducer.selected_listing_groups,
  );
  let syncInProgress = (account?.sync_data ?? []).some(
    (syncData) => syncData.currently_syncing,
  );
  React.useEffect(() => {
    setSelectedGroups(
      account.integration_service_id in selectedListingGroups
        ? selectedListingGroups[account.integration_service_id]
        : account.group_ids || [],
    );
  }, [account]);

  function triggerSync(action) {
    setSyncing((prev) => action);
    if (account && account.sync_data) {
      account.sync_data.forEach((syncItem) => {
        if (syncItem.action === action) {
          syncItem.currently_syncing = true;
          console.log("UPDATED SYNC ITEM", syncItem);
        }
      });
    }
    const serviceAccountsCopy = {...serviceAccounts};

    Object.keys(serviceAccountsCopy.accounts).forEach((key) => {
      const serviceAccountArray = serviceAccountsCopy.accounts[key];

      if (Array.isArray(serviceAccountArray)) {
        serviceAccountArray.forEach((serviceAccount) => {
          if (!serviceAccount.sync_data) {
            return;
          }
          if (serviceAccount.sync_data) {
            serviceAccount.sync_data.forEach((syncItem) => {
              if (syncItem.action === action) {
                syncItem.currently_syncing = true;
                console.log("UPDATED SYNC ITEM", syncItem);
              }
            });
          }
        });
      }
    });
    dispatch(
      setServiceAccounts({
        ...serviceAccountsCopy,
        accounts: {...serviceAccountsCopy.accounts},
      }),
    );

    dispatch(
      syncAccount(
        {account: account, action: action},
        () => {
          if (account && !account.sync_data) {
            setSyncing(false);
          }
        },
        () => setSyncing((prev) => false),
      ),
    );
  }

  function deleteAccount(account) {
    console.log("GOT ACCOUNT", account);
    let params = "";
    if (!!account.service_id) {
      params = `service_id=${encodeURIComponent(account.service_id)}`;
    } else {
      params = `integration_type=${account.integration_type}&integration_host_id=${account.integration_host_id}`;
    }
    setLoading(true);
    dispatch(
      deleteConnectedAccount(params, () => {
        console.log("GOT RESPONSE");
        handleDeleteAccount(account);
        setLoading(false);
        setConfirmDeleteOpen(false);
      }),
    );
  }

  function guestyPatchAccount(integration_token) {
    setReauthenticating(true);
    patchAccount({
      body: {
        enso_key: current_user,
        integration_type: "gy",
        integration_host_id: account.integration_host_id,
        options: {
          gy_jwt_token: integration_token,
        },
      },
    })
      .then((r) => {
        window.location.href =
          "/admin/settings/accounts/:redirect/:redirect_status";
      })
      .finally(() => {
        setReauthenticating(false);
      });
  }

  function handleReauth() {
    setReauthenticating(true);

    if (account.integration_type === "hfl") {
      dispatch(
        signUpUrl(
          {
            integration_host_id: account.integration_host_id,
            integration_type: account.integration_type,
          },
          (response) => {
            window.open(response.redirect_url);
            setReauthenticating(false);
          },
          () => {
            dispatch(openGeneralError(`Failed to get redirect url.`));
            setReauthenticating(false);
          },
        ),
      );
    }
    // Keep Guesty reauth modal for now, until we migrate to the new integration
    else if (account.integration_type === "gy") {
      setGuestyReauthModalOpen(true);
    } else if (account.integration_type === "abnb") {
      dispatch(
        signUpUrl(
          {integration_type: "abnb"},
          (response) => {
            setReauthenticating(false);
            window.open(response.redirect_url);
          },
          () => {
            setReauthenticating(false);
            dispatch(openGeneralError("Failed to get Airbnb Redirect Url"));
          },
        ),
      );
    } else {
      // Call AccountsPOST API in connected account page
      window.location.href = "/admin/connect";
    }
  }

  function SyncDataChip(sync_action, sync_data = []) {
    let sync = sync_data.find((s) => s.action === sync_action) || {};

    let in_progress;

    console.log(sync);

    if ("currently_syncing" in sync) {
      in_progress = sync_action === syncing || sync.currently_syncing;
    } else {
      in_progress =
        sync_action === syncing ||
        (!!sync.action && sync.action === sync_action && !sync.end);
    }
    return (
      <div className={classes.chipRow}>
        <Chip
          onClick={() => {
            if (in_progress) {
              return;
            }
            triggerSync(sync_action);
          }}
          clickable={!in_progress}
          disabled={in_progress}
          size="small"
          avatar={
            in_progress ? (
              <CircularProgress color="inherit" size="30" />
            ) : (
              <SyncIcon />
            )
          }
          label={
            <Box sx={{marginTop: 0}}>
              {in_progress
                ? `${sync_label[sync_action]} Sync in progress`
                : `Manually Sync ${sync_label[sync_action]}`}
            </Box>
          }
        />
        <Typography>
          {sync.start && ` - Last updated ${relativeTime(sync.start)} ago`}
        </Typography>
      </div>
    );
  }

  // Doesn't handle pms with generated integration_host_id and legacy pms (except Guesty) yet
  const reauthIntegrations = [
    "hfl",
    "gy",
    "abnb",
    "bks",
    "ownrz",
    "lmpm",
    "avt",
  ];

  function ReauthAccountChip() {
    if (!reauthIntegrations.includes(account.integration_type)) {
      return <></>;
    }
    return (
      <div className={classes.row}>
        <Chip
          onClick={handleReauth}
          clickable={!reauthenticating}
          size="small"
          avatar={
            reauthenticating ? (
              <CircularProgress color="inherit" size="30" />
            ) : (
              <AccountCircleIcon />
            )
          }
          label="Reauth Account"
        />
      </div>
    );
  }

  const guestyReauthModal = (
    <CustomDialog
      title="Guesty Integration Key"
      maxWidth="sm"
      open={guestyReauthModalOpen}
      onClose={() => setGuestyReauthModalOpen(false)}
      content={
        <>
          <div>
            <Typography>
              To obtain your Guesty integration key:
              <ol>
                <Typography component="li">
                  Sign in to your Guesty account.
                </Typography>
                <Typography component="li">
                  In the top navigation bar, click the icon drop-down menu and
                  select Setup mode.
                </Typography>
                <Typography component="li">
                  Click Integrations, then select Marketplace.
                </Typography>
                <Typography component="li">
                  Search for "Enso Connect" then click Connect.
                </Typography>
                <Typography component="li">
                  At the top right click Connect.
                </Typography>
                <Typography component="li">
                  In the pop-up, copy the integration key.
                </Typography>
                <Typography component="li">Click OK.</Typography>
              </ol>
            </Typography>
          </div>
          <InputField
            label="Integration Key"
            disableMultiline
            fullWidth
            editOnly
            onChange={(event) => setGuestyIntegrationToken(event.target.value)}
          />
        </>
      }
      labelConfirm="Submit"
      actionConfirm={() => {
        guestyPatchAccount(guestyIntegrationToken);
        setGuestyReauthModalOpen(false);
      }}
    />
  );

  const mapListingsModal = (
    <MatchListingsListings
      account={account}
      open={mapListingsModalOpen}
      onClose={() => setMapListingsModalOpen(false)}
    />
  );

  const mapListingsButton = (
    <Chip
      onClick={() => setMapListingsModalOpen(true)}
      clickable={true}
      size="small"
      avatar={
        syncing ? <CircularProgress color="inherit" size="30" /> : <SyncIcon />
      }
      label="Pair Listings"
    />
  );

  const [tooltipText, setTooltipText] = useState("Click to copy");
  const [openTooltip, setOpenTooltip] = useState(false);

  const accountIdChip = (accountId) => {
    return (
      <div className={classes.chipRow}>
        <Typography style={{paddingRight: "10px", marginTop: "1px"}}>
          Account ID:
        </Typography>
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
          <Tooltip
            title={tooltipText}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Chip
              size="small"
              label={<Box sx={{marginTop: 0}}>{accountId}</Box>}
              onClick={() => {
                navigator.clipboard.writeText(accountId).then(() => {
                  setTooltipText("Copied!");
                  setOpenTooltip(true);
                  setTimeout(() => {
                    setOpenTooltip(false);
                    setTooltipText("Click to copy");
                  }, 1000);
                });
              }}
              onMouseEnter={() => {
                setTooltipText("Click to copy");
                setOpenTooltip(true);
              }}
              onMouseLeave={() => {
                if (tooltipText === "Click to copy") {
                  setOpenTooltip(false);
                }
              }}
              style={{cursor: "pointer"}}
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
    );
  };

  function getAccountActions() {
    if (integration_spec.service === "pms") {
      return (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Information</Typography>
            </Box>
            <Typography>
              {account?.listings?.length} Listings Connected
            </Typography>

            {accountIdChip(account.integration_host_id)}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Tools</Typography>
            </Box>
            <Typography style={{paddingBottom: "10px"}}>
              Trigger a manual re-sync of your bookings or listings to refresh
              out-of-date information.
            </Typography>
            {SyncDataChip("SYNC_LISTINGS", account.sync_data)}
            {SyncDataChip("SYNC_BOOKINGS", account.sync_data)}
            {ReauthAccountChip()}
            {account.integration_type === "abnb" ? mapListingsButton : ""}
          </div>
        </>
      );
    } else if (integration_spec.service === "sl") {
      return (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Information</Typography>
            </Box>

            {accountIdChip(account.integration_service_id)}

            <Typography>{devices?.length || 0} Devices Connected</Typography>
            <div
              style={{
                border: "1.5px solid #E5E5EA",
                marginTop: 5,
                borderRadius: 10,
                maxHeight: "200px",
                overflow: "scroll",
              }}
            >
              <DeviceList
                selectedDevice={selectedDevice}
                onDeviceSelect={(newDevice) =>
                  setSelectedDevice((prev) => newDevice)
                }
                initialDevices={devices || []}
                includeHeader={false}
                disabled={true}
                flat={true}
              />
            </div>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Tools</Typography>
            </Box>
            <Typography>
              Trigger a manual re-sync of your devices to refresh out-of-date
              information.
            </Typography>
            {SyncDataChip("SYNC_DEVICES")}
          </div>
        </>
      );
    } else if (integration_spec.provider === "stripe") {
      return (
        <>
          {/*<Divider className={classes.divider}/>*/}
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Information</Typography>
            </Box>

            {accountIdChip(account.integration_service_id)}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Tools</Typography>
            </Box>
            <Typography>
              Manage your payouts & access additional accounting functionality
              in the Stripe dashboard.
            </Typography>
            <div className={classes.chipRow}>
              <Chip
                onClick={() =>
                  window.open(account.properties?.login_details?.url)
                }
                clickable={true}
                style={{marginTop: 3}}
                size="small"
                label={<Box sx={{marginTop: 0}}>{"Manage Payouts"}</Box>}
              />
            </div>
          </div>
        </>
      );
    } else if (integration_spec.provider === "whatsapp") {
      return (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Account Tools</Typography>
            </Box>
            <Typography>
              Refresh your templates & phone numbers from Whatsapp.
            </Typography>
            {SyncDataChip("SYNC_TEMPLATES", account.sync_data)}
            {SyncDataChip("SYNC_PHONE_NUMBERS", account.sync_data)}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <WhatsappTemplateList account={account} />
          </div>
        </>
      );
    }
  }

  const handleSuccessAlertClose = () => dispatch(closeGeneralSuccessAlert());

  const handleErrorAlertClose = () => dispatch(closeGeneralError());

  const handlePatchStripe = () => {
    console.log("account", account);
    console.log("selectedGroups", selectedGroups);
    patchServiceStripe({
      listing_group_ids: selectedGroups,
      integration_service_id: account.integration_service_id,
    })
      .then((r) => {
        console.log("GOT PATCH SERVICE STRIPE RESPONSE", r);
        if (r.success) {
          dispatch(
            openGeneralSuccessAlert({
              open: true,
              message: "Stripe account updated",
              onClose: handleSuccessAlertClose,
            }),
          );
          // Reload the page to reflect the changes
          // window.location.href = '/admin/settings/accounts/:redirect/:redirect_status'
          const obj = selectedListingGroups;
          obj[account.integration_service_id] = selectedGroups;
          dispatch(setSelectedListingGroups(obj));
          setTriggerRefresh((prev) => !prev);
        } else {
          dispatch(
            openGeneralError({
              open: true,
              message: "Failed to update Stripe account",
              subtitle: "Something went wrong, please try again later",
              onClose: handleErrorAlertClose,
            }),
          );
        }
      })
      .catch((e) => {
        console.error("Failed to patch stripe", e);
        dispatch(
          openGeneralError({
            open: true,
            message: "Failed to update Stripe account",
            subtitle: "Something went wrong, please try again later",
            onClose: handleErrorAlertClose,
          }),
        );
      });
  };

  return (
    <div className={classes.root}>
      {mapListingsModal}
      {guestyReauthModal}
      <ScopedListingGroupsModal
        open={{isOpen: scopedGroupsModalOpen, typed: account}}
        onClose={() => {
          setScopedGroupsModalOpen(false);
        }}
        onConfirm={() => {
          handlePatchStripe();
          setScopedGroupsModalOpen(false);
        }}
        selectedListings={selectedGroups}
        setSelectedListings={setSelectedGroups}
      />

      <ConfirmDialog
        disableDismiss
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        title={`Permanently delete ${integration_spec.title} account?`}
        message={
          <span style={{whiteSpace: "pre-wrap"}}>
            {integration_spec.delete_text || "This cannot be undone"}
          </span>
        }
        confirmLabel="Permanently delete"
        confirmAction={() => deleteAccount(account)}
        cancelLabel="Cancel"
        confirmDisabled={loading}
        cancelAction={() => setConfirmDeleteOpen(false)}
      />
      <CustomCardHeader
        title={`Connected ${integration_spec.title} Account`}
        type="header"
        className={classes.header}
        classes={{
          subheader: classes.subheader,
          action: classes.actions,
        }}
        action={
          <>
            <IconButton className={classes.closeBtn} onClick={onClose}>
              <CloseIcon className={classes.closeBtnIcon} />
            </IconButton>
          </>
        }
      />
      {syncInProgress && (
        <Box>
          <WarningAlert sync title={"Sync in Progress"} />
        </Box>
      )}
      <div className={classes.container}>
        <div className={classes.dialogContent}>
          <div className={classes.section}>
            <div className={classes.row}>
              <div className={classes.column}>
                <Box mb={2}>
                  <Typography variant="h1">Details</Typography>
                </Box>
                <HTMLField
                  inline
                  content={integration_spec["details"]}
                  multiline
                />
              </div>
              <div className={classes.ensoIconContainer}>
                <Typography variant="subtitle1" style={{fontWeight: 400}}>
                  {"Provided by"}
                </Typography>
                {
                  <img
                    className={classes.imgIcon}
                    src={integration_spec.icon}
                  />
                }
              </div>
            </div>
          </div>
          {!!integration_spec["Includes"] && (
            <div className={classes.section}>
              <Box mb={2}>
                <Typography variant="h1">Includes</Typography>
              </Box>
              <div className={classes.includedServicesRow}>
                {integration_spec["Includes"]?.map((s) => (
                  <Typography
                    key={s.header}
                    variant="subtitle2"
                    component="div"
                    className={classes.includedService}
                  >
                    {s.header}
                    {!!s.subheader && (
                      <Typography style={{maxWidth: 280}}>
                        {s.subheader}
                      </Typography>
                    )}
                  </Typography>
                ))}
              </div>
            </div>
          )}
          {getAccountActions()}
          {account.provider === "stripe" && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.section}>
                <Box mb={2}>
                  <Typography variant="h1">Stripe Account</Typography>
                </Box>
                {account.connection_type === "all" ||
                account.sort_id === "py#ALL" ||
                account.sort_id === "py#all" ? (
                  <Typography style={{paddingBottom: "10px"}}>
                    {t("default-stripe-account-info")}
                  </Typography>
                ) : (
                  <>
                    <Typography style={{paddingBottom: "1px"}}>
                      {t("stripe-account-used-listings").replace(
                        "{count}",
                        selectedGroups.length,
                      )}
                    </Typography>
                    <div
                      className={classes.chipRow}
                      style={{gap: "5px", marginBottom: 14}}
                    >
                      {selectedGroups
                        .filter((g) => !!groups[g.group_id])
                        .slice(0, 5)
                        .map((group, index) => (
                          <Chip
                            key={index}
                            size="small"
                            label={groups[group.group_id]?.name || "ERROR"}
                          />
                        ))}
                      {selectedGroups.length > 5 && (
                        <Chip size="small" label="..." />
                      )}
                    </div>
                    <div className={classes.stripeButtonContainer}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => setScopedGroupsModalOpen(true)}
                        style={{padding: "5px 10px"}}
                      >
                        {t("stripe-edit-listing-groups-button")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <Divider className={classes.divider} />
          {account?.provider !== "whatsapp" && (
            <div className={classes.section}>
              <Box mb={2}>
                <Typography variant="h1">Delete Account</Typography>
              </Box>
              <Typography style={{paddingBottom: "10px"}}>
                Permanently delete your account and unlink any connected
                resources.
              </Typography>
              <Chip
                onClick={() => setConfirmDeleteOpen(true)}
                style={{
                  backgroundColor: "darkred",
                  color: "white",
                  marginTop: 5,
                }}
                clickable={true}
                avatar={<DeleteIcon style={{color: "white"}} />}
                size="small"
                label={
                  <Box sx={{marginTop: 0}}>Permanently delete account</Box>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectedAccountPanel;
