import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {newUuid} from "utilities/helperFunctions";
import {createView, editView} from "redux/actions/accountsActions";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";
// Components
import ConfirmModal from "connectui/component/modal/ConfirmModal";
import FlexContainer from "connectui/component/layout/FlexContainer";
import ButtonText from "connectui/component/input/button/ButtonText";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import ErrorButtonBase from "connectui/component/input/button/base/ErrorButtonBase";
import LightButtonBase from "connectui/component/input/button/base/LightButtonBase";
import RoundIcon from "connectui/component/display/RoundIcon";
import Text from "connectui/component/display/Text";
import FilterSetDetailsStep from "./FilterSetDetailsStep";
import Container from "connectui/component/layout/Container";
import Divider from "connectui/component/display/Divider";
import FilterList from "../filterlist/FilterList";
import SvgIcon from "connectui/component/display/SvgIcon";
import SelectedFiltersOverview from "../../filterlist/SelectedFiltersOverview";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
    actionsRow: {
      width: "100%",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(0, 4, 5),
    },
    backArrow: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    },
  });

const FilterSetDetailsPanel = ({view, onClose = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isNew = !view.id;
  const [content, setContent] = useState({...view});
  const [step, setStep] = useState(isNew && !!view.queries.length ? 1 : 0);
  const [validStep, setValidStep] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    type: "",
  });

  useEffect(() => {
    const emptyContent = !content.name || !content.color || !content.icon;
    const emptyFilters = !content.queries.length;
    setValidStep(step === 0 ? !emptyFilters : !emptyContent);
  }, [step, content]);

  useEffect(() => {
    if (showErrors && validStep) {
      setShowErrors(false);
    }
  }, [showErrors, validStep]);

  const onSave = () => {
    const newView = isNew ? {...content, id: newUuid()} : content;
    const saveAction = isNew ? createView : editView;

    dispatch(
      saveAction({
        view: newView,
        onSuccess: () => {
          window.history.pushState(
            {},
            "",
            `${window.location.pathname}?view_id=${newView.id}`,
          );
        },
      }),
    );

    onClose(true);
  };

  return (
    <>
      <ConfirmModal
        isOpen={confirmModal.open}
        title={confirmModal.type === "save" ? "Save View?" : "Cancel Saving?"}
        description={
          confirmModal.type === "save"
            ? "Are you sure you want to save this view? It will be added to your saved views list."
            : "Are you sure you want to cancel? Your changes will not be saved."
        }
        actions={
          <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
            <ButtonText
              buttonBase={OutlinedButtonBase}
              weight="semibold"
              color="gray700"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Cancel" : "Go Back"}
              onClick={() => setConfirmModal({open: false, type: ""})}
            />
            <ButtonText
              buttonBase={
                confirmModal.type === "save" ? undefined : ErrorButtonBase
              }
              weight="semibold"
              color="white"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Save" : "Discard Changes"}
              onClick={confirmModal.type === "save" ? onSave : onClose}
            />
          </FlexContainer>
        }
        icon={
          <RoundIcon
            iconComponent={
              confirmModal.type === "save" ? "check-circle" : "alert-triangle"
            }
            baseColor={confirmModal.type === "save" ? "success" : "warning"}
          />
        }
      />
      <FlexContainer
        className={classes.container}
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        <FlexContainer
          className={classes.header}
          gap={theme.spacing(3)}
          alignItems="center"
        >
          {step > 0 && (
            <SvgIcon
              component={ICONS["arrow-left"]}
              className={classes.backArrow}
              onClick={() => setStep(step - 1)}
            />
          )}
          <Text variant="displayXS" weight="semibold">
            {t(
              isNew
                ? "filter-set-details-panel-create-header"
                : "filter-set-details-panel-header",
            )}
          </Text>
        </FlexContainer>
        {step === 0 && (
          <Text
            variant="textSM"
            color={showErrors && !validStep ? "error500" : "gray600"}
            sx={{padding: theme.spacing(0, 4), marginTop: theme.spacing(-3)}}
          >
            {t("filters-step-description")}
          </Text>
        )}

        {
          {
            0: (
              <FilterList
                filters={content.queries}
                setFilters={(newFilters) =>
                  setContent((prev) => ({...prev, queries: newFilters}))
                }
              />
            ),
            1: (
              <FilterSetDetailsStep
                content={content}
                showErrors={showErrors}
                setContent={(field, value) =>
                  setContent((prev) => ({...prev, [field]: value}))
                }
              />
            ),
          }[step]
        }

        {step === 0 && (
          <SelectedFiltersOverview
            filters={content.queries}
            setFilters={(newFilters) =>
              setContent((prev) => ({...prev, queries: newFilters}))
            }
          />
        )}

        <Container className={classes.actionsRow}>
          <Divider sx={{marginTop: "0px"}} />
          <FlexContainer gap={theme.spacing(3)}>
            <ButtonText
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="white"
              label={step === 0 ? "Continue" : "Save"}
              onClick={() => {
                if (validStep) {
                  setShowErrors(false);
                  step === 0
                    ? setStep(1)
                    : setConfirmModal({open: true, type: "save"});
                } else {
                  setShowErrors(true);
                }
              }}
            />
            <ButtonText
              buttonBase={LightButtonBase}
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="primary"
              label="Close"
              onClick={() => setConfirmModal({open: true, type: "cancel"})}
            />
          </FlexContainer>
        </Container>
      </FlexContainer>
    </>
  );
};

export default FilterSetDetailsPanel;
