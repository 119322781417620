import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import FilledTextField from "../../../../core/inputs/FilledTextField";
import SelectChipContainer from "connectui/component/input/SelectChipContainer";

const renderInput = ({fieldData, handleValueChange}) => {
  return (
    <div style={{width: "100%", height: "100%"}}>
      <FilledTextField
        fullWidth
        label="Value"
        type="number"
        inputProps={{min: 0, max: 100000}}
        value={fieldData.val}
        onChange={(e) => handleValueChange(fieldData.type, e.target.value)}
      />
    </div>
  );
};

const renderFilter = ({fieldData, handleValueChange}) => {
  const options = {
    "Above 0": {path: fieldData.field, operator: ">", value: 0},
    1: {path: fieldData.field, operator: "==", value: 1},
    2: {path: fieldData.field, operator: "==", value: 2},
    3: {path: fieldData.field, operator: "==", value: 3},
    4: {path: fieldData.field, operator: "==", value: 4},
    5: {path: fieldData.field, operator: "==", value: 5},
    "Above 5": {path: fieldData.field, operator: ">", value: 5},
  };

  const selected = fieldData.val.map((v, i) =>
    fieldData.operators[i] === ">" ? `Above ${v}` : `${v}`,
  );

  return (
    <SelectChipContainer
      options={Object.keys(options)}
      selectedOptions={selected}
      multiSelect={false}
      onChange={(newSelection) =>
        handleValueChange(
          "custom",
          newSelection.map((opt) => options[opt]),
        )
      }
    />
  );
};

const InquiryField = ({fieldData, handleValueChange, options, ...props}) => {
  const getOperators = () => [
    "is",
    "is_not",
    "is_greater_than",
    "is_less_than",
    "exists",
    "not_exists",
  ];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={() => renderInput({fieldData, handleValueChange})}
      renderFilter={() => renderFilter({fieldData, handleValueChange})}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

InquiryField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default InquiryField;
