import React, {useState} from "react";
import PropTypes from "prop-types";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import Text from "../../../../connectui/component/display/Text";
import Container from "../../../../connectui/component/layout/Container";
import {useTheme} from "../../../theme/ThemeProvider";

const DropdownSelect = ({
  label = "Select an option",
  customLabelComponent,
  children,
  placeholder,
  value,
  onChange,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleItemClick = (itemValue) => {
    onChange(itemValue);
    setIsOpen(false);
  };

  return (
    <FlexContainer
      flexDirection="column"
      gap={theme.spacing(1)}
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      <Text variant="textSM" color="textSecondary" weight="medium">
        {label}
      </Text>

      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(2),
          border: `1px solid ${theme.colors.border}`,
          borderRadius: theme.radius.md,
          backgroundColor: theme.colors.background,
          cursor: "pointer",
        }}
        onClick={toggleDropdown}
      >
        {customLabelComponent ? (
          customLabelComponent(value)
        ) : (
          <FlexContainer alignItems="center" gap={theme.spacing(2)}>
            {value ? (
              <>
                <Text variant="textMD" weight="semibold">
                  {value}
                </Text>
              </>
            ) : (
              <Text variant="textMD" color="textSecondary">
                {placeholder || "Select an option"}
              </Text>
            )}
          </FlexContainer>
        )}
        {/*<ButtonIc icon={<ArrowDropDown />} />*/}
      </Container>

      {isOpen && (
        <Container
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            marginTop: theme.spacing(1),
            backgroundColor: theme.colors.background,
            border: `1px solid ${theme.colors.border}`,
            borderRadius: theme.radius.md,
            zIndex: theme.zIndex.dropdown,
            overflow: "hidden",
            boxShadow: theme.shadows[1],
          }}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onClick: () => handleItemClick(child.props.value),
            }),
          )}
        </Container>
      )}
    </FlexContainer>
  );
};

DropdownSelect.propTypes = {
  label: PropTypes.string,
  customLabelComponent: PropTypes.func,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DropdownSelect;
