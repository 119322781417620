import React from "react";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import PropTypes from "prop-types";
import ModalBase from "./ModalBase";

const useStyles = (theme) =>
  buildStyles({
    modalContent: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      overflowY: "auto",
      width: 370,
      maxWidth: "90vw",
      borderRight: `1px solid ${theme.colors.border}`,
      backgroundColor: theme.colors.gray50,
    },
  });

/**
 * Drawer is a component that displays a sliding side panel
 * It displays the panel on the left side by default, but supports left and right position via the "position" prop.
 *
 * @param {Object} props - The props for the Drawer component.
 * @param {boolean} props.isOpen - Whether the drawer is open or not.
 * @param {Function} props.onClose - The function to call when the drawer is closed.
 * @param {"left"|"right"} [props.position="left"] - Defines the position of the drawer.
 * @param {React.ReactNode} props.children - The content to render inside the drawer.
 * @param {Object} props.rest - Any other props to be passed to the drawer content container.
 *
 * @returns {JSX.Element} The rendered Drawer component.
 */
const Drawer = ({isOpen, onClose, position = "left", children, ...props}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      className={classes.modalContent}
      wrapperProps={{
        sx: {justifyContent: position === "left" ? "flex-start" : "flex-end"},
      }}
      {...props}
    >
      {children}
    </ModalBase>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.node,
};

export default Drawer;
