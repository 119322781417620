import React from "react";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Container from "../../../../connectui/component/layout/Container";
import Text from "../../../../connectui/component/display/Text";
import GridComponent from "../../../../connectui/component/layout/GridComponent";
import CardBase from "../../../../connectui/component/display/card/CardBase";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import {ReactComponent as CalendarIcon} from "connectui/icons/calendar.svg";
import ButtonText from "../../../../connectui/component/input/button/ButtonText";
import LightButtonBase from "../../../../connectui/component/input/button/base/LightButtonBase";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";

const useStyles = (theme) =>
  buildStyles({
    gridArea: {
      padding: theme.spacing(10),
    },
    customIcon: {
      width: "20px",
      height: "20px",
    },
  });

export default function ConnectAccountPanel({setStageId}) {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const renderCards = (count) =>
    Array.from({length: count}).map((_, index) => (
      <CardBase
        key={index}
        sx={{width: "500px", height: "220px"}}
        icon={
          <SvgIcon component={CalendarIcon} className={classes.customIcon} />
        }
        title={<Text variant={"textLG"}>Phone</Text>}
        description={
          <Text variant={"textSM"}>
            Connect your phone account with us. This will allow you to send and
            receive SMS messages.
          </Text>
        }
        actionRow={
          <ButtonText
            sx={{flex: 1}}
            typographyVariant="textSM"
            weight="semibold"
            color="primary"
            onClick={() => setStageId(1)}
            buttonBase={LightButtonBase}
            label="Connect"
          />
        }
      />
    ));

  return (
    <Container sx={{paddingTop: theme.spacing(7)}}>
      <Text variant="displayXS" weight="semibold">
        Connect Accounts
      </Text>
      <Container className={classes.gridArea}>
        <FlexContainer gap={theme.spacing(5)} flexDirection={"column"}>
          <GridComponent
            customLabel={<Text variant={"textXL"}>Phone & Email</Text>}
          >
            {renderCards(5)}
          </GridComponent>
          <GridComponent
            customLabel={<Text variant={"textXL"}>Messaging Platforms</Text>}
          >
            {renderCards(5)}
          </GridComponent>
        </FlexContainer>
      </Container>
    </Container>
  );
}
