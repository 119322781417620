import React from "react";
import ButtonBase from "./base/ButtonBase";
import Text from "../../display/Text";
import PropTypes from "prop-types";

/**
 * ButtonIconText is a component that renders a button with an optional icon and label text.
 * It uses the provided ButtonBase component or defaults to ButtonBase, and allows customization of the icon, label, and typography.
 *
 * @param color - The color of the text.
 * @param {Object} props - The props for the ButtonIconText component.
 * @param {string} [props.typographyVariant="textMD"] - The typography variant to apply to the label text.
 * @param {string} [props.weight="medium"] - The font weight for the label text.
 * @param {string} [props.weight="textPrimary"] - The font color for the label text.
 * @param {React.ElementType} [props.buttonBase=ButtonBase] - The component to render for the button base (defaults to ButtonBase).
 * @param {React.ReactNode} [props.icon=null] - The icon to display inside the button.
 * @param {React.ReactNode} [props.label="Default Label"] - The label text to display inside the button.
 * @param {Object} [props.sx={}] - The style object that can be used to override default button styles.
 * @param {Object} props.rest - Any other props that should be passed to the ButtonBase component.
 *
 * @returns {JSX.Element} The rendered ButtonIconText component.
 */
const ButtonIconText = ({
  typographyVariant = "textMD",
  weight = "medium",
  color = "textPrimary",
  buttonBase: ButtonBaseComponent = ButtonBase,
  icon = null,
  label = "Default Label",
  sx = {},
  ...props
}) => {
  return (
    <ButtonBaseComponent sx={sx} {...props}>
      {icon && icon}
      <Text as="div" variant={typographyVariant} weight={weight} color={color}>
        {label}
      </Text>
    </ButtonBaseComponent>
  );
};

ButtonIconText.propTypes = {
  typographyVariant: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  buttonBase: PropTypes.any,
  icon: PropTypes.node,
  label: PropTypes.any,
  sx: PropTypes.any,
};

export default ButtonIconText;
