import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "../../../../theme/ThemeProvider";
import DropDownBase from "./DropDownBase";

/**
 * ChipDropDownBase is a reusable chip-styled dropdown component.
 * It extends DropDownBase by applying custom styles via the `sx` prop.
 *
 * @param {Object} props - Props for the ChipDropDownBase component.
 * @param {React.ReactNode} props.label - The chip-styled clickable label for the dropdown.
 * @param {React.ReactNode} props.children - The dropdown content (entries).
 * @param {boolean} props.isOpen - Whether the dropdown content is visible.
 * @param {Function} props.onClick - Click handler for toggling dropdown.
 *
 * @returns {JSX.Element} The rendered ChipDropDownBase component.
 */
const ChipDropDownBase = ({label, children, isOpen, onClick, ...props}) => {
  const {theme} = useTheme();

  const chipStyles = {
    label: {
      maxHeight: theme.spacing(7),
      border: "none",
      backgroundColor: theme.colors.gray100,
    },
    content: {
      border: "none",
      backgroundColor: theme.colors.gray100,
    },
  };

  return (
    <DropDownBase
      label={label}
      children={children}
      isOpen={isOpen}
      onClick={onClick}
      sx={chipStyles}
      {...props}
    />
  );
};

ChipDropDownBase.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChipDropDownBase;
