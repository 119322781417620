import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import DropDownBase from "./base/DropDownBase";
import FlexContainer from "../../layout/FlexContainer";
import Text from "../../display/Text";
import SvgIcon from "../../display/SvgIcon";
import {ReactComponent as ArrowUpIcon} from "../../../icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../../icons/chevron-down.svg";
import {buildStyles} from "../../buildStyles";
import {useTheme} from "../../../theme/ThemeProvider";
import Container from "../../layout/Container";

const useStyles = (theme) =>
  buildStyles({
    dropdownContainer: {
      position: "relative",
      display: "inline-block",
    },
    dropdownItem: {
      borderBottom: `1px solid ${theme.colors.divider}`,
      cursor: "pointer",
      "&:first-child": {
        borderTop: `1px solid ${theme.colors.divider}`,
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
    labelContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    arrowIcon: {
      width: "16px",
      height: "16px",
      color: theme.colors.gray400,
    },
  });

/**
 * DropDownSelect with expandable dropdown using DropDownBase for styling.
 *
 * @param DropDownBaseComponent
 * @param {Object} props - Props for the DropDownSelect component.
 * @param {React.ReactNode} props.label - The label/head of the dropdown.
 * @param {React.ReactNode} props.children - The content (entries) for the dropdown body.
 * @param {Function} [props.onChange] - Callback for when an item is selected (value is passed to the callback).
 * @param {React.ElementType} [props.dropDownBase=DropDownBase] - Custom dropdown base component.
 *
 * @returns {JSX.Element} The rendered DropDownSelect component.
 */
const DropDownSelect = ({
  label,
  children,
  dropDownBase: DropDownBaseComponent = DropDownBase,
  onChange = () => {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleClose = () => setIsOpen(false);

  const handleSelect = (value, label) => {
    setSelectedLabel(label);
    onChange(value);
    handleClose();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const defaultLabel = (
    <Text variant="textMD" color="textPrimary" weight="semiBold">
      {selectedLabel || label || "Select an option"}
    </Text>
  );

  return (
    <Container ref={dropdownRef} className={classes.dropdownContainer}>
      <DropDownBaseComponent
        label={
          <Container className={classes.labelContainer}>
            {defaultLabel}
            <SvgIcon
              component={isOpen ? ArrowUpIcon : ArrowDownIcon}
              className={classes.arrowIcon}
            />
          </Container>
        }
        isOpen={isOpen}
        onClick={handleToggle}
        {...props}
      >
        {React.Children.map(children, (child, index) => {
          const {value, label: childLabel} = child.props;

          return (
            <FlexContainer
              key={index}
              className={classes.dropdownItem}
              onClick={() => handleSelect(value, childLabel)}
            >
              {child}
            </FlexContainer>
          );
        })}
      </DropDownBaseComponent>
    </Container>
  );
};

DropDownSelect.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
  dropDownBase: PropTypes.elementType,
  onChange: PropTypes.func,
};

export default DropDownSelect;
