import React, {useState, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import {useTheme} from "connectui/theme/ThemeProvider";
import {ReactComponent as ArrowLeftIcon} from "connectui/icons/chevron-left.svg";
import {ReactComponent as ArrowRightIcon} from "connectui/icons/chevron-right.svg";
import SvgIcon from "./SvgIcon";
import Text from "./Text";
import Container from "../layout/Container";
import FlexContainer from "../layout/FlexContainer";

const useStyles = (theme) => ({
  container: {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows.xl,
    borderRadius: `${theme.radius.lg}px`,
    marginTop: theme.spacing(2),
    zIndex: theme.zIndex.dropdown,
    padding: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 3),
  },
  arrowIcon: {
    width: 20,
    height: 20,
    padding: 3,
    justifyContent: "center",
    cursor: "pointer",
    color: theme.colors.gray,
  },
  calendarRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  weekdayCell: {
    textAlign: "center",
    width: 38,
    height: 42,
    alignContent: "center",
  },
  dayInnerBase: {
    flex: 1,
    height: 38,
    cursor: "pointer",
    userSelect: "none",
    fontWeight: 400,
    fontSize: 14,
    borderRadius: "20px",
    color: theme.colors.gray700,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color border-radius 0.1s ease",
  },
  dayOutterBase: {
    width: 38,
    height: 42,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(1),
  },
  dayDisabled: {
    color: theme.colors.gray700,
    fontWeight: 400,
  },
  dayOutterDisabled: {opacity: 0.5},
  dayToday: {
    backgroundColor: theme.colors.gray100,
    color: theme.colors.gray700,
    fontWeight: 500,
    borderRadius: "50%",
  },
  daySelected: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontWeight: 500,
    borderRadius: "50%",
  },
  dayInRange: {
    backgroundColor: theme.colors.primary50,
    color: theme.colors.primary600,
  },
  square: {borderRadius: 0},
  firstDay: {borderRadius: "20px 0 0 20px"},
  lastDay: {borderRadius: "0 20px 20px 0"},
  selectingEndHover: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontWeight: 500,
    borderRadius: "50%",
  },
  selectedDateOutter: {
    paddingBottom: 0,
    height: 38,
    backgroundColor: theme.colors.primary50,
  },
});

// Helpers
function isSameDay(date1, date2) {
  if (!date1 || !date2) return false;
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function isWithinRange(dayTS, startDayTS, endDayTS) {
  if (!startDayTS || !endDayTS) return false;
  return dayTS >= startDayTS && dayTS <= endDayTS;
}

function generateCalendarDays(year, month) {
  const daysArray = [];
  // Current month variables
  const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
  const startDayIndex = firstDayOfMonth.getDay();
  // Prev month variables
  const daysFromPrevMonth = startDayIndex;
  const prevMonthLastDate = new Date(year, month, 0).getDate();
  // Next month variables
  const daysFromNextMonth = 6 - lastDayOfMonth;

  // Prev month days
  for (let i = 0; i < daysFromPrevMonth; i++) {
    const dayNum = prevMonthLastDate - (daysFromPrevMonth - 1) + i;
    daysArray.push({
      date: new Date(year, month - 1, dayNum),
      inCurrentMonth: false,
    });
  }
  // Current month days
  for (let d = 1; d <= totalDaysInMonth; d++) {
    daysArray.push({
      date: new Date(year, month, d),
      inCurrentMonth: true,
    });
  }
  // Next month days
  if (lastDayOfMonth !== 6) {
    for (let i = 1; i <= daysFromNextMonth; i++) {
      daysArray.push({
        date: new Date(year, month + 1, i),
        inCurrentMonth: false,
      });
    }
  }

  return daysArray;
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/**
 * Calendar is the component that renders a calendar with month view.
 * It allows navigating between months and selecting a date or range of dates.
 *
 * @param {Object} props - The props for the Calendar component.
 * @param {Date} [props.selectedDate] - The selected date (single-date).
 * @param {Array<Date>} [props.selectedRange] - The start/end date range.
 * @param {Function} [props.onDateClick] - The function to call when a date is selected.
 *
 * @returns {JSX.Element} The rendered Calendar component.
 */
const Calendar = ({selectedDate, selectedRange, onDateClick = () => {}}) => {
  const {theme} = useTheme();
  const styles = useStyles(theme);

  const today = new Date();
  const isRangeMode = typeof selectedRange !== "undefined";
  const [displayedYear, setDisplayedYear] = useState(today.getFullYear());
  const [displayedMonth, setDisplayedMonth] = useState(today.getMonth());
  const [hoveredDay, setHoveredDay] = useState(null);
  const currentMonthDate = new Date(displayedYear, displayedMonth, 1);

  const calendarDays = useMemo(() => {
    return generateCalendarDays(displayedYear, displayedMonth);
  }, [displayedYear, displayedMonth]);

  const goToPreviousMonth = useCallback(() => {
    let newMonth = displayedMonth - 1;
    let newYear = displayedYear;
    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }
    setDisplayedMonth(newMonth);
    setDisplayedYear(newYear);
    setHoveredDay(null);
  }, [displayedYear, displayedMonth]);

  const goToNextMonth = useCallback(() => {
    let newMonth = displayedMonth + 1;
    let newYear = displayedYear;
    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }
    setDisplayedMonth(newMonth);
    setDisplayedYear(newYear);
    setHoveredDay(null);
  }, [displayedYear, displayedMonth]);

  const handleDayClick = useCallback(
    (dayTS) => {
      onDateClick(new Date(dayTS));
      setHoveredDay(null);
    },
    [onDateClick],
  );

  const isBeforeInitialDate = useCallback(
    (dayTS) => {
      if (!isRangeMode) return false;
      const [startDate, endDate] = selectedRange || [];
      if (selectedRange?.length > 0 && startDate != null && !endDate) {
        if (dayTS < startDate) return true;
      }
      return false;
    },
    [isRangeMode, selectedRange],
  );

  function renderDayCell(dayObj, index) {
    const dayDate = dayObj.date;
    const dayTS = dayDate.getTime();
    const isCurrentMonth = dayObj.inCurrentMonth;
    const isToday = isSameDay(dayTS, today.getTime());
    const isSelectedDate =
      selectedDate && isSameDay(dayTS, selectedDate.getTime());
    const isDisabled = !isCurrentMonth;
    const isHovered = hoveredDay && dayTS === hoveredDay;

    // Base styles
    let dayOutterStyle = {...styles.dayOutterBase};
    let dayInnerStyle = {...styles.dayInnerBase};
    // Previous/Next month days disabled
    if (isDisabled) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayDisabled};
      dayOutterStyle = {...dayOutterStyle, ...styles.dayOutterDisabled};
    }
    // Today
    if (isToday) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayToday};
    }
    // Selected date
    if (isSelectedDate) {
      dayInnerStyle = {...dayInnerStyle, ...styles.daySelected};
    }
    // Hover
    if (isHovered && !isSelectedDate && isCurrentMonth) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayInRange};
    }

    // Handlers
    function handleMouseEnter() {
      if (!isDisabled) setHoveredDay(dayTS);
    }
    function handleMouseLeave() {
      if (hoveredDay === dayTS) setHoveredDay(null);
    }
    function handleClick() {
      if (!isDisabled) handleDayClick(dayTS);
    }

    return (
      <FlexContainer
        key={index}
        sx={dayOutterStyle}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FlexContainer sx={dayInnerStyle}>{dayDate.getDate()}</FlexContainer>
      </FlexContainer>
    );
  }

  function renderRangeDayCell(dayObj, index) {
    const dayDate = dayObj.date;
    const dayTS = dayDate.getTime();
    const isCurrentMonth = dayObj.inCurrentMonth;
    const isToday = isSameDay(dayTS, today.getTime());
    const [startDayTS, endDayTS] = selectedRange || [];
    const isSelectedStart = startDayTS && isSameDay(dayTS, startDayTS);
    const isSelectedEnd = endDayTS && isSameDay(dayTS, endDayTS);
    const isSameSelectedDate = isSelectedStart && isSelectedEnd;
    const selectingEndDate = !!startDayTS && !endDayTS;
    let isInSelectedRange = false;

    if (startDayTS && endDayTS) {
      if (
        isWithinRange(dayTS, startDayTS, endDayTS) &&
        !isSelectedStart &&
        !isSelectedEnd
      ) {
        isInSelectedRange = true;
      }
    } else if (startDayTS && hoveredDay) {
      // Highlighting days within the range when selecting end date
      if (hoveredDay > startDayTS) {
        if (dayTS >= startDayTS && dayTS <= hoveredDay && !isSelectedStart) {
          isInSelectedRange = true;
        }
      }
    }

    const isHovered = hoveredDay && dayTS === hoveredDay;
    const isBetweenRangeHover = isInSelectedRange || isHovered;
    const isDisabled = !isCurrentMonth || isBeforeInitialDate(dayTS);

    // -------- DAY STYLES --------
    let dayOutterStyle = {...styles.dayOutterBase};
    let dayInnerStyle = {...styles.dayInnerBase};

    // Disabled days (days of previous/next month && days before startDate)
    if (isDisabled) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayDisabled};
      dayOutterStyle = {...dayOutterStyle, ...styles.dayOutterDisabled};
    }
    // Today
    if (isToday && isCurrentMonth && !isBetweenRangeHover) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayToday};
    }
    // Within range or hover
    if (isBetweenRangeHover && !isSelectedStart && !isSelectedEnd) {
      dayInnerStyle = {...dayInnerStyle, ...styles.dayInRange};
    }
    // Selected start/end date
    if (isSelectedStart || isSelectedEnd) {
      dayInnerStyle = {...dayInnerStyle, ...styles.daySelected};
      // Outter style
      if (!isSameSelectedDate && (!selectingEndDate || isHovered === false)) {
        dayOutterStyle = {
          ...dayOutterStyle,
          ...styles.selectedDateOutter,
          ...(isSelectedStart ? styles.firstDay : styles.lastDay),
        };
      }
    }
    // Days within range => square style
    if (isInSelectedRange && !isSelectedStart && !isSelectedEnd) {
      dayInnerStyle = {...dayInnerStyle, ...styles.square};
    }
    // First day of week
    if (index % 7 === 0 && isInSelectedRange) {
      dayInnerStyle = {...dayInnerStyle, ...styles.firstDay};
    }
    // Last day of week
    if (index % 7 === 6 && isInSelectedRange) {
      dayInnerStyle = {...dayInnerStyle, ...styles.lastDay};
    }
    // End date hover
    if (selectingEndDate && isHovered && isCurrentMonth) {
      dayInnerStyle = {...dayInnerStyle, ...styles.selectingEndHover};
      if (!isSelectedStart) {
        dayOutterStyle = {
          ...dayOutterStyle,
          ...styles.selectedDateOutter,
          ...styles.lastDay,
        };
      }
    }

    // Handlers
    function handleMouseEnter() {
      if (!isDisabled) setHoveredDay(dayTS);
    }
    function handleMouseLeave() {
      if (hoveredDay === dayTS) setHoveredDay(null);
    }
    function handleClick() {
      if (!isDisabled) handleDayClick(dayTS);
    }

    return (
      <FlexContainer
        key={index}
        sx={dayOutterStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <FlexContainer sx={dayInnerStyle}>{dayDate.getDate()}</FlexContainer>
      </FlexContainer>
    );
  }

  const calendarRows = [];
  for (let i = 0; i < 6; i++) {
    const rowDays = calendarDays.slice(i * 7, i * 7 + 7);
    calendarRows.push(
      <Container key={i} sx={styles.calendarRow}>
        {rowDays.map((dayObj, idx) =>
          isRangeMode
            ? renderRangeDayCell(dayObj, i * 7 + idx)
            : renderDayCell(dayObj, i * 7 + idx),
        )}
      </Container>,
    );
  }

  return (
    <Container sx={styles.container}>
      {/* Header */}
      <Container sx={styles.header}>
        <SvgIcon
          component={ArrowLeftIcon}
          sx={styles.arrowIcon}
          onClick={goToPreviousMonth}
        />
        <Text
          weight="medium"
          color="gray700"
          sx={{textTransform: "capitalize"}}
        >
          {`${currentMonthDate.toLocaleString("default", {month: "long"})} ${displayedYear}`}
        </Text>
        <SvgIcon
          component={ArrowRightIcon}
          sx={styles.arrowIcon}
          onClick={goToNextMonth}
        />
      </Container>

      {/* Week days */}
      <Container sx={styles.calendarRow}>
        {weekDays.map((day, index) => (
          <Text
            key={index}
            variant="textSM"
            weight="medium"
            color="gray700"
            sx={styles.weekdayCell}
          >
            {day}
          </Text>
        ))}
      </Container>

      {/* Calendar days */}
      {calendarRows}
    </Container>
  );
};

Calendar.propTypes = {
  onDateClick: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

export default Calendar;
