import React from "react";
import {useParams} from "react-router-dom";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import Container from "connectui/component/layout/Container";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import Text from "connectui/component/display/Text";
import FlexContainer from "connectui/component/layout/FlexContainer";
import {useFlags} from "launchdarkly-react-client-sdk";
import GuestSection from "./GuestSection";
import ListingSection from "./ListingSection";
import {useSelector} from "react-redux";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: theme.spacing(4),
    },
  });

const GuestDetailsPanel = ({}) => {
  const flag = useFlags();
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {guest_id} = useParams();

  return (
    <Container className={classes.container}>
      {!guest_id ? (
        <FlexContainer
          className={classes.container}
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="textLG" color="gray400">
            {"Select a guest to see guest details"}
          </Text>
        </FlexContainer>
      ) : flag.newInboxGuestDetailsPanel ? (
        <>
          <GuestSection guestId={guest_id} />
          <ListingSection guestId={guest_id} />
        </>
      ) : (
        <BookingInfoDrawer hideRedirectIcon />
      )}
    </Container>
  );
};

export default GuestDetailsPanel;
