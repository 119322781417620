import React from "react";
import {useTheme} from "../../../../theme/ThemeProvider";
import {buildStyles} from "../../../buildStyles";
import Container from "../../../layout/Container";
import PropTypes from "prop-types";

const EmptyButtonBase = ({
  as: Component = "button",
  sx = {},
  children,
  ...props
}) => {
  const {theme} = useTheme();

  const classes = buildStyles({
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      padding: theme.spacing(2, 3),
      border: "none",
      cursor: "pointer",
      ...sx,
    },
  });

  return (
    <Container as={Component} className={classes.btn} {...props}>
      {children}
    </Container>
  );
};

EmptyButtonBase.propTypes = {
  as: PropTypes.any,
  sx: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default EmptyButtonBase;
