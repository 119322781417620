import {useFlags} from "launchdarkly-react-client-sdk";
import React from "react";
import ConnectedAccounts from "./ConnectedAccounts";
import BaseAccountPanel from "../../ui/component/businesssettings/panel/BaseAccountPanel";

const ConnectedAccountsRoute = () => {
  const flags = useFlags();

  if (flags.newConnectedAccountsPage) {
    return <BaseAccountPanel />;
  }

  return <ConnectedAccounts />;
};

export default ConnectedAccountsRoute;
