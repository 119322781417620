import React, {useContext, useEffect, useState} from "react";
import useViewportWidth from "hooks/useViewportWidth";
import {InboxContext} from "routes/MessagesPage";
// Styles & icons
import {useTheme} from "../../connectui/theme/ThemeProvider";
import {buildStyles} from "../../connectui/component/buildStyles";
import {ReactComponent as ArrowLeftIcon} from "connectui/icons/chevron-left.svg";
import {ReactComponent as ArrowRightIcon} from "connectui/icons/chevron-right.svg";
// Components
import Container from "../../connectui/component/layout/Container";
import VerticalPanelLayout from "connectui/component/layout/VerticalPanelLayout";
import SvgIcon from "connectui/component/display/SvgIcon";
import ButtonIcon from "../../connectui/component/input/button/ButtonIcon";
// Panels
import FilterSetDetailsPanel from "ui/component/inbox/panel/filtersets/FilterSetDetailsPanel";
import FilterSetsPanel from "ui/component/inbox/panel/filtersets/FilterSetsPanel";
import MessageThreadsPanel from "ui/component/inbox/panel/threads/MessageThreadsPanel";
import ConversationsPanel from "ui/component/inbox/panel/conversations/ConversationsPanel";
import GuestDetailsPanel from "ui/component/inbox/panel/guestdetails/GuestDetailsPanel";
import FiltersPanel from "ui/component/inbox/panel/filterlist/FiltersPanel";

const PANEL_STYLES = [
  {minWidth: "270px", maxWidth: "300px"}, // filter_sets
  {minWidth: "280px", maxWidth: "345px"}, // threads
  {minWidth: "400px"}, // chat
  {minWidth: "370px", maxWidth: "400px"}, // guest_details
];

const useStyles = (theme) =>
  buildStyles({
    outerContainer: {
      backgroundColor: theme.colors.white,
      width: "100%",
      height: "100vh",
      filter: "drop-shadow(0px 1px 2px rgba(10, 13, 18, 0.05))",
      display: "flex",
      flexDirection: "row",
    },
    iconBtn: {
      boxShadow: theme.shadows.default,
      borderRadius: theme.spacing(2),
      border: `1px solid ${theme.colors.border}`,
      backgroundColor: theme.colors.white,
      cursor: "pointer",
      padding: "0px",
      "&:hover": {backgroundColor: theme.colors.hoverGray},
    },
    icon: {
      width: "24px",
      height: "24px",
      justifyContent: "center",
      padding: "5px",
    },
  });

const Inbox = () => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const width = useViewportWidth({useBreakpointLabels: true});
  const isLG = width === "lg" || width === "md";
  const {setAppliedFilters} = useContext(InboxContext);
  const [showFilterSets, setShowFilterSets] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedView, setSelectedView] = useState(null);
  const [hideGuestPanel, setHideGuestPanel] = useState(!isLG && showFilterSets);

  useEffect(() => {
    if (!isLG) {
      if (showFilterSets && !hideGuestPanel) {
        setHideGuestPanel(true);
      }
    } else if (hideGuestPanel) {
      setHideGuestPanel(false);
    }
  }, [width, showFilterSets, hideGuestPanel, isLG]);

  const toggleGuestDetailsPanelOpen = () => {
    setHideGuestPanel((prev) => !prev);
    if (!isLG) {
      setShowFilterSets((prev) => !prev);
    }
  };

  const toggleFilterSetsPanelOpen = () => {
    setShowFilterSets((prev) => !prev);
    if (!isLG) {
      setHideGuestPanel((prev) => !prev);
    }
  };

  const handleEditView = (filterSet) => {
    setSelectedView(filterSet);
    setShowFilterSets(false);
  };

  const handleCloseView = (keepViewsOpen) => {
    if (keepViewsOpen) {
      setShowFilterSets(true);
      if (!isLG) setHideGuestPanel(true);
    }
    setSelectedView(null);
  };

  const handleSaveView = (filters) => {
    const newView = {
      color: "",
      icon: "",
      description: "",
      name: "",
      queries: filters,
    };
    setAppliedFilters(filters);
    setFiltersOpen(false);
    setSelectedView(newView);
    setShowFilterSets(false);
  };

  const expandArrow = (
    <ButtonIcon
      icon={
        <SvgIcon
          component={showFilterSets ? ArrowLeftIcon : ArrowRightIcon}
          className={classes.icon}
        />
      }
      className={classes.iconBtn}
      onClick={toggleFilterSetsPanelOpen}
    />
  );

  return (
    <Container className={classes.outerContainer}>
      <VerticalPanelLayout
        panels={[
          showFilterSets && !selectedView ? (
            <FilterSetsPanel
              expandArrow={showFilterSets ? expandArrow : null}
              editView={handleEditView}
            />
          ) : null,
          !!selectedView ? (
            <FilterSetDetailsPanel
              view={selectedView}
              onClose={handleCloseView}
            />
          ) : filtersOpen ? (
            <FiltersPanel
              onClose={() => setFiltersOpen(false)}
              saveView={handleSaveView}
            />
          ) : (
            <MessageThreadsPanel
              openFilters={() => setFiltersOpen(true)}
              expandArrow={!showFilterSets ? expandArrow : null}
            />
          ),
          <ConversationsPanel
            panelOpen={!hideGuestPanel}
            togglePanelOpen={toggleGuestDetailsPanelOpen}
          />,
          !hideGuestPanel ? <GuestDetailsPanel /> : null,
        ]}
        panelStyles={PANEL_STYLES}
      />
    </Container>
  );
};

export default Inbox;
