import React, {useMemo} from "react";
import ListComponent from "../../layout/list/ListComponent";
import PropTypes from "prop-types";
import Container from "../../layout/Container";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import ContextMenuItem from "./ContextMenuItem";
import ModalPortal from "connectui/component/layout/ModalPortal";

const useStyles = (theme) =>
  buildStyles({
    contextMenu: {
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows.md,
      borderRadius: `${theme.radius.lg}px`,
      padding: theme.spacing(1, 0),
      border: `1px solid ${theme.colors.gray100}`,
    },
  });

/**
 * ContextMenu is a component that renders a context menu with a list of menu items.
 * It uses ModalPortal component and supports custom components for each menu item.
 *
 * @param {Object} props - The props for the ContextMenu component.
 * @param {Array} [props.menuItems=[]] - The list of menu items to display. Each item can be a custom component or a default ContextMenuItem.
 * @param {boolean} props.open - Controls the open state of the portal.
 * @param {Function} props.onClose - The function to call when the context menu is closed.
 * @param {React.ReactNode} [props.anchorEl=null] - The element that will serve as an anchor to position the portal.
 *
 * @returns {JSX.Element} The rendered ContextMenu component.
 */
const ContextMenu = ({menuItems = [], open, onClose, anchorEl, ...props}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const items = useMemo(() => menuItems.filter((item) => !!item), [menuItems]);

  return (
    <ModalPortal isOpen={open} onClose={onClose} anchorEl={anchorEl}>
      {({ref}) => (
        <Container ref={ref} className={classes.contextMenu} {...props}>
          <ListComponent gap="0px">
            {items.map((item, index) => {
              if (!item) {
                return null;
              }
              const {Component = null, ...itemProps} = item;
              return Component ? (
                <Component key={index} {...itemProps} />
              ) : (
                <ContextMenuItem key={index} {...itemProps} />
              );
            })}
          </ListComponent>
        </Container>
      )}
    </ModalPortal>
  );
};

ContextMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
};

export default ContextMenu;
