import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import FilledTextField from "../../../../core/inputs/FilledTextField";
import SelectChipContainer from "connectui/component/input/SelectChipContainer";
import _ from "lodash";

const renderInput = ({fieldData, handleValueChange}) => {
  return (
    <div style={{width: "100%", height: "100%"}}>
      <FilledTextField
        fullWidth
        label="Value"
        type="number"
        inputProps={{min: 0, max: 100000}}
        value={fieldData.val}
        onChange={(e) => handleValueChange(fieldData.type, e.target.value)}
      />
    </div>
  );
};

const renderFilter = ({fieldData, handleValueChange, options}) => {
  const {val, operators} = fieldData;
  const optionLabels = Object.keys(options).map((k) => options[k].label);
  const selected = [options[`${operators[0]}${val[0]}`].label];

  return (
    <SelectChipContainer
      options={optionLabels}
      selectedOptions={selected}
      multiSelect={false}
      onChange={(newSelection) =>
        handleValueChange("custom", options[newSelection])
      }
    />
  );
};

const TotalRevenueField = ({
  fieldData,
  handleValueChange,
  options,
  ...props
}) => {
  const filterOptions = {
    "==0": {
      label: "$0",
      filters: [{path: fieldData.field, operator: "==", value: 0}],
    },
    ">0": {
      label: "$1 - $500",
      filters: [
        {path: fieldData.field, operator: ">", value: 0},
        {path: fieldData.field, operator: "<=", value: 500},
      ],
    },
    ">500": {
      label: "$501 - $1,000",
      filters: [
        {path: fieldData.field, operator: ">", value: 500},
        {path: fieldData.field, operator: "<=", value: 1000},
      ],
    },
    ">1000": {
      label: "$1,001 - $3,000",
      filters: [
        {path: fieldData.field, operator: ">", value: 1000},
        {path: fieldData.field, operator: "<=", value: 3000},
      ],
    },
    ">3000": {
      label: "Above $3,000",
      filters: [{path: fieldData.field, operator: ">", value: 3000}],
    },
  };

  const getOperators = () => [
    "is",
    "is_not",
    "is_greater_than",
    "is_less_than",
    "exists",
    "not_exists",
  ];

  const getLabel = () => {
    if (props.isFilter) {
      return (
        filterOptions[`${fieldData?.operators?.[0]}${fieldData?.val?.[0]}`]
          ?.label ?? ""
      );
    } else return getDefaultFieldLabel(fieldData?.val);
  };

  return (
    <BaseFieldComponent
      renderInput={() => renderInput({fieldData, handleValueChange})}
      renderFilter={() =>
        renderFilter({fieldData, handleValueChange, options: filterOptions})
      }
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

TotalRevenueField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TotalRevenueField;
