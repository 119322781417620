import {useState, useEffect} from "react";

function useMediaQuery(query) {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const listener = (e) => {
      if (e.matches !== matches) {
        setMatches(e.matches);
      }
    };

    mediaQueryList.addEventListener("change", listener);
    return () => mediaQueryList.removeEventListener("change", listener);
  }, [query, matches]);

  return matches;
}

export default useMediaQuery;
