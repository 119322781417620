import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {InboxContext} from "routes/MessagesPage";
import {
  createView,
  deleteView,
  togglePublicView,
} from "redux/actions/accountsActions";
import {newUuid} from "utilities/helperFunctions";
import _ from "lodash";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import Text from "connectui/component/display/Text";
import ContextMenu from "connectui/component/input/context/ContextMenu";
import ContextMenuItem from "connectui/component/input/context/ContextMenuItem";
import FilterSetList from "../../filtersets/FilterSetList";
import ConfirmModal from "connectui/component/modal/ConfirmModal";
import ButtonText from "connectui/component/input/button/ButtonText";
import ErrorButtonBase from "connectui/component/input/button/base/ErrorButtonBase";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import RoundIcon from "connectui/component/display/RoundIcon";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
  });

const FilterSetsPanel = ({
  expandArrow = null,
  customHeader,
  editView = () => null,
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const viewClicked = useRef(null);
  const {setAppliedFilters} = useContext(InboxContext);
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");
  const [selected, setSelected] = useState(viewId);
  const [menuOptions, setMenuOptions] = useState({
    open: false,
    isOwnerUser: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  useEffect(() => {
    if (!selected || !user_profile?.enso_key) {
      return;
    }
    const selectedView =
      user_profile.public_views.find((v) => v.id === selected) ??
      user_profile.views.find((v) => v.id === selected);
    if (selectedView) {
      setAppliedFilters(selectedView.queries ?? []);
    } else {
      window.history.pushState({}, "", window.location.pathname);
      setSelected(null);
      setAppliedFilters([]);
    }
  }, [selected, user_profile]);

  useEffect(() => {
    if (!viewId && !!selected) {
      setSelected(null);
    } else if (!!viewId && !selected) {
      setSelected(viewId);
    }
  }, [viewId]);

  const handleCreateView = () => {
    const newView = {
      color: "",
      icon: "",
      description: "",
      name: "",
      queries: [],
    };
    editView(newView);
  };

  const handleFilterSetClick = (filterSet) => () => {
    const isSelected = selected === filterSet.id;
    setSelected(isSelected ? null : filterSet.id);
    !!isSelected && setAppliedFilters([]);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}${isSelected ? "" : `?view_id=${filterSet.id}`}`,
    );
  };

  const handleDelete = () => {
    setMenuOptions({open: false, isOwnerUser: false});
    setDeleteModalOpen(false);
    dispatch(
      deleteView({
        view: viewClicked.current,
        onSuccess: () => {
          if (viewId === viewClicked.current.id) {
            window.history.pushState({}, "", window.location.pathname);
          }
        },
      }),
    );
  };

  const handleDuplicate = () => {
    setMenuOptions({open: false, isOwnerUser: false});
    const duplicatedView = {
      ...viewClicked.current,
      id: newUuid(),
      name: `${viewClicked.current.name} (Duplicated)`,
    };

    dispatch(createView({view: duplicatedView}));
  };

  const togglePublic = () => {
    setMenuOptions({open: false, isOwnerUser: false});
    const isPrivate = !!user_profile.views.find(
      (v) => v.id === viewClicked.current.id,
    );
    const newView = {
      ...viewClicked.current,
      id: newUuid(),
    };

    dispatch(
      togglePublicView({
        view: newView,
        oldId: viewClicked.current.id,
        makePublic: isPrivate,
      }),
    );
  };

  const openMenu = (selectedView, isPublic) => (event) => {
    event.stopPropagation();
    viewClicked.current = {...selectedView, public: isPublic};
    setAnchorEl(event.currentTarget);
    setMenuOptions({
      open: true,
      isOwnerUser: user_profile.enso_user_id === selectedView.created_by_user,
    });
  };

  return (
    <>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={`Delete "${viewClicked.current?.name ?? ""}" view ?`}
        description={
          "Are you sure you want to delete this view? This action cannot be undone."
        }
        actions={
          <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
            <ButtonText
              buttonBase={OutlinedButtonBase}
              weight="semibold"
              color="gray700"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={"Cancel"}
              onClick={() => setDeleteModalOpen(false)}
            />
            <ButtonText
              buttonBase={ErrorButtonBase}
              weight="semibold"
              color="white"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={"Delete"}
              onClick={handleDelete}
            />
          </FlexContainer>
        }
        icon={<RoundIcon iconComponent="alert-triangle" baseColor="warning" />}
      />
      <FlexContainer
        className={classes.container}
        flexDirection="column"
        gap={theme.spacing(6)}
        onScroll={() => {
          setMenuOptions(false);
          setAnchorEl(null);
        }}
      >
        {menuOptions.open && (
          <ContextMenu
            open
            anchorEl={anchorEl}
            onClose={() => {
              setMenuOptions(false);
              setAnchorEl(null);
            }}
            menuItems={[
              menuOptions.isOwnerUser
                ? {
                    label: "Edit View",
                    icon: ICONS["settings-tune"],
                    iconProps: {color: theme.colors.gray500},
                    onClick: () => editView(viewClicked.current),
                  }
                : null,
              menuOptions.isOwnerUser
                ? {
                    label: !!viewClicked.current?.public
                      ? "Make Private "
                      : "Make Public",
                    icon: ICONS[
                      !!viewClicked.current?.public ? "eye-off" : "eye"
                    ],
                    iconProps: {color: theme.colors.gray500},
                    onClick: togglePublic,
                  }
                : null,
              {
                label: "Duplicate",
                icon: ICONS["copy"],
                iconProps: {color: theme.colors.gray500},
                onClick: handleDuplicate,
              },
              menuOptions.isOwnerUser
                ? {
                    Component: ({label}) => (
                      <ContextMenuItem
                        label={
                          <Text weight="medium" color="error500">
                            {label}
                          </Text>
                        }
                        icon={ICONS["trash"]}
                        iconProps={{color: theme.colors.error500}}
                        onClick={() => setDeleteModalOpen(true)}
                      />
                    ),
                    label: "Delete",
                  }
                : null,
            ]}
          />
        )}
        <FlexContainer
          className={classes.header}
          gap={theme.spacing(3)}
          alignItems="center"
        >
          {customHeader ?? (
            <>
              {expandArrow}
              <Text variant="displayXS" weight="semibold">
                {t("filter-sets-panel-header")}
              </Text>
            </>
          )}
        </FlexContainer>

        <FilterSetList
          selected={selected}
          openMenu={openMenu}
          createView={handleCreateView}
          onFilterClick={handleFilterSetClick}
        />
      </FlexContainer>
    </>
  );
};

export default FilterSetsPanel;
