import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Container from "./Container";
import {useTheme} from "connectui/theme/ThemeProvider";

/**
 * ModalPortal allows you to render a child component into a different part of the DOM.
 * Intended to be used for modals and menus that need to be positioned
 * without the restrictions of the parent hierarchy.
 *
 * @param {Object} props - The props for the ModalPortal component.
 * @param {boolean} props.isOpen - Controls the open state of the portal.
 * @param {Function} props.onClose - The function to call when the modal is closed.
 * @param {React.ReactNode} [props.anchorEl=null] - The element that will serve as an anchor to position the portal.
 * @param {React.ReactNode|Function} props.children - The content of the portal.
 *
 * @returns {JSX.Element} The rendered ModalPortal component.
 */
const ModalPortal = ({
  isOpen,
  onClose,
  anchorEl = null,
  children,
  ...props
}) => {
  const modalNode = document.getElementById("modal-root");
  const wrapperRef = useRef(null);
  const {theme} = useTheme();
  const [position, setPosition] = useState({top: -9999, left: -9999});
  const [contentRef, setContentRef] = useState(null);

  useEffect(() => {
    if (!isOpen) return;
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const childrenNode = contentRef || wrapperRef.current;
    if (!isOpen || !anchorEl || !childrenNode) return;

    const rect = anchorEl.getBoundingClientRect();
    const contentSize = {
      width: childrenNode.offsetWidth,
      height: childrenNode.offsetHeight,
    };
    const canFitBelow = window.innerHeight - rect.bottom >= contentSize.height;
    const canFitRight = window.innerWidth - rect.right >= contentSize.width;
    const top = canFitBelow ? rect.bottom : rect.top - contentSize.height;
    const left = canFitRight ? rect.left : rect.right - contentSize.width;

    setPosition({top, left});
  }, [isOpen, anchorEl, contentRef]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Container
      ref={wrapperRef}
      onClick={(e) => e.stopPropagation()}
      sx={{
        position: "absolute",
        zIndex: theme.zIndex.modal,
        top: position.top,
        left: position.left,
      }}
      {...props}
    >
      {typeof children === "function"
        ? children({ref: setContentRef})
        : children}
    </Container>,
    modalNode,
  );
};

ModalPortal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default ModalPortal;
