import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {isSameDay, isValid} from "date-fns";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import {ReactComponent as CalendarIcon} from "connectui/icons/calendar.svg";
import Container from "../layout/Container";
import ModalPortal from "../layout/ModalPortal";
import ButtonIconText from "./button/ButtonIconText";
import OutlinedButtonBase from "./button/base/OutlinedButtonBase";
import SvgIcon from "../display/SvgIcon";
import Calendar from "../display/Calendar";

const useStyles = (theme) =>
  buildStyles({
    calendarIcon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(2),
    },
  });

/**
 * DateRangePicker is a component that renders a calendar widget allowing the user to select a date range.
 * It opens a calendar when clicked and allows navigating between months and selecting a start and end date.
 *
 * @param {Object} props - The props for the DateRangePicker component.
 * @param {Function} [props.onChange] - The function to call when the selected date range changes.
 * @param {boolean} [props.open=null] - If provided, it controls the open state of the calendar.
 * @param {function} [props.setOpen=null] - The function to call when the calendar open state changes.
 * @param {Date} [props.controlledDateRange] - If provided, it controls the selected date range.
 *
 * @returns {JSX.Element} The rendered DateRangePicker component.
 */
const DateRangePicker = ({
  onChange,
  open = null,
  setOpen = () => null,
  controlledDateRange,
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const prevRange = useRef(null);
  const [selectedDates, setSelectedDates] = useState(controlledDateRange ?? []);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const buttonWrapperRef = useRef(null);
  const [startDate, endDate] = selectedDates;

  useEffect(() => {
    if (controlledDateRange) {
      setSelectedDates(controlledDateRange);
    }
  }, [controlledDateRange]);

  useEffect(() => {
    if (open !== null) {
      setIsCalendarOpen(!!open);
    }
  }, [open]);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleDayClick = (date) => {
    // End date
    if (!endDate && !!startDate) {
      const newRange = [startDate, new Date(date.setHours(23, 59, 59, 999))];
      setSelectedDates(newRange);
      if (onChange) onChange(newRange);
      toggleOpen(false);
    }
    // Start date
    else {
      prevRange.current = [...selectedDates];
      setSelectedDates([new Date(date.setHours(0, 0, 0, 0)), null]);
    }
  };

  const toggleOpen = (isOpen) => {
    setIsCalendarOpen(isOpen);
    setOpen(isOpen);
  };

  const handleClose = () => {
    if (startDate && !endDate) {
      handleDayClick(new Date(startDate));
    } else {
      toggleOpen(false);
    }
  };

  function getFormattedEndDate() {
    return !isValid(endDate)
      ? " - Select end date"
      : isSameDay(startDate, endDate)
        ? ""
        : ` - ${formatDate(endDate)}`;
  }

  return (
    <>
      <Container ref={buttonWrapperRef}>
        <ButtonIconText
          buttonBase={OutlinedButtonBase}
          label={
            isValid(startDate)
              ? `${formatDate(startDate)}${getFormattedEndDate()}`
              : "Select dates"
          }
          weight="semibold"
          color={!!selectedDates.length ? "textPrimary" : "textSecondary"}
          typographyVariant="textSM"
          sx={
            isCalendarOpen
              ? {
                  boxShadow: `0px 1px 2px 0px rgba(10, 13, 18, 0.05), 0px 0px 0px 4px ${theme.colors.gray100}`,
                }
              : {}
          }
          onClick={() => toggleOpen(!isCalendarOpen)}
          icon={
            <SvgIcon
              component={CalendarIcon}
              className={classes.calendarIcon}
            />
          }
        />
      </Container>
      {isCalendarOpen && (
        <ModalPortal
          anchorEl={buttonWrapperRef.current}
          isOpen={isCalendarOpen}
          onClose={handleClose}
        >
          <Calendar
            selectedRange={selectedDates}
            onDateClick={handleDayClick}
          />
        </ModalPortal>
      )}
    </>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  controlledDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

export default DateRangePicker;
