import React from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import FieldComponentProvider from "../../field/FieldComponentProvider";

export default function FilterItemComponent({
  field,
  selectedFilters,
  setFilters,
  customFields,
  sx = {},
}) {
  return (
    <FlexContainer gap={"5px"} sx={{flexFlow: "wrap", ...sx}}>
      <FieldComponentProvider
        customFieldData={customFields}
        fieldInfo={{field: field}}
      >
        {({optionData, operatorType, componentMap, component}) =>
          component({
            type: "filter",
            handleValueChange: (type, newValue) => {
              let result = selectedFilters.filter((f) => f.path !== field);
              if (type === "custom") {
                result = result.concat(newValue);
              } else if (!Array.isArray(newValue)) {
                result.push({path: field, value: newValue});
              } else {
                newValue.forEach((val) => {
                  result.push({path: field, value: val});
                });
              }
              setFilters(result);
            },
            fieldData: {
              field,
              operators: selectedFilters
                .filter((f) => f.path === field)
                .map((f) => f.operator),
              type: operatorType,
              val: selectedFilters
                .filter((f) => f.path === field)
                .map((f) => f.value),
            },
            customFieldData: customFields,
            optionData: optionData,
            componentMap: componentMap,
            operatorType: operatorType,
          })
        }
      </FieldComponentProvider>
    </FlexContainer>
  );
}
