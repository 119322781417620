import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Checkbox, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {updateGuest} from "redux/actions/guestsActions";
import {
  crmGuestTableColumns,
  crmV2NewFields,
  defaultFieldMap,
  guestFilters,
} from "configuration/constants.js";
import {fieldParams} from "configuration/specs.js";
import {getEncodedFilters, getNestedObject} from "utilities/helperFunctions";
import {isFirefox} from "react-device-detect";
import _ from "lodash";
import GuestListTableHeader from "./GuestListTableHeader";
import CrmColumnsMenu from "../panel/CrmColumnsMenu";
import {getCRMGuestList} from "../../../../redux/actions/crmActions";
import {useTranslation} from "react-i18next";
import {useFlags} from "launchdarkly-react-client-sdk";
import VirtualizedTableComponent from "connectui/component/layout/table/VirtualizedTableComponent";
import Skeleton from "../../../../connectui/component/display/Skeleton";
import FieldComponentProvider from "../../field/FieldComponentProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  columnsMenu: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  columnsMenuItem: {
    padding: 0,
    borderRadius: 5,
    minHeight: 45,
  },
  rowItem: {
    overflow: "hidden",
  },
  tableSection: {
    marginLeft: "10px",
    height: "100%",
    marginBottom: "15px",
    flexGrow: 1,
    width: "calc(100vw - 116px)",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function GuestListTable({
  create,
  filters,
  updateFilters,
  columns,
  updateColumns,
  exportButton,
  searchText,
  setSearchText,
  setViewsDialogOpen,
  hideCheckboxColumn,
  setSelectedIds,
  openGuestPanel,
  disableFilters,
}) {
  const classes = useStyles({isMoz: isFirefox});
  const dispatch = useDispatch();
  const [customFieldsMap, setCustomFieldsMap] = React.useState({});
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const [totals, setTotals] = React.useState(null);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [writeFilter, setWriteFilter] = React.useState(null);
  const [guestListHits, setGuestListHits] = React.useState([]);
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = React.useState(null);
  const [allColumns, setAllColumns] = React.useState([]);
  const [guestListData, setGuestListData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const currentPageRef = useRef(currentPage);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const flag = useFlags();

  const {t} = useTranslation();
  const latestRequestRef = React.useRef(0);

  const tableFields = React.useMemo(() => {
    const keysToRemove = !flag.enableCrmViews ? crmV2NewFields : [];
    return crmGuestTableColumns.filter((key) => !keysToRemove.includes(key));
  }, [flag.enableCrmViews]);

  const allFilters = React.useMemo(() => {
    let newFilters = [...guestFilters.filterKeys];
    if (!flag.enableCrmViews) {
      newFilters = newFilters.filter((key) => !crmV2NewFields.includes(key));
    }
    if (groups.length < 2) {
      newFilters = newFilters.filter((f) => f !== "guest_group_id");
    }
    return newFilters;
  }, [groups]);

  const rowCount = isLoading
    ? 20
    : searchText && searchText !== ""
      ? guestListHits.length
      : hasNextPage
        ? guestListHits.length + 1
        : guestListHits.length;

  const toggleSelectAll = (e) => {
    const val = e.target.checked;
    const newSelectedGuests = val
      ? [...guestListHits.map((g) => g.guest_id), "all"]
      : [];
    setSelectedGuests(newSelectedGuests);
    setSelectedIds(newSelectedGuests.filter((g) => g !== "all"));
  };

  const onRowCheck = (guestId) => {
    let newSelectedGuests;
    if (selectedGuests.includes("all")) {
      newSelectedGuests = selectedGuests.filter(
        (g) => !(g === guestId || g === "all"),
      );
    } else {
      newSelectedGuests = selectedGuests.includes(guestId)
        ? selectedGuests.filter((g) => g !== guestId)
        : [...selectedGuests, guestId];
    }
    setSelectedGuests(newSelectedGuests);
    setSelectedIds(newSelectedGuests.filter((g) => g !== "all"));
  };

  const removeFilter = (selectedFilter) => {
    const newFilters = [...filters];
    const filterIndex = filters.findIndex(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      updateFilters(newFilters);
    }
  };

  const searchBarProps = !disableFilters
    ? {
        enableFilters: true,
        filters,
        disableSelectedFilters: true,
        filterKeys: allFilters,
        handleDeleteFilter: removeFilter,
        setFilters: updateFilters,
      }
    : {};

  const viewProfile = (index) => {
    const selectedGuest = guestListHits[index];
    if (selectedGuest?.unread_messages) {
      dispatch(
        updateGuest({
          guest_id: selectedGuest.guest_id,
          field: "unread_messages",
          val: false,
          disableAlert: true,
        }),
      );
    }
    openGuestPanel(selectedGuest);
  };

  const isRowLoaded = ({index}) => {
    if (searchText && searchText !== "") return true;
    return index < guestListHits.length;
  };

  const loadNextPage = async () => {
    if (currentPageRef.current === -1 || currentPage === -1) {
      currentPageRef.current = 2;
      setCurrentPage(2);
      return;
    }
    if (loadingMore || !hasNextPage || (searchText && searchText !== ""))
      return;
    setLoadingMore(true);
    const requestId = latestRequestRef.current;
    await dispatch(
      getCRMGuestList(
        null,
        "line_chart",
        null,
        getEncodedFilters(filters, false),
        searchText,
        (newData) => {
          if (requestId !== latestRequestRef.current) return;
          if (newData[0]?.hits?.length) {
            setGuestListHits((prev) => [...prev, ...newData[0].hits]);
            setCurrentPage((prevPage) => {
              const newPage = prevPage + 1;
              currentPageRef.current = newPage;
              return newPage;
            });
            if (newData[0].hits.length < 50) {
              setHasNextPage(false);
            }
          } else {
            setHasNextPage(false);
          }
          setLoadingMore(false);
        },
        () => {
          if (requestId !== latestRequestRef.current) return;
          setLoadingMore(false);
          setHasNextPage(false);
        },
        currentPageRef.current,
      ),
    );
  };

  const clearAll = () => {
    setSearchText("");
    setSelectedGuests([]);
    updateFilters([]);
  };

  const saveWriteFilter = () => {
    updateFilters([...filters, writeFilter]);
    setWriteFilter(null);
  };

  const onWriteFilterKeyDown = (event) => {
    if (event.key === "Escape") {
      setWriteFilter(null);
      return false;
    } else if (!["Enter", "Tab"].includes(event.key)) {
      return false;
    }
    saveWriteFilter();
  };

  const onWriteFilterChange = (e) => {
    const val = e.target.value;
    setWriteFilter((prev) => ({...prev, value: val}));
  };

  const onClickRow = (index) => {
    if (index >= guestListHits.length) return;
    if (hideCheckboxColumn) {
      viewProfile(index);
    } else {
      onRowCheck(guestListHits[index].guest_id);
    }
  };

  React.useEffect(() => {
    setSelectedGuests([]);
  }, [filters]);

  React.useEffect(() => {
    setGuestListHits((prev) =>
      prev.map((g) => (guests[g.guest_id] ? {...g, ...guests[g.guest_id]} : g)),
    );
  }, [guests]);

  React.useEffect(() => {
    setIsLoading(true);
    const newHits = guestListData?.length ? guestListData[0].hits : [];
    setGuestListHits(newHits);
    let newCustomFieldsMap = {};
    if (
      guestListData?.length &&
      guestListData[0]?.hits?.length &&
      guestListData[0]?.hits[0]?.custom_fields
    ) {
      guestListData[0].hits.forEach((hit) => {
        if (hit.custom_fields) {
          Object.keys(hit.custom_fields).forEach((key) => {
            newCustomFieldsMap[key] = `custom_fields.${key}`;
          });
        }
      });
    }
    setCustomFieldsMap(newCustomFieldsMap);
    setAllColumns([
      ...new Set([...tableFields, ...Object.keys(newCustomFieldsMap)]),
    ]);
    setIsLoading(false);
  }, [guestListData, create, tableFields]);

  React.useEffect(() => {
    if (hideCheckboxColumn && selectedGuests.length) setSelectedGuests([]);
  }, [hideCheckboxColumn, selectedGuests]);

  React.useEffect(() => {
    setGuestListHits([]);
    setCurrentPage(-1);
    currentPageRef.current = -1;
    setHasNextPage(true);
    setIsLoading(true);
    latestRequestRef.current += 1;
    const requestId = latestRequestRef.current;
    dispatch(
      getCRMGuestList(
        null,
        "line_chart",
        null,
        getEncodedFilters(filters, false),
        searchText,
        (newData) => {
          if (requestId !== latestRequestRef.current) return;
          if (newData[0]?.count > 0) {
            setTotals(newData[0]?.count || null);
          }
          setGuestListHits(newData[0]?.hits || []);
          setGuestListData(newData);
          setIsLoading(false);
          setHasNextPage(newData[0]?.hits.length >= 50);
        },
        () => {
          if (requestId !== latestRequestRef.current) return;
          setIsLoading(false);
        },
        0,
      ),
    );
  }, [filters, searchText, dispatch]);

  const tableColumns = React.useMemo(() => {
    const result = [];
    if (!hideCheckboxColumn) {
      result.push({
        label: (
          <Checkbox
            size="small"
            color="secondary"
            checked={selectedGuests.includes("all")}
            onChange={toggleSelectAll}
            style={{marginLeft: "4px"}}
          />
        ),
        field: "checkbox",
        sortable: false,
        render: (rowData) => {
          if (!rowData?.guest_id) {
            return <Skeleton animation="wave" width="100%" height={"34px"} />;
          }
          const checked =
            selectedGuests.includes("all") ||
            selectedGuests.includes(rowData?.guest_id);
          return (
            <Checkbox
              size="small"
              checked={checked}
              onChange={() => onRowCheck(rowData?.guest_id)}
            />
          );
        },
      });
    }
    _.each(tableFields, (field) => {
      if (!columns.includes(field)) return;
      const label = fieldParams[field].name;
      result.push({
        label,
        field,
        sortable: false,
        render: (rowData) => {
          if (!rowData?.guest_id) {
            return <Skeleton animation="wave" width="100%" height={"34px"} />;
          }
          const dataKey = fieldParams[field].path.slice(1).join(".");
          const value = getNestedObject(rowData, dataKey.split(".")) || "";

          if (!value || value === "") {
            return "-";
          }

          if (
            defaultFieldMap[fieldParams[field].field_type] !== null &&
            false
          ) {
            return (
              <FieldComponentProvider fieldInfo={{field: field, val: value}}>
                {({component}) => (
                  <Tooltip title={component({type: "label"})} arrow>
                    <div className={classes.rowItem}>
                      {component({type: "label"})}
                    </div>
                  </Tooltip>
                )}
              </FieldComponentProvider>
            );
          } else {
            if (Array.isArray(value)) {
              return value.join(", ");
            }
            return (
              <Tooltip title={value} arrow>
                <div className={classes.rowItem}>{value}</div>
              </Tooltip>
            );
          }
        },
      });
    });
    if (
      guestListData?.length &&
      guestListData[0]?.hits?.length &&
      guestListData[0]?.hits[0]?.custom_fields
    ) {
      Object.keys(customFieldsMap).forEach((key) => {
        if (!columns.includes(key)) return;
        const label = fieldParams[key]?.name || key;
        result.push({
          label,
          field: key,
          sortable: false,
          render: (rowData) => {
            if (!rowData?.guest_id) {
              return <Skeleton animation="wave" width="100%" height={"34px"} />;
            }
            const customVal = getNestedObject(
              rowData,
              customFieldsMap[key].split("."),
            );
            if (Array.isArray(customVal)) {
              return customVal.join(", ");
            }
            return (
              <Tooltip title={customVal || ""} arrow>
                <div className={classes.rowItem}>{customVal || ""}</div>
              </Tooltip>
            );
          },
        });
      });
    }
    return result;
  }, [
    hideCheckboxColumn,
    selectedGuests,
    columns,
    toggleSelectAll,
    onRowCheck,
    tableFields,
    guestListData,
    customFieldsMap,
  ]);

  return (
    <div className={classes.root}>
      <CrmColumnsMenu
        allColumns={allColumns}
        selectedColumns={columns}
        setSelectedColumns={updateColumns}
        fieldParams={fieldParams}
        columnsMenuAnchorEl={columnsMenuAnchorEl}
        closeColumnsMenu={() => {
          setColumnsMenuAnchorEl(null);
        }}
      />
      <GuestListTableHeader
        filters={filters}
        setViewsDialogOpen={setViewsDialogOpen}
        disableFilters={disableFilters}
        removeFilter={removeFilter}
        clearAll={clearAll}
        setColumnsMenuAnchorEl={setColumnsMenuAnchorEl}
        exportButton={exportButton}
        searchText={searchText}
        handleSearchInput={(val) => setSearchText(val)}
        totals={totals}
        searchBarProps={searchBarProps}
        writeFilter={writeFilter}
        closeWriteFilter={() => {
          setWriteFilter(null);
        }}
        onWriteFilterChange={onWriteFilterChange}
        onWriteFilterKeyDown={onWriteFilterKeyDown}
        fieldParams={fieldParams}
      />
      <div className={classes.tableSection}>
        {!guestListHits.length && !isLoading ? (
          <>
            <Box height={"100%"} minWidth={0}>
              <VirtualizedTableComponent
                columns={tableColumns}
                data={[]}
                totalRows={0}
                rowHeight={56}
                isRowLoaded={() => true}
                loadMoreRows={() => {}}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography variant="h6">No Results</Typography>
            </Box>
          </>
        ) : (
          <VirtualizedTableComponent
            columns={tableColumns}
            data={
              loadingMore
                ? [...guestListHits, ...Array(5).fill({guest_id: null})]
                : guestListHits
            }
            totalRows={rowCount}
            rowHeight={56}
            isRowLoaded={isRowLoaded}
            loadMoreRows={
              !(searchText && searchText !== "") ? loadNextPage : undefined
            }
            onClickRow={onClickRow}
          />
        )}
      </div>
    </div>
  );
}
