import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  getEncodedFilters,
  getFilterCount,
  newUuid,
  sleep,
} from "utilities/helperFunctions";
import {getGuests} from "redux/actions/guestsActions";
import {InboxContext} from "routes/MessagesPage";
import _ from "lodash";

import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";

import Text from "connectui/component/display/Text";
import SvgIcon from "connectui/component/display/SvgIcon";
import Container from "connectui/component/layout/Container";
import FlexContainer from "connectui/component/layout/FlexContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "connectui/component/input/text/OutlinedInputBase";
import ButtonIconText from "../../../../../connectui/component/input/button/ButtonIconText";
import Tooltip from "connectui/component/display/Tooltip";
import ButtonBase from "connectui/component/input/button/base/ButtonBase";
import ThreadList from "../../threads/ThreadList";
import ButtonText from "../../../../../connectui/component/input/button/ButtonText";
import OutlinedButtonBase from "../../../../../connectui/component/input/button/base/OutlinedButtonBase";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentArea: {
      flex: 1,
      overflow: "hidden",
      backgroundColor: theme.colors.white,
    },
    loadingText: {
      padding: theme.spacing(4),
    },
    icon: {
      width: "20px",
      height: "20px",
      color: theme.colors.textPrimary,
      marginRight: theme.spacing(2),
    },
    xIcon: {
      width: "11px",
      height: "11px",
      cursor: "pointer",
      color: theme.colors.gray500,
    },
    archivedIcon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(2),
    },
    archivedBtn: {
      padding: theme.spacing(5),
      backgroundColor: "transparent",
      boxShadow: "none",
      width: "100%",
      height: "fit-content",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      borderTop: `1px solid ${theme.colors.border}`,
      "&:hover": {backgroundColor: theme.colors.gray50},
    },
    archivedBtnSelected: {
      backgroundColor: theme.colors.primary50,
      "&:hover": {backgroundColor: theme.colors.primary100},
    },
    filtersTotalBtn: {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      border: "none",
      backgroundColor: theme.colors.primary50,
      color: theme.colors.primary,
    },
    closeIcon: {
      width: "8px",
      height: "8px",
    },
    noMessagesIcon: {
      width: "90px",
      height: "90px",
    },
  });

const archivedFilters = [
  {
    path: "guest_tags",
    value: "archived",
  },
];

const MessageThreadsPanel = ({
  sx = {},
  openFilters = () => null,
  onGuestClick = () => null,
  expandArrow = null,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const {appliedFilters, setAppliedFilters} = useContext(InboxContext);
  const currentRequest = useRef(null);
  const currentSearch = useRef(null);
  const totalFilters = useMemo(
    () =>
      [...new Set(appliedFilters.map((f) => f.path))].reduce(
        (total, filter) => total + getFilterCount(filter, appliedFilters),
        0,
      ),
    [appliedFilters],
  );

  const dispatch = useDispatch();
  const hasError = useSelector(
    (state) => state.defaultReducer.errors.guests.hasError,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const guests = useSelector((state) => state.defaultReducer.guests_dict);

  const [clearXVisible, setClearXVisible] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [archivedOpen, setArchivedOpen] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true); // Assume more pages by default
  const [totalCount, setTotalCount] = useState(0); // track guest count idk
  const [loadingGuests, setLoadingGuests] = useState(
    !Object.keys(guests).length,
  );
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");

  useEffect(() => {
    // Open/Close archived messages based on applied filters
    let archivedFiltersApplied = _.isEqual(appliedFilters, archivedFilters);
    if (archivedOpen !== archivedFiltersApplied) {
      setArchivedOpen(archivedFiltersApplied);
    }

    // Wait for appliedFilters to reflect the selected view queries on initial load
    if (!appliedFilters.length && !!viewId) {
      return;
    }
    loadPage(0, searchText);
  }, [appliedFilters]);

  const filterArchivedGuests = (newData) =>
    archivedOpen || appliedFilters.find((f) => f.value === "archived")
      ? newData
      : newData.filter((g) => !g.tags?.includes("archived"));

  const loadPage = (start, search) => {
    if (start === 0) {
      setLoadingGuests(true);
    }
    let params = `enso_key=${current_user}&start=${start}`;
    if (!!search) {
      params += `&query=${encodeURIComponent(search)}`;
    }
    if (!!appliedFilters.length) {
      params += `&filters=${getEncodedFilters(appliedFilters)}`;
    }

    const requestID = newUuid();
    currentRequest.current = requestID;

    dispatch(
      getGuests({
        params,
        add: start > 0,
        onSuccess: (response) => {
          if (requestID !== currentRequest.current) {
            return;
          }
          if (start === 0) {
            setData(filterArchivedGuests(response.hits));
            setTotalCount(response.count || response.hits.length);
          } else {
            setData((prev) => prev.concat(filterArchivedGuests(response.hits)));
          }
          setHasNextPage(!!response.start_key);
          setLoadingGuests(false);
        },
        onError: () => {
          if (requestID !== currentRequest.current) {
            return;
          }
          setHasNextPage(false);
          setLoadingGuests(false);
        },
      }),
    );
  };

  const isRowLoaded = ({index}) => index < data.length;

  const showClearX = () => setClearXVisible(true);
  const hideClearX = () => setClearXVisible(false);

  const loadMoreRows = ({startIndex, stopIndex}) => {
    if (!hasNextPage || loadingGuests) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      loadPage(data.length, searchText);
      resolve();
    });
  };

  const clearFilters = () => {
    setAppliedFilters([]);
    if (viewId) {
      window.history.pushState({}, "", window.location.pathname);
    }
  };

  const totalRows = hasNextPage ? data.length + 1 : data.length;

  return (
    <Container className={classes.container} sx={sx} {...props}>
      <FlexContainer
        flexDirection="column"
        p={theme.spacing(4)}
        gap={theme.spacing(4)}
      >
        <FlexContainer gap={theme.spacing(3)} alignItems="center">
          {expandArrow}
          <Text variant="displayXS" weight="semibold">
            {t("message-threads-panel-header")}
          </Text>
        </FlexContainer>
        <TextInput
          inputBase={OutlinedInputBase}
          startIcon={
            <SvgIcon
              component={ICONS.search}
              className={classes.icon}
              sx={{color: theme.colors.gray500}}
            />
          }
          inputProps={{
            placeholder: t("search"),
            value: searchText,
            onChange: (e) => {
              const val = e.target.value;
              setSearchText(val);
              // If search changes, consider clearing data and reloading:
              setLoadingGuests(true);
              setHasNextPage(true);
              currentSearch.current = val;
              if (!val) {
                setData([]);
                loadPage(0, val);
              } else {
                sleep(200).then(() => {
                  if (val === currentSearch.current) {
                    setData([]);
                    loadPage(0, val);
                  }
                });
              }
            },
          }}
          actions={
            !!searchText && (
              <SvgIcon
                component={ICONS.x}
                className={classes.xIcon}
                onClick={() => {
                  setLoadingGuests(true);
                  setHasNextPage(true);
                  setSearchText("");
                  setData([]);
                  loadPage(0, "");
                }}
              />
            )
          }
        />
        <FlexContainer
          gap={theme.spacing(2)}
          alignItems="center"
          sx={{width: "100%"}}
        >
          <ButtonIconText
            sx={{
              padding: "0px",
              backgroundColor: "transparent !important",
              boxShadow: "none",
            }}
            label={"Filters"}
            weight="semibold"
            typographyVariant="textSM"
            color="gray700"
            onClick={openFilters}
            icon={<SvgIcon component={ICONS.filter} className={classes.icon} />}
          />
          {!!appliedFilters.length && (
            <Tooltip
              tooltipContent={
                <Text variant="textXS" weight="semibold" color="white">
                  {"Clear Filters"}
                </Text>
              }
              styles={{
                wrapper: {
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                },
                tooltip: {
                  backgroundColor: theme.colors.gray700,
                },
                tooltipArrow: {
                  borderTop: `6px solid ${theme.colors.gray700}`,
                },
              }}
            >
              <ButtonBase
                className={classes.filtersTotalBtn}
                onMouseEnter={showClearX}
                onMouseLeave={hideClearX}
                onClick={clearFilters}
              >
                <FlexContainer
                  sx={{width: "100%", height: "100%"}}
                  alignItems="center"
                  justifyContent="center"
                >
                  {clearXVisible ? (
                    <SvgIcon
                      component={ICONS.x}
                      className={`${classes.closeIcon} close-icon`}
                    />
                  ) : (
                    <Text
                      variant="textSM"
                      weight="semibold"
                      color="primary"
                      sx={{marginRight: "1px"}}
                    >
                      {totalFilters}
                    </Text>
                  )}
                </FlexContainer>
              </ButtonBase>
            </Tooltip>
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer flexDirection="column" className={classes.contentArea}>
        <FlexContainer flex={1} width="100%">
          {hasError && (
            <Text variant="textSM" className={classes.loadingText}>
              {t("error_loading_guests")}
            </Text>
          )}
          {!loadingGuests && !hasError && data.length === 0 && (
            <FlexContainer
              sx={{width: "100%", height: "100%"}}
              gap={theme.spacing(2)}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <SvgIcon
                component={
                  appliedFilters.length > 0
                    ? ICONS["no-messages-filters"]
                    : ICONS["no-messages"]
                }
                className={classes.noMessagesIcon}
              />
              <Text
                variant="textMD"
                color={"textSecondary"}
                sx={{paddingTop: theme.spacing(2)}}
              >
                {appliedFilters.length > 0
                  ? t("no-messages-filters")
                  : t("no-messages")}
              </Text>
              {appliedFilters.length > 0 ? (
                <ButtonText
                  buttonBase={OutlinedButtonBase}
                  typographyVariant="textSM"
                  weight="semibold"
                  color="gray700"
                  label="Clear Filters"
                  sx={{boxShadow: "none", width: "112px"}}
                  onClick={clearFilters}
                />
              ) : (
                <Text
                  variant="textSM"
                  color={"textTertiary"}
                  sx={{maxWidth: "200px", textAlign: "center"}}
                >
                  {t("no-messages-subtitle")}
                </Text>
              )}
            </FlexContainer>
          )}
          {!hasError && (loadingGuests || data.length > 0) && (
            <ThreadList
              data={data}
              skeleton={{enabled: loadingGuests}}
              totalRows={totalRows}
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              onGuestClick={onGuestClick}
              removeGuestFromList={(guest) =>
                setData((prev) =>
                  prev.filter((g) => g.guest_id !== guest.guest_id),
                )
              }
            />
          )}
        </FlexContainer>
        {!hasError && !loadingGuests && (
          <Container width="100%">
            <ButtonIconText
              className={`${classes.archivedBtn} ${archivedOpen ? classes.archivedBtnSelected : ""}`}
              label={"Archived Messages"}
              weight="semibold"
              typographyVariant="textSM"
              color={archivedOpen ? "primary" : "textSecondary"}
              onClick={() => {
                setAppliedFilters(archivedOpen ? [] : archivedFilters);
                setArchivedOpen((prev) => !prev);
                if (viewId) {
                  window.history.pushState({}, "", window.location.pathname);
                }
              }}
              icon={
                <SvgIcon
                  component={ICONS.archive}
                  sx={{
                    color: archivedOpen
                      ? theme.colors.primary
                      : theme.colors.textSecondary,
                  }}
                  className={classes.archivedIcon}
                />
              }
            />
          </Container>
        )}
      </FlexContainer>
    </Container>
  );
};

export default MessageThreadsPanel;
