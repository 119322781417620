export const guidebookTemplateConfig = {
  tags: [
    {key: "top", label: "Essential"},
    {key: "listing", label: "Listing Guides"},
    {key: "place", label: "Places"},
    //{key: "events", label: 'Events & Offers'}
  ],
  templates: [
    /*{
      preview:
        "A general welcome guide for guests, including important information about the property and local area.",
      header_image:
        "https://images.pexels.com/photos/3826678/pexels-photo-3826678.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Welcome to Your Stay",
              },
            ],
            label: [],
          },
          {
            type: "summary",
            show_contents: true,
            text: [
              {
                language: "en",
                value:
                  "Welcome to our home! We are delighted to have you as our guest and want to ensure your stay is as comfortable and enjoyable as possible.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Property Overview",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Our home is equipped with everything you need, {provide an overview of the property and its amenities}",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Key Features",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Bedrooms: Comfortable beds with fresh linens.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Kitchen: Fully equipped with modern appliances and utensils.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Living Room: Cozy space with a TV and entertainment options.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Outdoor Area: Relax in the garden or on the patio.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Wi-Fi: High-speed internet throughout the property.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Important Rules & Information",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value:
                  "Please keep the following rules in mind during your stay. {provide an overview of house rules}",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Accidents happen, please notify us of any damages as soon as possible",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Please take out the trash before you exit the property",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "{describe any additional rules}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Contact Information: Reach us at {contact_info} if you need any assistance.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Explore Our Guidebooks",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We’ve created detailed guidebooks to help you navigate different areas of the home. Click on the links below to learn more about specific amenities and services available during your stay.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Guidebooks",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Kitchen & Coffee Guide - Learn about the kitchen appliances, coffee maker, and basic supplies.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Climate Control & AC Guide - Get tips on adjusting the temperature and keeping comfortable.",
                  },
                ],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Laundry & Linens - Instructions for using the washer, dryer, and drying rack.",
                  },
                ],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "TV & Entertainment Guide - Learn how to use the TV, streaming services, and more.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "We’re Here to Help",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you have any questions or need assistance during your stay, please don’t hesitate to reach out. We’re here to ensure your experience is seamless and enjoyable.",
              },
            ],
          },
        ],
      },
      name: [
        {
          value: "Welcome!",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "welcome-guide",
      guidebook_type: "welcome",
    },*/
    {
      preview:
        "Guide on how to use the heating and cooling systems, including thermostat instructions and best practices.",
      header_image:
        "https://images.pexels.com/photos/7616651/pexels-photo-7616651.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      name: [
        {
          value: "Climate Control",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "climate-control",
      guidebook_type: "listing",
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Climate Control & AC Guide",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We want to ensure you're comfortable during your stay, so here’s a quick guide on how to control the climate and air conditioning in the house.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Thermostat Instructions",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "The thermostat is located {location}. It controls the temperature throughout the house. Here’s how to adjust it to your preference.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Thermostat Usage",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "To increase the temperature, press the 'up' button.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "To lower the temperature, press the 'down' button.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Switch between 'heat' and 'cool' modes by pressing {button_name}.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Fan modes can be adjusted to 'auto' or 'on' for continuous airflow.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "Thermostat location and basic controls.",
              },
            ],
            image:
              "https://images.pexels.com/photos/6347539/pexels-photo-6347539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Windows & Ventilation",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "For fresh air, you can open the windows located in {rooms}. Please make sure to close them if you’re using the AC to maintain energy efficiency.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Ventilation Tips",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Ensure all windows are closed when the air conditioning is running.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Turn on exhaust fans in the bathroom or kitchen when cooking or showering.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Troubleshooting",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you encounter any issues with the climate control or air conditioning system, here are a few things to check before contacting us.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Common Issues",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Ensure the thermostat or AC unit is powered on.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Check that windows and doors are fully closed.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Make sure the remote control has batteries and they are inserted correctly.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
        ],
      },
    },
    {
      preview:
        "Details on using coffee machines and kitchen appliances provided in the property.",
      header_image:
        "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      name: [
        {
          value: "Kitchen & Coffee",
          language: "en",
        },
      ],
      properties: {
        components: [
          {
            type: "summary",
            show_contents: true,
            text: [
              {
                language: "en",
                value:
                  "We hope you enjoy using the kitchen during your stay! Here you will find all the information you need about the kitchen appliances, coffee station, and basic supplies. {provide an overview of the kitchen amenities}",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Coffee Station",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "The kitchen is equipped with a coffee station to get your day started right. {provide a summary of the coffee options}",
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value:
                  "The coffee station, complete with everything you need for a perfect cup.",
              },
            ],
            image:
              "https://images.pexels.com/photos/28482920/pexels-photo-28482920/free-photo-of-stylish-coffee-break-with-nespresso-capsules.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Coffee Station Details",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Coffee Maker (Drip/French Press/Nespresso)",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Instructions for using the coffee maker: {instructions}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Coffee pods/grounds are located: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Mugs and cups can be found: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Extras: Sugar, creamers, and stirrers are available: {location}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Kitchen Appliances",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Our kitchen is fully stocked with modern appliances for your convenience. {provide an overview of the kitchen appliances}",
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "Modern kitchen appliances for all your cooking needs.",
              },
            ],
            image:
              "https://images.pexels.com/photos/213162/pexels-photo-213162.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Appliance Instructions",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Oven & Stovetop: {basic_instructions}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Microwave: {basic_instructions}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Dishwasher: {basic_instructions}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Fridge & Freezer: {basic_instructions}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Cookware & Utensils",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Available Cookware",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Pots & Pans: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Cutlery & Knives: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Serving dishes & Bowls: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Specialty Utensils (e.g., garlic press, bottle opener): {location}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Basic Ingredients",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We’ve provided a few basics to help with your cooking. You’ll find essentials like salt, pepper, and oil in the following locations.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Basic Ingredients",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Salt & Pepper: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Cooking Oil: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Other Seasonings: {location}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Cleaning Supplies & Waste Disposal",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "To keep the kitchen tidy, cleaning supplies are available, and we’ve provided instructions on how to dispose of waste during your stay.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Cleaning Supplies",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Dish Soap & Sponge: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Dishwasher Pods: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Trash Bin: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Recycling Bin: {location}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Safety Information",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Safety Information",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Fire Extinguisher: {location}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Smoke Alarms: Installed and operational",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "First Aid Kit: {location}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Local Grocery Stores & Coffee Shops",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you need to restock any supplies, here are a few local grocery stores and coffee shops nearby.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Nearby Stores and Shops",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Grocery Store 1: {store_details}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Grocery Store 2: {store_details}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Coffee Shop 1: {shop_details}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Coffee Shop 2: {shop_details}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
        ],
      },
      icon: "https://enso-image-assets.s3.amazonaws.com/products/restaurant.png",
      template_id: "coffee-kitchen-guide",
      guidebook_type: "listing",
    },
    {
      preview:
        "Guide on how to use the laundry facilities, including washer and dryer instructions.",
      header_image:
        "https://images.pexels.com/photos/1937336/pexels-photo-1937336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Laundry Instructions",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We’ve made it easy for you to take care of laundry during your stay. Below, you'll find instructions for using the washer, dryer, and drying rack, as well as information about mid-stay cleaning services.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Washer Instructions",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Our property is equipped with a washing machine. {provide instructions and an overview of the washing machine}",
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "Washer and dryer location and basic controls.",
              },
            ],
            image:
              "https://images.pexels.com/photos/5591460/pexels-photo-5591460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Use the Washer",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Add your laundry detergent and fabric softener to the designated compartments.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "{describe the 2nd step}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "{describe the 3rd step}",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "{describe the 4th step}",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Dryer Instructions",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "After your clothes are washed, the dryer is available to make sure everything is dry and ready to wear. It’s located next to the washer.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Use the Dryer",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Clean the lint filter before use.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Load the dryer and select the appropriate drying cycle (e.g., low heat, high heat, or air dry).",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Press start and wait for the cycle to complete.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Drying Rack",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you prefer to air dry your clothes, there is a drying rack available for your use. It can be found in {location}.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Drying Rack Tips",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Place the drying rack in a well-ventilated area for faster drying.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Hang clothes evenly across the rack to avoid wrinkles.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Mid-Stay Cleaning Service",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you would like to refresh your space during your stay, we offer mid-stay cleaning services. For an additional fee, we can arrange a cleaning of your linens, towels, and common areas.",
              },
            ],
          },
        ],
      },
      tags: ["top"],
      name: [
        {
          value: "Laundry & Linens",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "laundry-guide",
      guidebook_type: "listing",
    },
    {
      preview:
        "Instructions for using the TV and entertainment systems available at the property.",
      header_image:
        "https://images.pexels.com/photos/987586/pexels-photo-987586.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "TV & Entertainment Guide",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Relax and enjoy the entertainment options available in the home. Below, you'll find instructions on how to use the TV, streaming services, and other entertainment amenities.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "TV Instructions",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "The TV is located in the {location}. It’s equipped with streaming services, live TV options, and more for your viewing pleasure.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Use the TV",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Use the remote control to turn on the TV by pressing the power button.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Navigate to the home screen by pressing the {button_name} button on the remote.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Select your preferred streaming service or live TV channel using the directional buttons.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "To adjust the volume, use the volume buttons on the side of the remote.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "TV remote and controls overview.",
              },
            ],
            image:
              "https://images.pexels.com/photos/5202918/pexels-photo-5202918.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Streaming Services",
              },
            ],
            label: [],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "Streaming service navigation example.",
              },
            ],
            image:
              "https://images.pexels.com/photos/5202917/pexels-photo-5202917.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "You have access to various streaming services such as Netflix, Hulu, Amazon Prime, and more. Here's how to sign in and start watching.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Use Streaming Services",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Navigate to the desired streaming app on the TV home screen.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Sign in using your personal account, or enjoy the pre-installed guest account where available.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Browse the available shows or movies, and select the one you wish to watch.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Use the remote to play, pause, or skip through content.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Music & Other Entertainment",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "In addition to TV, you can enjoy music, games, and other entertainment options available in the home.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Available Entertainment Options",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Bluetooth Speaker: You can pair your device to the {speaker_name} for music playback.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Board Games: A selection of games is available in the {location} for some fun with friends or family.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Books & Magazines: You’ll find a variety of books and magazines in the {location} for some quiet reading.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Troubleshooting",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "If you experience any issues with the TV or entertainment system, here are a few tips to resolve common problems.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Common Issues",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Make sure the TV is powered on and connected to the internet.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Ensure the remote control has working batteries.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Check if the TV is set to the correct input source (e.g., HDMI 1 for streaming services).",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
        ],
      },
      tags: ["top"],
      name: [
        {
          value: "TV & Entertainment",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "tv-entertainment",
      guidebook_type: "listing",
    },
    {
      preview:
        "Information about the surrounding neighborhood, including places to visit and things to do.",
      header_image:
        "https://images.pexels.com/photos/1458759/pexels-photo-1458759.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      name: [
        {
          value: "About the Neighbourhood",
          language: "en",
        },
      ],
      template_spec: {
        google_maps_queries: [
          {
            search_radius: 2,
          },
        ],
      },
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Place+Marker.png",
      template_id: "about-neighbourhood",
      guidebook_type: "place",
    },
    {
      preview:
        "A guide to nearby dining options, including restaurant recommendations and specialties.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/restaurants.png",
      tags: ["top"],
      template_spec: {
        google_maps_queries: [
          {
            search_radius: 5,
            place_type: "restaurant",
            query: "Restaurant",
          },
        ],
      },
      properties: {
        components: [
          {
            type: "summary",
            show_contents: true,
            icon: null,
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },
          /*{
            type: "place",
            display_name: "Place 1",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },
          {
            type: "place",
            display_name: "Place 2",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },*/
        ],
      },
      name: [
        {
          value: "Restaurant Guide",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/products/restaurant.png",
      template_id: "restaurant-guide",
      guidebook_type: "place",
    },
    {
      preview:
        "Explore the city with these local attractions, must-see spots, and hidden gems.",
      header_image:
        "https://images.pexels.com/photos/3050833/pexels-photo-3050833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      properties: {
        components: [
          {
            type: "summary",
            show_contents: true,
            icon: null,
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },
          /*{
            type: "place",
            display_name: "Place 1",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },
          {
            type: "place",
            display_name: "Place 2",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },*/
        ],
      },
      name: [
        {
          value: "Exploring the City",
          language: "en",
        },
      ],
      template_spec: {
        google_maps_queries: [
          {
            search_radius: 20,
            place_type: "tourist_attraction",
          },
        ],
      },
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Place+Marker.png",
      template_id: "exploring-city",
      guidebook_type: "place",
    },
    {
      preview:
        "A guide to local tours, excursions, and fun activities for guests to enjoy.",
      header_image:
        "https://images.pexels.com/photos/5137963/pexels-photo-5137963.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      tags: ["top"],
      properties: {
        components: [
          {
            type: "summary",
            show_contents: true,
            icon: null,
            text: [
              {
                language: "en",
                value: "Check out our favorite nearby places to shop.",
              },
            ],
            label: [],
          },
          /*{
            type: "place",
            display_name: "Place 1",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },
          {
            type: "place",
            display_name: "Place 2",
            text: [
              {
                language: "en",
                value: "Check out out favorite local spots to eat",
              },
            ],
            label: [],
          },*/
        ],
      },
      name: [
        {
          value: "Local Shopping",
          language: "en",
        },
      ],
      template_spec: {
        google_maps_queries: [
          {
            search_radius: 5,
            place_type: "shopping_mall",
          },
        ],
      },
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Place+Marker.png",
      template_id: "tours-activities",
      guidebook_type: "place",
    },
    {
      preview:
        "Important emergency and safety information for guests, including contact numbers.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/emergency.png",
      tags: ["top"],
      name: [
        {
          value: "Emergency & Safety Information",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "emergency-safety",
      guidebook_type: "listing",
    },
    {
      preview:
        "Information about bike storage facilities available to guests, including location and usage rules.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/bike-storage.png",
      tags: [],
      name: [
        {
          value: "Bike Storage",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "bike-storage",
      guidebook_type: "listing",
    },
    {
      preview:
        "Instructions for using the parking facilities available at the property.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/parking.png",
      tags: [],
      name: [
        {
          value: "Parking & Transport Guide",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "parking-guide",
      guidebook_type: "listing",
    },
    {
      preview:
        "Details on using the BBQ and outdoor cooking facilities safely and effectively.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/bbq.png",
      tags: [],
      name: [
        {
          value: "BBQ & Outdoor Cooking Guide",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "bbq-guide",
      guidebook_type: "listing",
    },
    {
      preview:
        "Step-by-step instructions for safely using the hot tub, including precautions and safety tips.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/hot-tub.png",
      tags: [],
      name: [
        {
          value: "Hot Tub",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "hot-tub",
      guidebook_type: "listing",
    },
    {
      preview:
        "Guide on using the pool safely, including rules and recommended safety measures.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/pool.png",
      tags: [],
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Pool Guide",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Welcome to the pool area! We hope you enjoy relaxing and having fun by the pool during your stay. Below are some guidelines for pool use, as well as information on how to request pool heating for added comfort.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Pool Rules & Safety",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Important Pool Rules",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Please do not run around the pool area to avoid slips and falls.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "No glass containers near the pool. Please use plastic or metal containers to prevent accidents.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Children must be supervised by an adult at all times.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Ensure that the pool gate is closed securely when entering or leaving the area.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Please shower before entering the pool.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Pool Heating Option",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "For those who prefer a warmer swimming experience, pool heating is available for an additional fee. Pool heating enhances your comfort, especially during cooler months.",
              },
            ],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Request Pool Heating",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Contact us at {contact_info} to request pool heating during your stay.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Let us know your preferred start date for the pool heating.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "We will provide details on the heating fee and confirm your request.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Pool Area Amenities",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "What’s Available",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Lounge Chairs: Perfect for relaxing by the pool.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Pool Towels: Towels are provided for your convenience. You’ll find them in {location}.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Umbrellas: Use the umbrellas to shade yourself from the sun.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Pool Toys: A selection of toys and floats is available for use. Please return them to {location} when finished.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Pool Cleaning & Maintenance",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "The pool is professionally cleaned and maintained to ensure a safe and enjoyable experience. If you notice any issues with the pool, such as water clarity or equipment malfunctions, please contact us immediately at {contact_info}.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Have Fun & Relax",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We hope you enjoy spending time at the pool! Whether you’re swimming, lounging, or just enjoying the view, we aim to make your experience as pleasant as possible. Feel free to reach out with any questions or requests during your stay.",
              },
            ],
          },
        ],
      },
      name: [
        {
          value: "Pool Guide",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "pool-guide",
      guidebook_type: "listing",
    },
    {
      preview:
        "Guide for safely using the fireplace, including lighting instructions and safety precautions.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/fireplace.png",
      tags: [],
      properties: {
        components: [
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Fireplace Guide",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "Our cozy fireplace is a wonderful way to relax and warm up during your stay. Below are instructions on how to safely use the fireplace along with some important safety tips.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Using the Fireplace",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "How to Operate the Fireplace",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Step 1: Ensure the fireplace is free of any obstructions.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Step 2: If it’s a gas fireplace, turn the gas valve to the 'on' position.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Step 3: Use the ignition switch or remote control to light the fireplace.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Step 4: Adjust the flame intensity as needed using the controls.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Step 5: To turn off the fireplace, switch the ignition off and close the gas valve (if applicable).",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "image",
            text: [
              {
                language: "en",
                value: "Fireplace controls and usage overview.",
              },
            ],
            image:
              "https://images.pexels.com/photos/22254/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Safety Tips",
              },
            ],
            label: [],
          },
          {
            type: "list",
            text: [
              {
                language: "en",
                value: "Important Fireplace Safety",
              },
            ],
            list_style: "bulleted",
            list_items: [
              {
                primary_text: [
                  {
                    language: "en",
                    value: "Never leave the fireplace unattended when in use.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Keep flammable items, including furniture and curtains, at least three feet away from the fireplace.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Ensure the fireplace is completely turned off before going to bed or leaving the house.",
                  },
                ],
                secondary_text: [],
              },
              {
                primary_text: [
                  {
                    language: "en",
                    value:
                      "Do not use water to extinguish a gas fireplace. Simply turn off the gas supply.",
                  },
                ],
                secondary_text: [],
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Fireplace Maintenance",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "The fireplace is professionally maintained to ensure safety and performance. If you notice any issues, such as the fireplace not lighting or strange smells, please contact us at {contact_info} immediately.",
              },
            ],
          },
          {
            type: "header",
            icon: null,
            text: [
              {
                language: "en",
                value: "Enjoy the Warmth!",
              },
            ],
            label: [],
          },
          {
            type: "text",
            text: [
              {
                language: "en",
                value:
                  "We hope you enjoy the cozy ambiance of the fireplace during your stay. It’s a perfect way to unwind after a long day of exploring!",
              },
            ],
          },
        ],
      },
      name: [
        {
          value: "Fireplace",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Home.png",
      template_id: "fireplace",
      guidebook_type: "listing",
    },
    {
      preview:
        "Information on nearby tourist attractions, including tips for visiting and special highlights.",
      header_image:
        "https://enso-image-assets.s3.amazonaws.com/products/attractions.png",
      tags: [],
      name: [
        {
          value: "Local Attractions",
          language: "en",
        },
      ],
      icon: "https://enso-image-assets.s3.amazonaws.com/icons/Place+Marker.png",
      template_id: "local-attractions",
      guidebook_type: "place",
    },
  ],
};
