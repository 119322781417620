import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {Box, ListItem, MenuList, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import useDashboardStyles from "styles/useDashboardStyles";
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import CurrencyText from "components/TextFields/CurrencyText";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import InfoText from "components/Misc/InfoText";
import CustomMenu from "core/menus/CustomMenu";
import {getDashboardRevenue} from "redux/actions/dashboardActions";
import {endOfDay, startOfDay, subDays} from "date-fns";
import {boardingPassProducts} from "configuration/enums";
import {onboardingContent} from "configuration/specs";
import {buildGuestFilterUrl, capitalize} from "utilities/helperFunctions";
import infoTexts from "assets/infoTexts";
import {FIXED_SIZES} from "configuration/settings";
import {useFlags} from "launchdarkly-react-client-sdk";
import VirtualizedTableComponent from "connectui/component/layout/table/VirtualizedTableComponent";
import Text from "../../../connectui/component/display/Text";

const now = new Date();
const defaultCurrencies = ["USD", "CAD", "NZD", "GBP", "AUD", "EUR"];

const RevenueStatsCard = ({
  loading,
  selectedGroups = [],
  noData,
  refreshDasboard,
  onRefreshCompleted,
  mobile,
  saveSelectedRange = () => {},
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const didRefresh = useRef(false);
  const revenueData = useSelector(
    (state) => state.dashboardReducer.revenueData,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedProps = selectedViewItems.dashboard.props;
  const [currency, setCurrency] = useState("CAD");
  const [stat, setStat] = useState("product_name");
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [currencyMenuAnchorEl, setCurrencyMenuAnchorEl] = useState(null);
  const [statMenuAnchorEl, setStatMenuAnchorEl] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [data, setData] = useState(revenueData?.product_types || []);
  const flag = useFlags();
  const [dateRange, setDateRange] = useState(
    !!savedProps.revenueStatsRange
      ? savedProps.revenueStatsRange
      : [
          new Date(startOfDay(subDays(now, 29))).getTime(),
          new Date(endOfDay(now)).getTime(),
        ],
  );
  const [sortParams, setSortParams] = useState({
    sortBy: "",
    sortDirection: "ASC",
  });
  let bigTotal = loading ? 0 : (revenueData?.total_revenue ?? 0);

  const openCurrencyMenu = (e) => setCurrencyMenuAnchorEl(e.currentTarget);
  const openStatMenu = (e) => setStatMenuAnchorEl(e.currentTarget);
  const closeCurrencyMenu = (e) => setCurrencyMenuAnchorEl(null);
  const closeStatMenu = (e) => setStatMenuAnchorEl(null);

  function getData(range, displayCurrency = null, newStat = stat) {
    if (displayCurrency) {
      setLocalLoading(true);
    }
    dispatch(
      getDashboardRevenue({
        start_date: range[0],
        end_date: range[1],
        stat: newStat,
        display_currency: displayCurrency,
        listing_group_ids: selectedGroups.length ? selectedGroups : null,
        onSuccess: () => {
          if (didRefresh.current) {
            didRefresh.current = false;
            onRefreshCompleted();
          }
          setLocalLoading(false);
        },
        onError: () => {
          setLocalLoading(false);
        },
      }),
    );
  }

  useEffect(() => {
    if (refreshDasboard && !didRefresh.current) {
      didRefresh.current = true;
      getData(dateRange);
    }
  }, [refreshDasboard]);

  useEffect(() => {
    setCurrency(revenueData?.display_currency ?? "CAD");
    setAvailableCurrencies(
      revenueData?.available_currencies ?? defaultCurrencies,
    );
  }, [revenueData]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      if (savedProps.revenueStatsRange) {
        didRefresh.current = true;
        getData(dateRange);
      }
      return;
    }
    setLocalLoading(true);
    getData(dateRange);
  }, [selectedGroups]);

  useEffect(() => {
    if (!sortParams.sortBy) {
      setData(revenueData?.product_types || []);
    } else {
      setData(
        _.orderBy(
          revenueData?.product_types || [],
          [sortParams.sortBy],
          [sortParams.sortDirection.toLowerCase()],
        ),
      );
    }
  }, [revenueData, sortParams]);

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    closeCurrencyMenu();
    setLocalLoading(true);
    getData(dateRange, newCurrency);
  };

  const handleStatChange = (newStat) => {
    setStat(newStat);
    closeStatMenu();
    setLocalLoading(true);
    getData(dateRange, null, newStat);
  };

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange(newRange);
    if (isRangeComplete) {
      saveSelectedRange(newRange);
      setLocalLoading(true);
      getData(newRange);
    }
  };

  const onRowClick = (index) => {
    if (index >= data.length) return;
    let row = data[index];
    let filter;
    if (stat === "product_name") {
      filter = {
        id: "guest_purchased_product_names",
        value: row.product_type.toLowerCase(),
      };
    } else if (stat === "product_type") {
      filter = {
        id: "guest_purchased_product_types",
        value: row.product_type,
      };
    }
    if (filter && flag.enableCrmViews) {
      const url = buildGuestFilterUrl(
        [filter],
        [
          "guest_purchased_product_names",
          "guest_purchased_product_types",
          "total_rev",
          "guest_name",
          "guest_destination",
          "enso_total_revenue",
        ],
      );
      window.location.href = "/admin/guests" + url;
    }
  };

  const tableColumns = [
    {
      label: "Revenue types",
      field: "product_type",
      sortable: true,
      render: (row) => (
        <Text variant={"textSM"}>
          {boardingPassProducts[row.product_type]?.name ||
            capitalize(row.product_type, "_")}
        </Text>
      ),
      getSortValue: (row) =>
        boardingPassProducts[row.product_type]?.name?.toLowerCase() ||
        row.product_type?.toLowerCase() ||
        "",
    },
    {
      label: "# of purchases",
      field: "num_of_purchases",
      sortable: true,
      render: (row) => <Text variant={"textSM"}>{row.num_of_purchases}</Text>,
      getSortValue: (row) => row.num_of_purchases || 0,
    },
    {
      label: "Total revenue",
      field: "revenue",
      sortable: true,
      render: (row) => (
        <CurrencyText
          disableFixedDecimal
          value={row.revenue}
          currency={currency}
          noSuffix
        />
      ),
      getSortValue: (row) => row.revenue || 0,
    },
  ];

  const isRowLoaded = ({index}) => index < data.length;

  const openUpsellGuide = () => {
    let btn = onboardingContent.upsells.buttons.find((b) =>
      b.text.includes("create"),
    );
    if (!btn) {
      return;
    } else {
      if (window.Intercom && !isMobile) {
        window.Intercom("showArticle", btn.article_id);
      }
    }
  };

  function getBlueBox(total, isFee) {
    return (
      <div
        className={clsx(
          classes.col,
          classes.centered,
          classes.gap8,
          classes.blueBox,
          {mobile: mobile},
        )}
      >
        <Typography
          component="div"
          variant="h3"
          className={clsx(classes.row, classes.centered)}
        >
          <CurrencyText
            hideSymbol={loading}
            disableFixedDecimal={Number.isInteger(total)}
            value={total}
            customClass={classes.bigNumber}
            currency={currency}
            noSuffix
          />
          <Box ml={1}>
            <Typography component="span" variant="h1" color="secondary">
              {currency}
            </Typography>
          </Box>
        </Typography>
        <Box className={clsx(classes.row, classes.centered)}>
          <Typography variant="subtitle2">
            {isFee ? "Fees" : "Upsells"}
          </Typography>
          <Box ml={2} display="flex">
            <InfoText
              disableGutters
              text={
                isFee
                  ? infoTexts.totalRevenueFees
                  : infoTexts.totalRevenueUpsells
              }
              subdued
            />
          </Box>
        </Box>
      </div>
    );
  }

  const currencyMenu = (
    <CustomMenu
      open={!!currencyMenuAnchorEl}
      anchorEl={currencyMenuAnchorEl}
      onClose={closeCurrencyMenu}
      overflowAuto
      maxHeight={500}
      content={
        <MenuList>
          {availableCurrencies.map((c) => {
            if (currency === c) {
              return null;
            }
            return (
              <ListItem
                button
                key={c}
                disableRipple
                className={classes.menuItemText}
                onClick={() => {
                  handleCurrencyChange(c);
                }}
              >
                {c}
              </ListItem>
            );
          })}
        </MenuList>
      }
    />
  );

  const statMenu = (
    <CustomMenu
      open={!!statMenuAnchorEl}
      anchorEl={statMenuAnchorEl}
      onClose={closeStatMenu}
      overflowAuto
      maxHeight={500}
      content={
        <MenuList>
          <ListItem
            button
            disableRipple
            className={classes.menuItemText}
            onClick={() => {
              handleStatChange("product_name");
            }}
          >
            Product Name
          </ListItem>
          <ListItem
            button
            disableRipple
            className={classes.menuItemText}
            onClick={() => {
              handleStatChange("product_type");
            }}
          >
            Product Type
          </ListItem>
        </MenuList>
      }
    />
  );

  const timePeriodModal = (
    <TimePeriodModal
      past
      disableBtn={loading || localLoading}
      selectedRange={dateRange}
      onChange={handleTimePeriodChange}
    />
  );

  const currencySelector = (
    <ExpandButton
      size="small"
      variant="contained"
      disabled={loading || localLoading}
      buttonClasses={{label: classes.lightSelectorLabel}}
      className={clsx(classes.lightSelector, {
        disabled: loading || localLoading,
      })}
      label={currency}
      hideIcon={
        availableCurrencies.length < 2 && availableCurrencies[0] === currency
      }
      onClick={openCurrencyMenu}
    />
  );

  const statSelector = (
    <ExpandButton
      size="small"
      variant="contained"
      disabled={loading || localLoading}
      buttonClasses={{label: classes.lightSelectorLabel}}
      className={clsx(classes.lightSelector, {
        disabled: loading || localLoading,
      })}
      label={stat === "product_name" ? "Product Name" : "Product Type"}
      onClick={openStatMenu}
    />
  );

  return (
    <div
      className={clsx(classes.card, "padd", classes.flex1, {
        mobile: mobile,
      })}
      style={{minHeight: FIXED_SIZES.dashboard_revenue_card}}
    >
      {currencyMenu}
      {statMenu}
      <div
        className={clsx(classes.cardHeader, {
          [classes.row]: !mobile,
          [classes.col]: mobile,
        })}
      >
        <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
          <CurrencyText
            disableFixedDecimal={Number.isInteger(bigTotal)}
            value={bigTotal}
            customClass={classes.bigNumber}
            currency={currency}
            noSuffix
          />
          <Typography variant="h2" className={classes.cardTitle}>
            Total Revenue
          </Typography>
          <InfoText disableGutters text={infoTexts.totalRevenue} subdued />
          {!mobile && currencySelector}
          {!mobile && statSelector}
        </Box>
        {!mobile && timePeriodModal}
        {mobile && (
          <Box
            width="100%"
            className={classes.row}
            justifyContent="space-between"
            mt={2}
          >
            <Box className={clsx(classes.row, classes.gap8)}>
              {currencySelector}
              {statSelector}
            </Box>
            {timePeriodModal}
          </Box>
        )}
      </div>
      {loading || localLoading ? (
        <Box
          height="100%"
          className={clsx(classes.gap12, {
            [classes.row]: !mobile,
            [classes.col]: mobile,
          })}
          mt={2}
          minHeight={300}
        >
          <Skeleton
            animation="wave"
            width={mobile ? "100%" : "22%"}
            height={mobile ? 100 : 168}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            width={mobile ? "100%" : "22%"}
            height={mobile ? 100 : 168}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            width={mobile ? "100%" : "56%"}
            height={mobile ? 150 : 168}
            className={classes.skeleton}
          />
        </Box>
      ) : noData ||
        (!revenueData?.product_types?.length &&
          !revenueData?.fees_revenue &&
          !revenueData?.upsells_revenue) ? (
        <Box width="100%" height="100%" className={classes.emptyDataContent}>
          <Typography variant="h2" className={classes.noDataTitle}>
            No revenue data
          </Typography>
          <Typography className={classes.noDataInfo} align="center">
            In order to collect data about your revenue, you must have Upsells
            and Fees created and running on an active listing.
          </Typography>
          {!isMobile && (
            <PrimaryButton
              color="secondary"
              label="Create an Upsell"
              onClick={openUpsellGuide}
            />
          )}
        </Box>
      ) : (
        <Box
          mt={2}
          className={clsx(classes.gap12, classes.revenueContent, {
            [classes.row]: !mobile,
            [classes.col]: mobile,
            mobile: mobile,
          })}
        >
          {getBlueBox(revenueData?.upsells_revenue ?? 0)}
          {getBlueBox(revenueData?.fees_revenue ?? 0, true)}
          <Box
            flex={mobile ? "auto" : 1}
            maxHeight={170}
            height={60 + 37 * data.length}
          >
            <VirtualizedTableComponent
              columns={tableColumns}
              data={data}
              labelProps={{variant: "textSM"}}
              tableStyles={{border: "none"}}
              headerStyles={{backgroundColor: "transparent", height: "37px"}}
              totalRows={data.length}
              rowHeight={37}
              isRowLoaded={isRowLoaded}
              onClickRow={onRowClick}
              loadMoreRows={() => {}}
              defaultSortField="revenue"
              defaultSortDirection="DESC"
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default RevenueStatsCard;
