const _base_spacing_unit = 4;
// const _fontFamily = "-apple-system, BlinkMacSystemFont, sans-serif";
const _fontFamily = `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`;
const _typography = {
  displayXL: {fontSize: "60px", lineHeight: "72px", letterSpacing: "-0.02em"},
  displayLG: {fontSize: "48px", lineHeight: "60px", letterSpacing: "-0.02em"},
  displayMD: {fontSize: "36px", lineHeight: "44px", letterSpacing: "-0.02em"},
  displaySM: {fontSize: "30px", lineHeight: "38px"},
  displayXS: {fontSize: "24px", lineHeight: "32px"},
  textXL: {fontSize: "20px", lineHeight: "30px"},
  textLG: {fontSize: "18px", lineHeight: "28px"},
  textMD: {fontSize: "16px", lineHeight: "24px"},
  textSM: {fontSize: "14px", lineHeight: "20px"},
  textXS: {fontSize: "12px", lineHeight: "18px"},
  textXXS: {fontSize: "10px", lineHeight: "16px"},
};

const _createTypographyVariant = (baseStyles) => {
  return {
    regular: {...baseStyles, fontWeight: 400, fontFamily: _fontFamily},
    medium: {...baseStyles, fontWeight: 500, fontFamily: _fontFamily},
    semibold: {...baseStyles, fontWeight: 600, fontFamily: _fontFamily},
    bold: {...baseStyles, fontWeight: 700, fontFamily: _fontFamily},
  };
};

const _getSpacing = (...values) => {
  if (values.length === 0) {
    return "0px";
  }
  return values
    .slice(0, 4)
    .map((value) => `${value * _base_spacing_unit}px`)
    .join(" ");
};

const defaultTheme = {
  zIndex: {
    dropdown: 1000,
    modal: 1100,
    tooltip: 1200,
  },
  radius: {
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 10,
    xxl: 12,
  },
  breakpoints: {
    xs: 0,
    sm: 1024,
    md: 1440,
    lg: 1920,
  },
  spacing: (...val) => _getSpacing(...val),
  typography: {
    displayXL: _createTypographyVariant(_typography.displayXL),
    displayLG: _createTypographyVariant(_typography.displayLG),
    displayMD: _createTypographyVariant(_typography.displayMD),
    displaySM: _createTypographyVariant(_typography.displaySM),
    displayXS: _createTypographyVariant(_typography.displayXS),
    textXL: _createTypographyVariant(_typography.textXL),
    textLG: _createTypographyVariant(_typography.textLG),
    textMD: _createTypographyVariant(_typography.textMD),
    textSM: _createTypographyVariant(_typography.textSM),
    textXS: _createTypographyVariant(_typography.textXS),
    textXXS: _createTypographyVariant(_typography.textXXS),
  },
  shadows: {
    default: "0px 1px 2px 0px #0A0D120D",
    xs: "0px 1px 2px 0px #0A0D120F",
    sm: "0px 1px 3px 0px #0A0D121A",
    md: "0px 4px 8px -2px #0A0D121A, 0px 2px 4px -2px #0A0D120F",
    xl: "0px 20px 24px -4px #0A0D1214, 0px 8px 8px -4px #0A0D1208",
  },
  colors: {
    // General
    white: "#ffffff",
    black: "#000000",
    border: "#e9eaeb",
    divider: "#e9eaeb",
    textPrimary: "#181d27",
    textSecondary: "#717680",
    textTertiary: "#a4a7ae",
    hoverGray: "#f5f5f5",
    inputBG: "#fafafa",
    // Keywords
    inquiry: "purple",
    pending: "bluegray",
    confirmed: "success",
    declined: "error",
    checked_in: "primary",
    checked_out: "orange",
    cancelled: "warning",
    expired_guest: "indigo",
    expired_host: "rose",
    pending_guest: "bluelight",
    // Gray
    gray: "#717680",
    gray25: "#fdfdfd",
    gray50: "#fafafa",
    gray100: "#f5f5f5",
    gray200: "#e9eaeb",
    gray300: "#d5d7da",
    gray400: "#a4a7ae",
    gray500: "#717680",
    gray600: "#535862",
    gray700: "#414651",
    gray800: "#252b37",
    gray900: "#181d27",
    // Primary
    primary: "#2f7afb",
    primary25: "#f7faff",
    primary50: "#e3edff",
    primary100: "#cfe0fe",
    primary200: "#a7c7fd",
    primary300: "#7fadfd",
    primary400: "#5794fc",
    primary500: "#2f7afb",
    primary600: "#2669db",
    primary700: "#1d57bb",
    primary800: "#14469c",
    primary900: "#0b347c",
    // Error
    error: "#f04438",
    error25: "#fffbfa",
    error50: "#fef3f2",
    error100: "#fee4e2",
    error200: "#fecdca",
    error300: "#fda29b",
    error400: "#f97066",
    error500: "#f04438",
    error600: "#d92d20",
    error700: "#b42318",
    error800: "#912018",
    error900: "#7a271a",
    // Warning
    warning: "#f79009",
    warning25: "#fffcf5",
    warning50: "#fffaeb",
    warning100: "#fef0c7",
    warning200: "#fedf89",
    warning300: "#fec84b",
    warning400: "#fdb022",
    warning500: "#f79009",
    warning600: "#dc6803",
    warning700: "#b54708",
    warning800: "#93370d",
    warning900: "#7a2e0e",
    // Success
    success: "#12b76a",
    success25: "#f6fef9",
    success50: "#ecfdf3",
    success100: "#d1fadf",
    success200: "#a6f4c5",
    success300: "#6ce9a6",
    success400: "#32d583",
    success500: "#12b76a",
    success600: "#039855",
    success700: "#027a48",
    success800: "#05603a",
    success900: "#054f31",
    // Blue
    blue: "",
    blue25: "#f5faff",
    blue50: "#eff8ff",
    blue100: "#d1e9ff",
    blue200: "#b2ddff",
    blue300: "#84caff",
    blue400: "#53b1fd",
    blue500: "#2e90fa",
    blue600: "#1570ef",
    blue700: "#175cd3",
    blue800: "#1849a9",
    blue900: "#194185",
    // Bluegray
    bluegray: "#4e5ba6",
    bluegray25: "#fcfcfd",
    bluegray50: "#f8f9fc",
    bluegray100: "#eaecf5",
    bluegray200: "#d5d9eb",
    bluegray300: "#afb5d9",
    bluegray400: "#717bbc",
    bluegray500: "#4e5ba6",
    bluegray600: "#3e4784",
    bluegray700: "#363f72",
    bluegray800: "#293056",
    bluegray900: "#101323",
    // Bluelight
    bluelight: "#0ba5ec",
    bluelight25: "#f5fbff",
    bluelight50: "#f0f9ff",
    bluelight100: "#e0f2fe",
    bluelight200: "#b9e6fe",
    bluelight300: "#7cd4fd",
    bluelight400: "#36bffa",
    bluelight500: "#0ba5ec",
    bluelight600: "#0086c9",
    bluelight700: "#026aa2",
    bluelight800: "#065986",
    bluelight900: "#0b4a6f",
    // Indigo
    indigo: "#6172f3",
    indigo25: "#f5f8ff",
    indigo50: "#eef4ff",
    indigo100: "#e0eaff",
    indigo200: "#c7d7fe",
    indigo300: "#a4bcfd",
    indigo400: "#8098f9",
    indigo500: "#6172f3",
    indigo600: "#444ce7",
    indigo700: "#3538cd",
    indigo800: "#2d31a6",
    indigo900: "#2d3282",
    // Purple
    purple: "#7a5af8",
    purple25: "#fafaff",
    purple50: "#f4f3ff",
    purple100: "#ebe9fe",
    purple200: "#d9d6fe",
    purple300: "#bdb4fe",
    purple400: "#9b8afb",
    purple500: "#7a5af8",
    purple600: "#6938ef",
    purple700: "#5925dc",
    purple800: "#4a1fb8",
    purple900: "#3e1c96",
    // Pink
    pink: "#ee46bc",
    pink25: "#fef6fb",
    pink50: "#fdf2fa",
    pink100: "#fce7f6",
    pink200: "#fcceee",
    pink300: "#faa7e0",
    pink400: "#f670c7",
    pink500: "#ee46bc",
    pink600: "#dd2590",
    pink700: "#c11574",
    pink800: "#9e165f",
    pink900: "#851651",
    // Rosé
    rose: "#f63d68",
    rose25: "#fff5f6",
    rose50: "#fff1f3",
    rose100: "#ffe4e8",
    rose200: "#fecdd6",
    rose300: "#fea3b4",
    rose400: "#fd6f8e",
    rose500: "#f63d68",
    rose600: "#e31b54",
    rose700: "#c01048",
    rose800: "#a11043",
    rose900: "#89123e",
    // Orange
    orange: "#fb6514",
    orange25: "#fffaf5",
    orange50: "#fff6ed",
    orange100: "#ffead5",
    orange200: "#fddcab",
    orange300: "#feb273",
    orange400: "#fd853a",
    orange500: "#fb6514",
    orange600: "#ec4a0a",
    orange700: "#c4320a",
    orange800: "#9c2a10",
    orange900: "#7e2410",
  },
};

export default defaultTheme;
