import React from "react";
import ModalBase from "./ModalBase";
import ButtonBase from "../input/button/base/ButtonBase";
import Text from "../display/Text";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import ButtonText from "../input/button/ButtonText";
import OutlinedButtonBase from "../input/button/base/OutlinedButtonBase";
import FlexContainer from "../layout/FlexContainer";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    actionsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: theme.spacing(6),
    },
    actionButton: {
      flex: 1,
      margin: `0 ${theme.spacing(1)}px`,
    },
  });

/**
 * ConfirmModal is a component that displays a modal with a confirmation message and custom actions.
 * It provides default "Cancel" and "Confirm" buttons, but custom actions can also be passed via the `actions` prop.
 *
 * @param {Object} props - The props for the ConfirmModal component.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Function} props.onClose - The function to call when the modal is closed.
 * @param {Function} props.onConfirm - The function to call when the "Confirm" button is clicked.
 * @param {string} [props.title="Confirm Action"] - The title of the modal.
 * @param {string} [props.description="Are you sure you want to proceed? This action cannot be undone."] - The description to display in the modal.
 * @param {React.ReactNode} [props.icon] - The optional icon to display in the modal.
 * @param {React.ReactNode} [props.actions=null] - The custom actions to display inside the modal (defaults to "Cancel" and "Confirm" buttons).
 *
 * @returns {JSX.Element} The rendered ConfirmModal component.
 */
const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Confirm Action",
  description = "Are you sure you want to proceed? This action cannot be undone.",
  icon,
  actions = null, // Pass custom actions; uses defaults if null
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  // Default actions if none provided
  const defaultActions = (
    <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
      <ButtonText
        sx={classes.actionButton}
        buttonBase={OutlinedButtonBase}
        label="Cancel"
        onClick={onClose}
      />
      <ButtonText
        sx={classes.actionButton}
        buttonBase={ButtonBase}
        label="Confirm"
        onClick={onConfirm}
      />
    </FlexContainer>
  );

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
        gap={theme.spacing(2)}
        sx={{padding: theme.spacing(2), maxWidth: "420px"}}
      >
        {icon && icon}
        <Text
          variant="textLG"
          weight="semibold"
          sx={{marginTop: theme.spacing(1)}}
        >
          {title}
        </Text>
        <Text variant="textSM" color="gray600">
          {description}
        </Text>
        <div className={classes.actionsWrapper}>
          {actions || defaultActions}
        </div>
      </FlexContainer>
    </ModalBase>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  actions: PropTypes.node,
};

export default ConfirmModal;
