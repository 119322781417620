import React, {useState} from "react";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Container from "../../../../connectui/component/layout/Container";
import ConnectAccountPanel from "./ConnectAccountPanel";
import PhoneConnectPanel from "./PhoneConnectPanel";
import DisplayedPhonePanel from "./DisplayedPhonePanel";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import {ReactComponent as ArrowLeftIcon} from "../../../../connectui/icons/arrow-left.svg";
import ButtonIconText from "../../../../connectui/component/input/button/ButtonIconText";
import EmptyButtonBase from "../../../../connectui/component/input/button/base/EmptyButtonBase";
import NumberConnectedPanel from "./NumberConnectedPanel";
import MessagingFeaturesPanel from "./MessagingFeaturesPanel";

const useStyles = (theme) =>
  buildStyles({
    outerContainer: {
      backgroundColor: theme.colors.white,
      width: "100%",
      height: "100vh",
      filter: "drop-shadow(0px 1px 2px rgba(10, 13, 18, 0.05))",
      display: "flex",
      flexDirection: "row",
    },
    contentContainer: {
      padding: theme.spacing(5),
      overflow: "auto",
    },
    backButtonContainer: {
      position: "absolute",
      top: theme.spacing(8),
      left: "250px",
    },
    backIcon: {
      width: "24px",
      height: "24px",
      color: theme.colors.textTertiary,
      paddingRight: theme.spacing(3),
    },
  });

export default function BaseAccountPanel() {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const [stageId, setStageId] = useState(0);

  let stages = [
    {id: 0, component: ConnectAccountPanel},
    {id: 1, component: PhoneConnectPanel, backToStageId: 0},
    {id: 2, component: DisplayedPhonePanel, backToStageId: 1},
    {id: 3, component: NumberConnectedPanel, backToStageId: 2},
    {id: 4, component: MessagingFeaturesPanel, backToStageId: 0},
  ];

  const currentStage = stages.find((stage) => stage.id === stageId);

  return (
    <Container className={classes.outerContainer}>
      <Container className={classes.contentContainer}>
        {currentStage?.backToStageId !== undefined && (
          <Container className={classes.backButtonContainer}>
            <ButtonIconText
              onClick={() => setStageId(currentStage.backToStageId)}
              typographyVariant="textMD"
              color={"textTertiary"}
              buttonBase={EmptyButtonBase}
              weight="semibold"
              icon={
                <SvgIcon
                  component={ArrowLeftIcon}
                  className={classes.backIcon}
                />
              }
              label="Back"
            />
          </Container>
        )}
      </Container>

      <FlexContainer
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={stageId === 0 ? "flex-start" : "center"}
        sx={{width: "100%", overflow: "auto"}}
      >
        {stages.map((stage) => {
          if (stage.id === stageId) {
            const Component = stage.component;
            return <Component key={stage.id} setStageId={setStageId} />;
          }
        })}
      </FlexContainer>
    </Container>
  );
}
