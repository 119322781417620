import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import GenericSwitchInput from "../../../base/input/GenericSwitchInput";

const CheckBoxField = ({fieldData, handleValueChange, options, ...props}) => {
  const renderInput = () => (
    <GenericSwitchInput
      value={fieldData.val}
      edit={true}
      onValueChange={(e) => {
        handleValueChange(fieldData.type, e);
      }}
    />
  );

  const getOperators = () => ["is"];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      renderFilter={renderInput}
      getOperators={getOperators}
      {...props}
    />
  );
};

CheckBoxField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default CheckBoxField;
