import React, {useContext} from "react";
import {InboxContext} from "routes/MessagesPage";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import Text from "../../../../connectui/component/display/Text";
import ChipBase from "connectui/component/display/chip/base/ChipBase";
import XChipBase from "../../../../connectui/component/display/chip/base/XChipBase";
import Tooltip from "connectui/component/display/Tooltip";
import FieldComponentProvider from "ui/component/field/FieldComponentProvider";

export default function FilterListChip({filter, value, hoverChips, ...props}) {
  const {theme} = useTheme();
  const {customFields} = useContext(InboxContext);

  return (
    <Tooltip tooltipContent={hoverChips} sx={{padding: theme.spacing(2)}}>
      <ChipText
        text={
          <Text
            variant="textSM"
            weight="medium"
            color="blue700"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {!value ? (
              "None"
            ) : `${value}`.startsWith("+") ? (
              value
            ) : (
              <FieldComponentProvider
                customFieldData={customFields}
                fieldInfo={{field: filter.path}}
              >
                {({component}) =>
                  component({
                    type: "label",
                    isFilter: true,
                    fieldData: {
                      field: filter.path,
                      operators: filter.operators,
                      val: value,
                    },
                    handleValueChange: () => {},
                  })
                }
              </FieldComponentProvider>
            )}
          </Text>
        }
        chipBase={hoverChips ? ChipBase : XChipBase}
        sx={{
          cursor: "pointer",
          color: theme.colors.blue700,
          backgroundColor: theme.colors.blue50,
          textWrap: "auto",
          "&:hover": {backgroundColor: theme.colors.blue100},
        }}
        {...props}
      />
    </Tooltip>
  );
}
