import {API} from "aws-amplify";

export const postAccount = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/accounts", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchAccount = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/accounts", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postRootBranding = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/accounts/root/branding", {
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBranding = async (brand_id) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/accounts/branding?brand_id=${brand_id}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postBranding = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/accounts/branding", {
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchBranding = async (body) => {
  try {
    const response = await API.patch("ensoAPI", `/accounts/branding`, {
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteBranding = async (brand_id) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/accounts/branding?brand_id=${encodeURIComponent(brand_id)}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const delRootAccount = async (enso_key) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/accounts/root?enso_key=${enso_key}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const signUp = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/signup", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const signUpChannel = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/signup/channel", body);
    return response;
  } catch (error) {
    if (error.response?.data?.error?.[0]) {
      const serverError = error.response.data.error[0];
      const enhancedError = new Error(serverError.message);
      enhancedError.severity = serverError.severity;
      enhancedError.properties = serverError.properties;
      throw enhancedError;
    }
    throw error;
  }
};

export const signUpUrl = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/signup/url", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postManagedEmail = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/services/email", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postManagedPhone = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/services/phone", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteManagedEmail = async (body) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/services/email?managed_email=${body}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getManagedEmailSetupStatus = async (body) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/services/email/setup/status?managed_email=${body.managed_email}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const postManagedEmailSetup = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/services/email/setup", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteManagedPhone = async (body) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/services/phone?phone=${encodeURIComponent(body)}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchAvailablePhoneNumbers = async (params = "") => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/services/phone/purchase/search?${params}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const purchasePhoneNumber = async (body) => {
  try {
    const response = await API.post(
      "ensoAPI",
      "/services/phone/purchase",
      body,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getManagedEmailByEnsoEmail = async (enso_email) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/managedemail?enso_email=${enso_email}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getManagedEmailByManagedEmail = async (managed_email) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/managedemail?managed_email=${managed_email}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// CHANNELS
export const getChannels = async (params) => {
  try {
    let response = await API.get("ensoAPI", `/accounts/channels?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postAccountsSync = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/accounts/sync", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAccount = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/accounts?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

// Billing
export const getBilling = async (params) => {
  try {
    let response = await API.get("ensoAPI", `/billing`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postBilling = async (body) => {
  try {
    const response = await API.post("ensoAPI", `/billing`, {body: body});
    console.log("GOT BILLING POST RESPONSE", response);
    return response;
  } catch (error) {
    console.log("GOT BILLING POST ERROR", error);
    throw error;
  }
};

export const getSubscription = async (subscriptionID) => {
  try {
    const response = await API.get("ensoAPI", `/billing/${subscriptionID}`);
    return response;
  } catch (err) {
    throw err;
  }
};

// LINKS
export const getLink = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/links${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};
