import {fieldParams} from "../../../configuration/specs";
import React from "react";
import {defaultFieldMap} from "../../../configuration/constants";

/**
 * @author Chloe Hill
 * @param fieldInfo
 * @param customFieldData
 * @param children: {optionData: [], customFieldData: {}, operatorType: string, componentMap: {}} => JSX.Element
 * @name FieldComponentProvider
 * @description Provides the necessary data for options, custom component mappings and field operator types.
 * @usage Universal component for handling fields anywhere in the platform.
 */
export default function FieldComponentProvider({
  fieldInfo,
  customFieldData,
  children,
}) {
  /**
   * @name getOptionData
   * @description Generates the option data and filtered fields for the given fieldinfo, pulling from custom fields and hardcoded enum values.
   * @returns {*[]}
   */
  function getOptionData() {
    let optionData = [];
    let field = fieldParams[fieldInfo.field];

    if (field?.options) {
      optionData = field?.options;
    }

    let filteredField;

    if (customFieldData) {
      let i = 0,
        len = Object.keys(customFieldData)?.length;
      for (; i <= len; i++) {
        filteredField = customFieldData[Object.keys(customFieldData)[i]]?.find(
          (f) => {
            return f.field === fieldInfo.field;
          },
        );
        if (!!filteredField) {
          break;
        }
      }
    }

    if (filteredField && filteredField.data) {
      optionData = [
        ...new Set([
          ...optionData,
          ...filteredField?.data?.filter((f) => f !== ""),
        ]),
      ];
    }

    return optionData;
  }

  /**
   * @name getComponentMap
   * @description Loads and maps custom field components, returning a map of possible components.
   * @example const CustomComponent = componentMap["MyCustomComponent"];
   */
  function getComponentMap() {
    if (process.env.NODE_ENV === "development") {
      // Use require.context dynamically during development
      const componentContext = require.context(
        "../field/fields",
        false,
        /\.jsx?$/,
      );
      return componentContext.keys().reduce((map, filePath) => {
        const componentName = filePath
          .replace("./", "")
          .replace(/\.(js|jsx)$/, "");
        map[componentName] = componentContext(filePath).default;
        return map;
      }, {});
    } else {
      // Use pre-generated index.js in production
      return require(".//fields").default || require(".//fields");
    }
  }

  function getComponent({handleValueChange, ...props}) {
    let customComponentName;

    customComponentName =
      defaultFieldMap[fieldParams[fieldInfo.field]?.field_type];

    let boardingPassFields;

    if (customFieldData) {
      boardingPassFields = customFieldData[
        "Boarding Pass Collected Fields"
      ]?.find((f) => f.field === fieldInfo.field);
    }

    if (!customComponentName) {
      customComponentName =
        fieldParams[fieldInfo.field]?.field_type ||
        defaultFieldMap[boardingPassFields?.type] ||
        "TextField";
    }

    if (!customComponentName) return null;

    const CustomComponent = getComponentMap()[customComponentName];
    if (!CustomComponent) return null;

    let optionData = getOptionData();

    if (typeof CustomComponent === "function") {
      const instance = CustomComponent({
        fieldData: fieldInfo,
        options: optionData,
        customFieldData: customFieldData,
        handleValueChange,
        ...props,
      });

      if (props.type === "operators" && instance?.props?.getOperators) {
        return instance.props.getOperators();
      } else if (props.type === "label" && instance?.props?.getLabel) {
        return instance.props.getLabel();
      } else {
        return (
          <CustomComponent
            fieldData={fieldInfo}
            options={optionData}
            customFieldData={customFieldData}
            handleValueChange={(type, newValue) => {
              handleValueChange(type, newValue);
            }}
            {...props}
          />
        );
      }
    }
  }

  return children({
    optionData: getOptionData,
    customFieldData,
    component: getComponent,
    componentMap: getComponentMap,
    fieldInfo,
  });
}
