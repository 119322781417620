import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {getInboxData, updateGuest} from "redux/actions/guestsActions";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ThreadCardComponent from "./ThreadCardComponent";
import FlexContainer from "connectui/component/layout/FlexContainer";
import Skeleton from "connectui/component/display/Skeleton";
import ContextMenu from "connectui/component/input/context/ContextMenu";
import ContextMenuItem from "connectui/component/input/context/ContextMenuItem";
import Text from "connectui/component/display/Text";
import Divider from "connectui/component/display/Divider";
import ListComponent from "connectui/component/layout/list/ListComponent";
import VirtualizedListComponent from "connectui/component/layout/list/VirtualizedListComponent";

const useStyles = (theme) =>
  buildStyles({
    list: {"&::-webkit-scrollbar": {width: "0!important"}},
  });

const ThreadList = ({
  data,
  totalRows,
  removeGuestFromList,
  skeleton = {enabled: false},
  onGuestClick,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const {guest_id} = useParams();
  const dispatch = useDispatch();
  const guestClicked = useRef(null);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const [anchorEl, setAnchorEl] = useState(null);

  const editGuest = (guest_id, field, val) => {
    setAnchorEl(null);
    dispatch(
      updateGuest({
        guest_id,
        field: field,
        val: val,
        disableAlert: true,
        onSuccess: () => dispatch(getInboxData()),
      }),
    );
  };

  const toggleArchive = (guest) => {
    let newTags = [...(guest.tags ?? [])];
    if (guest?.tags?.includes("archived")) {
      newTags = newTags.filter((t) => t !== "archived");
    } else {
      newTags.push("archived");
    }
    editGuest(guest.guest_id, "tags", newTags);
    removeGuestFromList(guest);
  };

  const handleGuestClick = (guest) => {
    guestClicked.current = guest;

    if (!!guest.unread_messages) {
      editGuest(guest.guest_id, "unread_messages", false);
    }

    if (guest.guest_id) {
      history.push(`/admin/messages/${guest.guest_id}`);
    }
    onGuestClick && onGuestClick();
  };

  return (
    <>
      {!!anchorEl && (
        <ContextMenu
          open
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          menuItems={[
            {
              label: !!guestClicked.current?.unread_messages
                ? "Mark as read"
                : "Mark as unread",
              onClick: () =>
                editGuest(
                  guestClicked.current?.guest_id,
                  "unread_messages",
                  !guestClicked.current?.unread_messages,
                ),
            },
            {
              label: !!guestClicked.current?.unanswered_messages
                ? "Mark as answered"
                : "Mark as unanswered",
              onClick: () =>
                editGuest(
                  guestClicked.current?.guest_id,
                  "unanswered_messages",
                  !guestClicked.current?.unanswered_messages,
                ),
            },
            {Component: () => <Divider sx={{margin: theme.spacing(2, 0)}} />},
            guestClicked.current?.guest_type !== "guest"
              ? {
                  label: "Switch to Guest",
                  onClick: () =>
                    editGuest(
                      guestClicked.current?.guest_id,
                      "guest_type",
                      "guest",
                    ),
                }
              : null,
            guestClicked.current?.guest_type !== "owner"
              ? {
                  label: "Switch to Owner",
                  onClick: () =>
                    editGuest(
                      guestClicked.current?.guest_id,
                      "guest_type",
                      "owner",
                    ),
                }
              : null,
            guestClicked.current?.guest_type !== "maintenance"
              ? {
                  label: "Switch to Maintenance",
                  onClick: () =>
                    editGuest(
                      guestClicked.current?.guest_id,
                      "guest_type",
                      "maintenance",
                    ),
                }
              : null,
            guestClicked.current?.guest_type !== "cleaner"
              ? {
                  label: "Switch to Cleaner",
                  onClick: () =>
                    editGuest(
                      guestClicked.current?.guest_id,
                      "guest_type",
                      "cleaner",
                    ),
                }
              : null,
            {Component: () => <Divider sx={{margin: theme.spacing(2, 0)}} />},
            {
              Component: ({label}) => (
                <ContextMenuItem
                  label={
                    <Text weight="medium" color="error500">
                      {label}
                    </Text>
                  }
                  onClick={() => toggleArchive(guestClicked.current)}
                />
              ),
              label: guestClicked.current?.tags?.includes("archived")
                ? "Unarchive conversation"
                : "Archive conversation",
            },
          ]}
        />
      )}
      {skeleton.enabled ? (
        <ListComponent gap={"0px"} width={"100%"}>
          {Array.from({length: 10}).map((_, index) => (
            <ThreadCardComponent
              key={index}
              skeleton={{
                enabled: true,
                width: "100%",
                height: "100px",
              }}
            />
          ))}
        </ListComponent>
      ) : (
        <VirtualizedListComponent
          totalRows={totalRows}
          rowHeight={100}
          onScroll={() => setAnchorEl(null)}
          className={classes.list}
          {...props}
        >
          {(index) => {
            const guest = guests[data[index]?.guest_id] ?? data[index];
            return (
              <ThreadCardComponent
                guest={guest}
                selected={guest?.guest_id === guest_id}
                openMenu={(e) => {
                  e.stopPropagation();
                  guestClicked.current = guest;
                  setAnchorEl(e.currentTarget);
                }}
                onClick={() => handleGuestClick(guest)}
                skeleton={{
                  enabled: index >= data.length,
                  width: "100%",
                  height: "100px",
                }}
              />
            );
          }}
        </VirtualizedListComponent>
      )}
    </>
  );
};

export default ThreadList;
