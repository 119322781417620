import React from "react";
import Container from "../layout/Container";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    checkboxWrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing.sm,
      cursor: "pointer",
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "20px",
      height: "20px",
      border: `2px solid ${theme.colors.gray300}`,
      borderRadius: "4px",
      backgroundColor: theme.colors.white,
      transition: "background-color 0.2s, border-color 0.2s",
    },
    checkboxContainerChecked: {
      backgroundColor: theme.colors.primary500,
      borderColor: theme.colors.primary500,
    },
    checkmark: {
      width: "10px",
      height: "10px",
      backgroundColor: theme.colors.white,
      borderRadius: "2px",
    },
    hiddenInput: {
      position: "absolute",
      opacity: 0,
      pointerEvents: "none",
    },
    label: {
      color: theme.colors.textPrimary,
      marginLeft: theme.spacing.sm,
    },
  });

/**
 * CheckBox is a component that renders a customizable checkbox with a label.
 * It allows handling changes and can display a checked state with custom styles.
 *
 * @param {Object} props - The props for the CheckBox component.
 * @param {boolean} [props.isChecked=false] - The checked state of the checkbox.
 * @param {Function} [props.onChange] - The function to call when the checkbox state changes.
 * @param {string} [props.label] - The label to display next to the checkbox.
 *
 * @returns {JSX.Element} The rendered CheckBox component.
 */
const CheckBox = ({isChecked = false, onChange, label}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const handleChange = () => {
    onChange && onChange(!isChecked);
  };

  return (
    <Container as="label" className={classes.checkboxWrapper}>
      <Container
        as="input"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        className={classes.hiddenInput}
      />
      <Container
        as="div"
        className={`${classes.checkboxContainer} ${
          isChecked ? classes.checkboxContainerChecked : ""
        }`}
      >
        {isChecked && <Container as="div" className={classes.checkmark} />}
      </Container>
      {label && (
        <Container as="span" className={classes.label}>
          {label}
        </Container>
      )}
    </Container>
  );
};

CheckBox.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default CheckBox;
