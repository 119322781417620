import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import DateComponent from "../../../../components/TextFields/Date";
import DateRangePicker from "connectui/component/input/DateRangePicker";

function renderInput({fieldData, handleValueChange}) {
  return (
    <DateComponent
      date={new Date()}
      showInfoText
      value={fieldData.val}
      edit={true}
      onValueChange={(newValue) => {
        handleValueChange(fieldData.type, newValue);
      }}
    />
  );
}

function renderFilter({fieldData, handleValueChange}) {
  return (
    <DateRangePicker
      controlledDateRange={
        !!fieldData.val?.length
          ? [new Date(fieldData.val[0]), new Date(fieldData.val[1])]
          : []
      }
      onChange={(newDate) =>
        handleValueChange("custom", [
          {path: fieldData.field, operator: ">", value: newDate[0].getTime()},
          {path: fieldData.field, operator: "<", value: newDate[1].getTime()},
        ])
      }
    />
  );
}

function getOperators() {
  return ["is_greater_than", "is_less_than"];
}

function getLabel(fieldData) {
  return getDefaultFieldLabel(fieldData?.val, fieldData);
}

const DateField = ({fieldData, handleValueChange, options, ...props}) => {
  return (
    <BaseFieldComponent
      type="input"
      renderInput={() => renderInput({fieldData, handleValueChange})}
      renderFilter={() => renderFilter({fieldData, handleValueChange})}
      getLabel={() => getLabel(fieldData)}
      getOperators={getOperators}
      {...props}
    />
  );
};

DateField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DateField;
