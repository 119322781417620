import React, {useState} from "react";
import {postServiceStripe} from "redux/api/servicesAPI.js";
import {useSelector, useDispatch} from "react-redux";
// UI
import {
  ListItem,
  Typography,
  CardContent,
  Card,
  Chip,
  Modal,
  Divider,
  Button,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
//Custom
import PrimaryButton from "core/buttons/PrimaryButton.jsx";
import VirtualizedList from "components/Lists/VirtualizedList";
// Utilities
import {relativeTime} from "utilities/helperFunctions.js";
import {FIXED_SIZES} from "configuration/settings";
import _ from "lodash";
import {serviceProviderMap} from "configuration/enums";
import FullLoader from "components/Dialogs/FullLoader";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 50,
    padding: 10,
  },
  item: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    height: 250,
  },
  root: {
    padding: theme.spacing(4),
  },
}));

export default function ServiceAccountCard({
  account,
  setAccount,
  channels,
  initialOpen,
  selectedChannel,
  setSelectedChannel,
  triggerRefresh,
}) {
  const classes = useStyles();
  // Selectors
  const redux_loading = useSelector(
    (state) => state.defaultReducer.loading,
  ).connected_channel_accounts;
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showAccounts, setShowAccounts] = useState(initialOpen);
  const {t} = useTranslation();
  const selectedListingGroups = useSelector(
    (state) => state.defaultReducer.selected_listing_groups,
  );

  let channelType = "device";
  if (channels?.[0]?.service == "msg") {
    channelType = "template";
  }

  const handleLoadingDialogClose = () => setLoading((prev) => false);

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText="Redirecting..."
    />
  );

  const channelProp = channelType + "s";

  function handleConnectStripe(newAccount = null) {
    if (!account && newAccount === null) {
      setModalOpen(true);
      return;
    }
    setLoading((prev) => true);
    postServiceStripe({
      new_account: newAccount || false,
      service_id: account?.service_id,
    }).then((r) => {
      if (r.success) {
        setAccount(r.service_account);
      } else {
        window.location.replace(r.url);
      }
    });
  }

  function getChannelItem({index}) {
    const channel = channels[index];
    const channelListingGroups =
      channel.integration_service_id in selectedListingGroups
        ? selectedListingGroups[channel.integration_service_id]
        : channel.group_ids;

    return (
      <ListItem
        disableRipple
        divider
        dense
        className={classes.item}
        button
        ContainerComponent="div"
        selected={selectedChannel.id === channel.id}
        onClick={() => setSelectedChannel(channel)}
      >
        <div style={{width: "100%"}}>
          <div>
            {channel.provider === "stripe" &&
            (channel.connection_type === "all" ||
              channel.sort_id === "py#ALL" ||
              channel.sort_id === "py#all") ? (
              <Typography variant="h1" style={{color: "#000"}}>
                {channel.integration_service_id}{" "}
                <Button
                  variant="contained"
                  className="ml-3"
                  style={{
                    borderRadius: "5px",
                    background: "#DCE5EC",
                    color: "#275588",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "1rem",
                    maxHeight: "1.5rem",
                  }}
                >
                  {t("stripe-account-default-chip")}
                </Button>
              </Typography>
            ) : (
              <Typography variant="h1" style={{color: "#000"}}>
                {channel.integration_service_id}
                {channelListingGroups?.length < 1 ? (
                  <Button
                    variant="contained"
                    className="ml-3"
                    style={{
                      borderRadius: "5px",
                      background: "#ffb8b8",
                      color: "#882727",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    {t("stripe-account-disabled-chip")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="ml-3"
                    style={{
                      borderRadius: "5px",
                      background: "#9AD4D6",
                      color: "#229296",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    {t("branding-account-brand-count").replace(
                      "{count}",
                      channelListingGroups?.length,
                    )}
                  </Button>
                )}
              </Typography>
            )}
          </div>
          <div style={{display: "flex", width: "100%"}}>
            {channel[channelProp]?.length > 0 && (
              <div style={{flexGrow: 1}}>
                <Typography
                  style={{color: "#707070"}}
                >{`${channel[channelProp]?.length || 0} ${channelProp} Connected`}</Typography>
              </div>
            )}
            {channel.sync_data?.length > 0 && (
              <Typography
                style={{
                  color: "#707070",
                  opacity: 0.5,
                }}
              >
                Updated {relativeTime(channel.sync_data[0].start)} ago
              </Typography>
            )}
          </div>
        </div>
      </ListItem>
    );
  }

  const channelAccountsList = React.useMemo(() => {
    if (!channels?.length) {
      return null;
    }
    return (
      <>
        <Divider style={{marginTop: "10px", marginBottom: "5px"}} />
        <VirtualizedList
          // hideScrollbar
          getRowItem={getChannelItem}
          rowHeight={FIXED_SIZES.connected_channel_accounts}
          totalRows={channels.length}
        />
      </>
    );
  }, [channels, redux_loading, selectedChannel, triggerRefresh]);
  if (!channels) {
    return "";
  }

  const multipleChannels = channels.length > 1;

  return (
    <>
      {loadingModal}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            padding: 20,
            position: "absolute",
            width: 400,
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h2">
            {t("stripe-already-have-account")}
          </Typography>
          <div style={{marginTop: 20}}>
            <PrimaryButton
              style={{marginRight: 5}}
              label={"Yes"}
              onClick={() => {
                setModalOpen(false);
                handleConnectStripe(false);
              }}
            />
            <PrimaryButton
              label={"No"}
              onClick={() => {
                setModalOpen(false);
                handleConnectStripe(true);
              }}
            />
          </div>
        </div>
      </Modal>
      <Card style={{marginTop: 10}}>
        <CardContent>
          <Typography variant="h2">
            <img
              src={serviceProviderMap[channels[0]?.provider]?.icon}
              className={classes.icon}
            />
            {channels[0].provider == "whatsapp" && (
              <Chip color="primary" label={"BETA"} />
            )}
          </Typography>
          <Typography>
            Your {serviceProviderMap[channels[0]?.provider]?.title} account is
            connected and recieving notifications and updates.
          </Typography>
          <div style={{display: "flex", marginTop: 15}}>
            <div style={{flex: 1}} />
            {/* only show this button if it is stripe account */}
            {channels[0].provider === "stripe" && (
              <PrimaryButton
                size="small"
                className="mr-3"
                onClick={() => handleConnectStripe()}
                label={t("connect-new-account-stripe")}
              />
            )}
            <PrimaryButton
              size="small"
              variant="text"
              color="primary"
              onClick={() => {
                if (multipleChannels) {
                  setShowAccounts(!showAccounts);
                } else {
                  setSelectedChannel(channels[0]);
                }
              }}
              label={`${showAccounts ? "Hide" : "View"} ${multipleChannels ? `${channels.length} Accounts` : "Account"}`}
            />
          </div>
          {showAccounts && (
            <div className={classes.content}>{channelAccountsList}</div>
          )}
        </CardContent>
      </Card>
    </>
  );
}
