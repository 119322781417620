import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../../../../../connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../../connectui/component/buildStyles";
import Text from "../../../../../connectui/component/display/Text";
import DropdownExpand from "../../../../../connectui/component/display/dropdown/DropdownExpand";
import FlexContainer from "../../../../../connectui/component/layout/FlexContainer";
import {ReactComponent as Calendar} from "connectui/icons/calendar.svg";
import {ReactComponent as User} from "connectui/icons/user.svg";
import {ReactComponent as Email} from "connectui/icons/trash.svg";
import {ReactComponent as Phone} from "connectui/icons/eye.svg";
import {ReactComponent as AI} from "connectui/icons/cloud-rain.svg";
import Divider from "../../../../../connectui/component/display/Divider";
import Container from "../../../../../connectui/component/layout/Container";
import GuestField from "../../guestdetails/GuestDetailsDisplayField";
import GuestDetailsEditableValue from "../../guestdetails/GuestDetailsEditableValue";
import DropDownSelect from "../../../../../connectui/component/input/dropdown/DropDownSelect";
import ProfilePicture from "../../../../../connectui/component/display/ProfilePicture";
import {getGuestContactInfo} from "../../../../../utilities/helperFunctions";
import {getGuest, updateGuest as updGuest} from "redux/actions/guestsActions";
import usePrevious from "../../../../../hooks/usePrevious";
import TextInput from "../../../../../connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "../../../../../connectui/component/input/text/OutlinedInputBase";
import ChipDropDownBase from "../../../../../connectui/component/input/dropdown/base/ChipDropDownBase";
import {languageEnum} from "../../../../../configuration/enums";
import {languages} from "../../../../../configuration/constants";
import GuestDetailsTags from "../../guestdetails/GuestDetailsTags";

const useStyles = (theme) =>
  buildStyles({
    fieldContainer: {
      paddingTop: theme.spacing(4),
    },
    userImageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default function GuestSection({guestId}) {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  const [guest, setGuest] = React.useState(guests[guestId]);
  const prevGuest = usePrevious(guest);
  const [assignedTo, setAssignedTo] = React.useState(null);

  const users = React.useMemo(() => {
    if (!user_profile?.enso_users) {
      return [];
    }
    return user_profile.enso_users.map((u) => ({
      ...u,
      picture: u.picture ?? u.profile_picture,
    }));
  }, [user_profile]);

  const assignee = React.useMemo(() => {
    const euId = assignedTo?.enso_user_id || guest?.assigned_to || "";
    return users.find((u) => u.enso_user_id === euId) ?? null;
  }, [assignedTo, users, guest]);

  const {guest_email, guest_phone} = React.useMemo(
    () => getGuestContactInfo(guest),
    [guest],
  );

  React.useEffect(() => {
    if (!guests[guestId] && prevGuest?.guest_id === guestId) {
      return;
    } else {
      setGuest((prev) => guests[guestId]);
    }
  }, [guests, guestId]);

  const updateGuest = React.useCallback(
    (field, val, update) => {
      update && dispatch(updGuest({guest_id: guest.guest_id, field, val}));
    },
    [dispatch, guest?.guest_id],
  );

  const handleAssigneeChange = (newAssigneeId) => {
    const newAssignee = users.find((u) => u.enso_user_id === newAssigneeId);

    if (newAssignee) {
      setAssignedTo(newAssignee);
      updateGuest("assigned_to", newAssigneeId, true);
    } else {
      setAssignedTo(null);
      updateGuest("assigned_to", null, true);
    }
  };

  const handleLanguageUpdate = (newLang) => {
    updateGuest("language", newLang, true);
  };

  return (
    <DropdownExpand
      divider={false}
      // expanded={true}
      customLabel={<Text variant={"displayXS"}>{"Details"}</Text>}
    >
      <FlexContainer
        className={classes.fieldContainer}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        gap={theme.spacing(4)}
        flexDirection={"column"}
      >
        <GuestField
          label="Assignee"
          icon={User}
          value={
            <DropDownSelect
              dropDownBase={ChipDropDownBase}
              label={
                <FlexContainer alignItems="center" gap={theme.spacing(2)}>
                  <Container className={classes.userImageContainer}>
                    <ProfilePicture
                      sx={{width: "25px", height: "25px"}}
                      name={assignee?.name || "Unassigned"}
                      image={assignee?.picture}
                    />
                  </Container>
                  <Text variant="textMD" color="textPrimary">
                    {assignee?.name || "Unassigned"}
                  </Text>
                </FlexContainer>
              }
              onChange={(newValue) => handleAssigneeChange(newValue)}
            >
              {users.map((user) => (
                <FlexContainer
                  key={user.enso_user_id}
                  value={user.enso_user_id}
                  label={user.name}
                  alignItems="center"
                  gap={theme.spacing(2)}
                  sx={{
                    cursor: "pointer",
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                  }}
                >
                  <Container className={classes.userImageContainer}>
                    <ProfilePicture
                      name={user.name || "?"}
                      image={user.picture}
                      sx={{width: "25px", height: "25px"}}
                    />
                  </Container>
                  <Text variant="textMD" color="textPrimary">
                    {user.name}
                  </Text>
                </FlexContainer>
              ))}
            </DropDownSelect>
          }
        />
        <GuestField
          label="Response Time"
          value={
            guest?.response_time
              ? `${Math.round(guest?.response_time / 6) / 10}min`
              : "-"
          }
          icon={Calendar}
        />
        <GuestDetailsTags
          guest={guest}
          updateGuest={(val) => updateGuest("tags", val, true)}
        />
        <Divider noPadding />
        <GuestField
          label="Email"
          value={
            <GuestDetailsEditableValue
              value={guest_email}
              onChange={(e) => console.log("UPDATED VALUE", e)}
            />
          }
          icon={Email}
        />
        <GuestField
          label="Phone"
          value={
            <GuestDetailsEditableValue
              value={guest_phone}
              onChange={(e) => console.log("UPDATED VALUE", e)}
            />
          }
          icon={Phone}
        />
        <GuestField
          label="Language"
          value={
            <DropDownSelect
              dropDownBase={ChipDropDownBase}
              label={
                <FlexContainer alignItems="center" gap={theme.spacing(2)}>
                  <Text variant="textMD" color="textPrimary">
                    {languages[guest?.language] || "N/A"}
                  </Text>
                </FlexContainer>
              }
              onChange={(newValue) => handleLanguageUpdate(newValue)}
            >
              {Object.keys(languageEnum).map((user) => (
                <FlexContainer
                  key={languageEnum[user]}
                  value={languageEnum[user]}
                  label={user}
                  alignItems="center"
                  gap={theme.spacing(2)}
                  sx={{
                    cursor: "pointer",
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                  }}
                >
                  <Text variant="textMD" color="textPrimary">
                    {user}
                  </Text>
                </FlexContainer>
              ))}
            </DropDownSelect>
          }
          icon={Calendar}
        />
        <Divider noPadding />
        <Container sx={{width: "100%"}}>
          <GuestField label="Conversation Summary" icon={AI} />
          <TextInput
            sx={{marginTop: theme.spacing(1)}}
            as={"textarea"}
            inputBase={OutlinedInputBase}
            inputProps={{
              value: "Conversation Summary Placeholder",
              placeholder: "Placeholder conversation summary.",
            }}
          />
        </Container>
      </FlexContainer>
    </DropdownExpand>
  );
}
