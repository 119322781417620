import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as ArrowUpIcon} from "../../icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../icons/chevron-down.svg";
import {buildStyles} from "../buildStyles";
import {useTheme} from "../../theme/ThemeProvider";
import Container from "./Container";
import SvgIcon from "../display/SvgIcon";
import Text from "../display/Text";

const useStyles = (theme) =>
  buildStyles({
    gridContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    gridHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    },
    gridLabel: {
      fontSize: theme.typography.displayMD.fontSize,
      fontWeight: theme.typography.displayMD.semibold.fontWeight,
      color: theme.colors.textPrimary,
      display: "flex",
      alignItems: "center",
    },
    gridContent: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(4),
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    gridHidden: {
      display: "none",
    },
    arrow: {
      width: "16px",
      height: "16px",
      color: theme.colors.gray400,
      flexShrink: 0,
    },
  });

/**
 * `GridComponent` renders a grid of items with optional features like collapsibility and custom labels.
 *
 * @param {Object} props - Props for the GridComponent.
 * @param {string} [props.label] - The label to display above the grid.
 * @param {boolean} [props.collapsible=false] - Whether the grid is collapsible.
 * @param {React.ReactNode} [props.customLabel=null] - A custom label component to override the default label.
 * @param {Object} [props.labelProps] - Additional props to pass to the label.
 * @param {React.ReactNode} props.children - The grid items to display inside the component.
 * @param {Object} props.rest - Any other props that should be passed to the container.
 *
 * @returns {JSX.Element} The rendered GridComponent.
 */
const GridComponent = ({
  label,
  collapsible = false,
  customLabel = null,
  labelProps = {},
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const toggleGrid = () => {
    if (collapsible) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Container className={classes.gridContainer} {...props}>
      {(label || customLabel) && (
        <Container
          className={classes.gridHeader}
          onClick={collapsible ? toggleGrid : undefined}
        >
          {customLabel || (
            <Text className={classes.gridLabel} {...labelProps}>
              {label}
            </Text>
          )}
          {collapsible && (
            <SvgIcon
              component={isOpen ? ArrowDownIcon : ArrowUpIcon}
              className={classes.arrow}
            />
          )}
        </Container>
      )}
      <Container
        className={`${classes.gridContent} ${!isOpen ? classes.gridHidden : ""}`}
      >
        {children}
      </Container>
    </Container>
  );
};

GridComponent.propTypes = {
  label: PropTypes.string,
  collapsible: PropTypes.bool,
  customLabel: PropTypes.node,
  labelProps: PropTypes.object,
  children: PropTypes.node,
};

export default GridComponent;
