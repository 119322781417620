import React, {useContext, useMemo, useState} from "react";
import {InboxContext} from "routes/MessagesPage";
import GuestField from "./GuestDetailsDisplayField";
import ChipRow from "../../../../connectui/component/layout/ChipRow";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import XChipBase from "../../../../connectui/component/display/chip/base/XChipBase";
import ChipBase from "../../../../connectui/component/display/chip/base/ChipBase";
import Skeleton from "../../../../connectui/component/display/Skeleton";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import {ReactComponent as Calendar} from "connectui/icons/calendar.svg";
import ContextMenu from "../../../../connectui/component/input/context/ContextMenu";
import {getCustomFields} from "../../../../redux/actions/settingsActions";
import {useDispatch} from "react-redux";
import Container from "../../../../connectui/component/layout/Container";
import TextInput from "../../../../connectui/component/input/text/type/TextInput";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import ButtonIcon from "../../../../connectui/component/input/button/ButtonIcon";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import ICONS from "../../../../connectui/icons/iconMap";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import ListComponent from "../../../../connectui/component/layout/list/ListComponent";
import Text from "../../../../connectui/component/display/Text";
import ListItemContainer from "../../../../connectui/component/layout/list/ListItemContainer";
import CheckBox from "../../../../connectui/component/input/CheckBox";
import FilledInputBase from "connectui/component/input/text/FilledInputBase";

const useStyles = (theme) =>
  buildStyles({
    icon: {
      width: "19px",
      height: "19px",
    },
    addIcon: {
      width: "16px",
      height: "16px",
    },
    menuContainer: {
      padding: theme.spacing(2),
      minWidth: "200px",
    },
  });

function MenuTag({
  customFields,
  tags,
  addedTag,
  setAddedTag,
  toggleTag,
  updateGuest,
}) {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <Container className={classes.menuContainer}>
      {customFields ? (
        <Container className={classes.listContainer}>
          <ListComponent
            label={"Add a tag"}
            gap={theme.spacing(2)}
            listStyles={{maxHeight: "300px", overflowY: "auto"}}
          >
            {customFields["Guest"]
              ?.find((f) => f.field === "guest_tags")
              ?.data?.map((entry, index) => (
                <ListItemContainer key={index} onClick={() => toggleTag(entry)}>
                  <CheckBox
                    isChecked={tags.includes(entry)}
                    onChange={() => toggleTag(entry)}
                  />
                  <Text
                    as="div"
                    variant="textSM"
                    weight="medium"
                    color="gray700"
                    sx={{display: "inline"}}
                  >
                    {entry}
                  </Text>
                </ListItemContainer>
              ))}
          </ListComponent>
        </Container>
      ) : (
        <Skeleton />
      )}
      <FlexContainer>
        <TextInput
          inputBase={FilledInputBase}
          inputProps={{placeholder: "add a tag"}}
          value={addedTag}
          onChange={(e) => setAddedTag(e.target.value)}
        />
        <ButtonIcon
          icon={
            <SvgIcon
              component={ICONS["plus-circle"]}
              className={classes.addIcon}
            />
          }
          onClick={() => {
            if (addedTag.trim() && !tags.includes(addedTag)) {
              updateGuest([...tags, addedTag]);
              setAddedTag("");
            }
          }}
          disabled={!addedTag.trim() || tags.includes(addedTag)}
        />
      </FlexContainer>
    </Container>
  );
}

export default function GuestDetailsTags({guest, updateGuest}) {
  const {theme} = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const {customFields, setCustomFields} = useContext(InboxContext);
  const [addedTag, setAddedTag] = useState("");

  const updateCustomFields = () => {
    if (Object.keys(customFields).length) return;
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: [],
        onSuccess: (response) => {
          return !isMounted ? null : setCustomFields(response ?? {});
        },
      }),
    );
    return () => {
      isMounted = false;
    };
  };
  React.useEffect(updateCustomFields, []);

  const handleChipClick = (tag) => {
    updateGuest(guest?.tags?.filter((t) => t !== tag));
  };

  const toggleTag = (tag) => {
    if (guest?.tags.includes(tag)) {
      handleChipClick(tag);
    } else {
      updateGuest([...guest?.tags, tag]);
    }
  };

  const menuItems = useMemo(
    () => [
      {
        Component: MenuTag,
        customFields,
        tags: guest?.tags,
        addedTag,
        setAddedTag,
        toggleTag,
        updateGuest,
      },
    ],
    [customFields, guest.tags, addedTag, toggleTag],
  );

  return (
    <GuestField
      align={"flex-start"}
      label="Guest Tags"
      value={
        guest?.tags ? (
          <>
            <ChipRow
              wrap
              sx={{maxWidth: "70%"}}
              align={"right"}
              chips={[
                ...guest?.tags?.map((tag) => ({
                  Component: ChipText,
                  text: tag,
                  chipBase: XChipBase,
                  onXClick: () => handleChipClick(tag),
                  sx: {
                    cursor: "pointer",
                    backgroundColor: theme.colors.blue50,
                    color: theme.colors.gray700,
                    "&:hover": {
                      backgroundColor: theme.colors.gray200,
                    },
                  },
                })),
                {
                  Component: ChipText,
                  text: "+",
                  chipBase: ChipBase,
                  onClick: (event) => {
                    setAnchorEl(event.currentTarget);
                  },
                  sx: {
                    cursor: "pointer",
                    backgroundColor: theme.colors.blue50,
                    color: theme.colors.gray700,
                    "&:hover": {
                      backgroundColor: theme.colors.gray200,
                    },
                  },
                },
              ]}
            />
            <ContextMenu
              menuItems={menuItems}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            />
          </>
        ) : (
          <Skeleton />
        )
      }
      icon={Calendar}
    />
  );
}
