import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import BaseFieldComponent from "../BaseFieldComponent";
import Text from "connectui/component/display/Text";
import withReducer from "../../../../utilities/withReducer";
import {buildStyles} from "../../../../connectui/component/buildStyles";

const styles = (theme) => ({
  selectContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
});

const AssignedToField = ({fieldData, handleValueChange, reducer, ...props}) => {
  const classes = buildStyles(styles);
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const users = reducer.user_profile.enso_users || userProfile.enso_users || [];
  const userOptions = ["Unassigned", ...users.map((u) => u.enso_user_id)];

  const renderInput = () => (
    <div className={classes.selectContainer}>
      <SelectChipContainer
        options={userOptions}
        selectedOptions={fieldData?.val}
        onChange={(newSelection) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newSelection)
              ? newSelection.filter((f) => f !== "")
              : newSelection,
          );
        }}
        multiSelect={fieldData?.operator !== "is_not"}
        customRender={(option) => {
          const user = users.find((u) => u.enso_user_id === option);
          const isSelected = fieldData?.val?.includes(option);
          return (
            <Text
              variant="textSM"
              weight="medium"
              color={isSelected ? "blue700" : "gray700"}
            >
              {user?.name || option || "Unknown"}
            </Text>
          );
        }}
      />
    </div>
  );

  const renderFilter = () => renderInput();
  const getLabel = () => {
    const user = users.find((u) => u.enso_user_id === fieldData?.val);
    return user?.name ?? "Unknown";
  };

  const renderText = () => {
    const user = users.find((u) => u.enso_user_id === fieldData?.val);
    return user?.name || fieldData?.val;
  };

  return (
    <BaseFieldComponent
      displayType={fieldData?.displayType}
      renderInput={renderInput}
      getLabel={getLabel}
      renderFilter={renderFilter}
      renderLabel={renderText}
      {...props}
    />
  );
};

AssignedToField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  reducer: PropTypes.object.isRequired,
};

export default withReducer(AssignedToField);
