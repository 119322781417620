import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {editView} from "redux/actions/accountsActions";
import {buildStyles} from "connectui/component/buildStyles";
import {useTheme} from "connectui/theme/ThemeProvider";
import ICONS from "connectui/icons/iconMap";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import SvgIcon from "connectui/component/display/SvgIcon";
import ScrollableContainer from "connectui/component/layout/ScrollableContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import FilledInputBase from "connectui/component/input/text/FilledInputBase";
import Skeleton from "connectui/component/display/Skeleton";
import ListComponent from "connectui/component/layout/list/ListComponent";
import Text from "connectui/component/display/Text";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import ButtonText from "connectui/component/input/button/ButtonText";
import TextButtonBase from "connectui/component/input/button/base/TextButtonBase";
import FiltersSetItem from "./FilterSetItem";
import ButtonIconText from "connectui/component/input/button/ButtonIconText";

const useStyles = (theme) =>
  buildStyles({
    icon: {
      width: "19px",
      height: "19px",
    },
    actionsIcon: {
      width: "18px",
      height: "18px",
      cursor: "pointer",
    },
    emptyCardIcon: {
      width: "24px",
      height: "24px",
    },
    emptyCard: {
      padding: theme.spacing(6, 4),
      borderRadius: `${theme.radius.md}px`,
      backgroundColor: theme.colors.gray100,
    },
    learnMoreBtn: {
      outline: "none",
      backgroundColor: "transparent",
      "&:hover": {
        color: theme.colors.gray700,
        backgroundColor: "transparent",
      },
    },
    createViewBtn: {
      padding: theme.spacing(1, 3),
      backgroundColor: "transparent !important",
      boxShadow: "none",
      border: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing(2),
      color: theme.colors.textSecondary,
      opacity: 0.5,
      "&:hover": {opacity: 1},
    },
    addIcon: {
      width: "16px",
      height: "16px",
    },
  });

const EmptyViewsCard = ({createView}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={theme.spacing(2)}
      className={classes.emptyCard}
    >
      <SvgIcon
        className={classes.emptyCardIcon}
        component={ICONS.eye}
        sx={{color: theme.colors.textSecondary}}
      />
      <Text
        variant="textSM"
        weight="medium"
        sx={{marginBottom: theme.spacing(2), textAlign: "center"}}
      >
        {"Save custom views for quick access to key information"}
      </Text>
      <ButtonText
        buttonBase={OutlinedButtonBase}
        typographyVariant="textSM"
        weight="semibold"
        color="gray700"
        label="Create View"
        sx={{boxShadow: "none", width: "170px"}}
        onClick={createView}
      />
      <ButtonText
        buttonBase={TextButtonBase}
        className={classes.learnMoreBtn}
        as="a"
        typographyVariant="textSM"
        weight="semibold"
        color="gray700"
        label="Learn More"
        target="_blank"
        href="https://help.ensoconnect.com/en/articles/10407237-inbox-views"
      />
    </FlexContainer>
  );
};

const FilterSetList = ({selected, openMenu, onFilterClick, createView}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const loadingViews = useSelector(
    (state) => state.defaultReducer.loading,
  ).views;
  const inbox_data = useSelector((state) => state.defaultReducer.inbox_data);

  const [editItem, setEditItem] = useState({id: null, name: ""});
  const viewsSections = [
    t("general-filter-sets-section-title"),
    t("custom-filter-sets-section-title"),
  ];

  const getColors = (color) => {
    const itemColor = theme.colors[color] || theme.colors.primary;
    const selectedBG =
      theme.colors[color.replace(/\d+$/, "50")] || theme.colors.primary50;
    return {icon: itemColor, bg: selectedBG};
  };

  const saveViewName = () => {
    const newView = {
      ...(user_profile.views.find((v) => v.id === editItem.id) ??
        user_profile.public_views.find((v) => v.id === editItem.id)),
    };
    newView.name = editItem.name;
    dispatch(editView({view: newView}));
    setEditItem({id: null, name: ""});
  };

  return (
    <ScrollableContainer
      width={"100%"}
      height={"100%"}
      p={theme.spacing(0, 4, 4)}
    >
      <ListComponent gap={theme.spacing(6)}>
        {viewsSections.map((section, ind) => (
          <ListComponent
            key={section}
            gap={theme.spacing(1)}
            label={section}
            collapsible={true}
            labelProps={{
              variant: "textSM",
              weight: "medium",
              color: "textSecondary",
              sx: {marginBottom: theme.spacing(2)},
            }}
          >
            {!user_profile?.enso_key || loadingViews ? (
              <ListComponent gap={theme.spacing(1)}>
                {Array.from({length: 4}).map((_, index) => (
                  <FlexContainer
                    key={index}
                    alignItems="center"
                    gap={theme.spacing(3)}
                    sx={{
                      width: "100%",
                      height: "36px",
                      padding: theme.spacing(2, 3),
                    }}
                  >
                    <Skeleton
                      width={"20px"}
                      height={"20px"}
                      style={{borderRadius: `${theme.radius.lg}px`}}
                    />
                    <Skeleton
                      width={"128px"}
                      height={"20px"}
                      style={{borderRadius: `${theme.radius.lg}px`}}
                    />
                  </FlexContainer>
                ))}
              </ListComponent>
            ) : !user_profile.public_views.length &&
              !user_profile.views.length ? (
              t("empty")
            ) : ind === 1 && !user_profile.views.length ? (
              <EmptyViewsCard createView={createView} />
            ) : (
              <>
                {user_profile[ind === 0 ? "public_views" : "views"].map(
                  (filterSet) => {
                    const isSelected = selected === filterSet.id;
                    const colors = getColors(filterSet.color);

                    if (editItem.id === filterSet.id) {
                      return (
                        <TextInput
                          key={filterSet.id}
                          inputBase={FilledInputBase}
                          sx={{width: "100%"}}
                          inputProps={{
                            placeholder: "View name",
                            value: editItem.name,
                            onChange: (e) => {
                              setEditItem({
                                id: filterSet.id,
                                name: e.target.value,
                              });
                            },
                          }}
                          actions={
                            <FlexContainer
                              alignItems="center"
                              gap={theme.spacing(1)}
                              sx={{marginLeft: theme.spacing(3)}}
                            >
                              <SvgIcon
                                className={classes.actionsIcon}
                                component={ICONS.check}
                                sx={{color: theme.colors.textSecondary}}
                                onClick={saveViewName}
                              />
                              <SvgIcon
                                className={classes.actionsIcon}
                                component={ICONS["small-x"]}
                                sx={{color: theme.colors.textSecondary}}
                                onClick={() =>
                                  setEditItem({id: null, name: ""})
                                }
                              />
                            </FlexContainer>
                          }
                        />
                      );
                    }

                    return (
                      <FiltersSetItem
                        key={filterSet.id}
                        openContextMenu={openMenu(filterSet, ind === 0)}
                        icon={
                          <SvgIcon
                            className={classes.icon}
                            sx={{color: colors.icon}}
                            component={ICONS[filterSet.icon]}
                          />
                        }
                        onClick={onFilterClick(filterSet)}
                        onEditName={
                          user_profile.enso_user_id ===
                          filterSet.created_by_user
                            ? (e) => {
                                e.stopPropagation();
                                setEditItem({
                                  id: filterSet.id,
                                  name: filterSet.name,
                                });
                              }
                            : null
                        }
                        label={filterSet.name}
                        totalCount={
                          inbox_data[filterSet.id]
                            ? inbox_data[filterSet.id]
                            : null
                        }
                        itemProps={{
                          sx: isSelected ? {backgroundColor: colors.bg} : {},
                        }}
                      />
                    );
                  },
                )}
              </>
            )}
            {ind === 1 && !loadingViews && !!user_profile.views.length && (
              <ButtonIconText
                className={classes.createViewBtn}
                label={"New View"}
                weight="medium"
                color="textSecondary"
                typographyVariant="textSM"
                onClick={createView}
                icon={
                  <SvgIcon
                    component={ICONS["plus-circle"]}
                    className={classes.addIcon}
                  />
                }
              />
            )}
          </ListComponent>
        ))}
      </ListComponent>
    </ScrollableContainer>
  );
};

export default FilterSetList;
