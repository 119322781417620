import React, {useCallback, useMemo, useState} from "react";
import PropTypes from "prop-types";
import Container from "../Container";
import Text from "../../display/Text";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import VirtualizedListComponent from "../list/VirtualizedListComponent";

const useStyles = (theme, rowHeight, columns, tableStyles, headerStyles) =>
  buildStyles({
    tableContainer: {
      border: `1px solid #e9eaeb`,
      borderRadius: "15px",
      width: "100%",
      height: "100%",
      overflowX: "auto",
      ...tableStyles,
    },
    table: {
      display: "flex",
      flexDirection: "column",
      minWidth: `${columns * 310}px`,
      height: "100%",
      overflowY: "hidden",
    },
    header: {
      display: "flex",
      backgroundColor: theme.colors.gray50,
      borderBottom: `1px solid ${theme.colors.border}`,
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(8),
      ...headerStyles,
    },
    headerCell: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
      minWidth: "300px",
    },
    row: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(8),
      height: `${rowHeight}px`,
      borderBottom: `1px solid ${theme.colors.border}`,
      cursor: "pointer",
    },
    cell: {
      flex: 1,
      padding: theme.spacing(1),
      minWidth: "300px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    sortable: {
      cursor: "pointer",
    },
    sortIcon: {
      marginLeft: theme.spacing(1),
    },
  });

export const TableColumn = PropTypes.shape({
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  render: PropTypes.func,
});

const VirtualizedTableComponent = ({
  columns,
  data,
  totalRows,
  rowHeight = 60,
  isRowLoaded,
  loadMoreRows,
  defaultSortField,
  onClickRow,
  tableStyles,
  headerStyles,
  labelProps,
  defaultSortDirection = "ASC",
}) => {
  const {theme} = useTheme();
  const classes = useStyles(
    theme,
    rowHeight,
    columns.length,
    tableStyles,
    headerStyles,
  );
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);

  const sortedData = useMemo(() => {
    if (!sortField) return data;
    const column = columns.find((col) => col.field === sortField);

    if (column?.getSortValue) {
      return [...data].sort((a, b) => {
        const aValue = column.getSortValue(a);
        const bValue = column.getSortValue(b);
        if (sortDirection === "ASC") return aValue > bValue ? 1 : -1;
        return aValue < bValue ? 1 : -1;
      });
    }

    return [...data].sort((a, b) => {
      if (sortDirection === "ASC") return a[sortField] > b[sortField] ? 1 : -1;
      return a[sortField] < b[sortField] ? 1 : -1;
    });
  }, [data, sortField, sortDirection, columns]);

  const handleSort = useCallback(
    (field) => {
      if (sortField === field) {
        setSortDirection((prev) => (prev === "ASC" ? "DESC" : "ASC"));
      } else {
        setSortField(field);
        setSortDirection("ASC");
      }
    },
    [sortField],
  );

  return (
    <div className={classes.tableContainer}>
      <Container className={classes.table}>
        <Container className={classes.header}>
          {columns.map((col, colIndex) => (
            <Container
              key={colIndex}
              className={`${classes.headerCell} ${
                col.sortable ? classes.sortable : ""
              }`}
              onClick={() => col.sortable && handleSort(col.field)}
            >
              <Text color={"textSecondary"} {...labelProps}>
                {col.label}
                {sortField === col.field && (
                  <span className={classes.sortIcon}>
                    {sortDirection === "ASC" ? "↑" : "↓"}
                  </span>
                )}
              </Text>
            </Container>
          ))}
        </Container>

        <VirtualizedListComponent
          totalRows={totalRows}
          rowHeight={rowHeight}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
        >
          {(index) => {
            const rowData = sortedData[index];
            return (
              <Container
                key={index}
                className={classes.row}
                onClick={() => onClickRow?.(index)}
              >
                {columns.map((col, colIndex) => (
                  <Container key={colIndex} className={classes.cell}>
                    {col.render ? col.render(rowData) : rowData[col.field]}
                  </Container>
                ))}
              </Container>
            );
          }}
        </VirtualizedListComponent>
      </Container>
    </div>
  );
};

VirtualizedTableComponent.propTypes = {
  columns: PropTypes.arrayOf(TableColumn),
  data: PropTypes.array,
  totalRows: PropTypes.number,
  rowHeight: PropTypes.number,
  isRowLoaded: PropTypes.func,
  loadMoreRows: PropTypes.func,
  defaultSortField: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(["ASC", "DESC"]),
  onClickRow: PropTypes.func,
};

export default VirtualizedTableComponent;
