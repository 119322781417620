import React, {createContext, useState} from "react";
import Inbox from "ui/page/Inbox";
import MobileInbox from "ui/page/MobileInbox";
import Messages from "./Messages";
import {useFlags} from "launchdarkly-react-client-sdk";
import useMediaQuery from "utilities/useMediaQuery";

const defaultContext = {
  appliedFilters: [],
  customFields: [],
  setAppliedFilters: () => {},
  setCustomFields: () => {},
};
export const InboxContext = createContext(defaultContext);

const MessagesPage = () => {
  const flags = useFlags();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  if (flags.newInbox) {
    return (
      <InboxContext.Provider
        value={{
          appliedFilters,
          customFields,
          setAppliedFilters,
          setCustomFields,
        }}
      >
        {isDesktop ? (
          <Inbox />
        ) : flags.newInboxMobile ? (
          <MobileInbox />
        ) : (
          <Messages />
        )}
      </InboxContext.Provider>
    );
  }
  return <Messages />;
};

export default MessagesPage;
