import React, {useState, useRef} from "react";
import {Box, CardMedia} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    margin: "auto",
    overflow: "hidden",
    borderRadius: 10,
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    width: "100%",
    height: 300,
  },
  imageWrapper: {
    flex: "0 0 100%",
    width: "100%",
    cursor: "pointer",
  },
  image: {
    height: "100%",
    objectPosition: "top",
  },
  indicatorsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    left: "50%",
    gap: theme.spacing(1.5),
    transform: "translateX(-50%)",
    zIndex: 2,
  },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    transition: "background-color 0.3s",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    opacity: 0.6,
    "&.active": {opacity: 1},
  },
}));

const ImageCarousel = ({images, maxImages = 10}) => {
  const classes = useStyles();
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const carouselRef = useRef(null);
  const startX = useRef(0);
  const isDragging = useRef(false);

  const limitedImages = images.slice(0, maxImages);

  const handleIndicatorClick = (index) => {
    setCurrentImgIndex(index);
  };

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;

    const diff = startX.current - e.clientX;
    if (diff > 50 && currentImgIndex < limitedImages.length - 1) {
      setCurrentImgIndex((prev) => prev + 1);
      isDragging.current = false;
    } else if (diff < -50 && currentImgIndex > 0) {
      setCurrentImgIndex((prev) => prev - 1);
      isDragging.current = false;
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleWheel = (e) => {
    if (e.shiftKey) {
      if (e.deltaY > 0 && currentImgIndex < limitedImages.length - 1) {
        setCurrentImgIndex((prev) => prev + 1);
      } else if (e.deltaY < 0 && currentImgIndex > 0) {
        setCurrentImgIndex((prev) => prev - 1);
      }
    }
  };

  const handleTouchStart = (e) => {
    isDragging.current = true;
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;

    const diff = startX.current - e.touches[0].clientX;
    if (diff > 50 && currentImgIndex < limitedImages.length - 1) {
      setCurrentImgIndex((prev) => prev + 1);
      isDragging.current = false;
    } else if (diff < -50 && currentImgIndex > 0) {
      setCurrentImgIndex((prev) => prev - 1);
      isDragging.current = false;
    }
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  return (
    <Box
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={classes.mainContainer}
    >
      <Box
        className={classes.carousel}
        sx={{transform: `translateX(-${currentImgIndex * 100}%)`}}
      >
        {limitedImages.map((image, index) => (
          <Box key={index} className={classes.imageWrapper}>
            <CardMedia
              className={classes.image}
              image={image}
              component="img"
              alt={`Image ${index + 1}`}
            />
          </Box>
        ))}
      </Box>

      <Box className={classes.indicatorsContainer}>
        {limitedImages.map((_, index) => (
          <Box
            key={index}
            className={clsx(classes.indicator, {
              active: index === currentImgIndex,
            })}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
