import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import BaseFieldComponent from "../BaseFieldComponent";

const SingleSelectField = ({
  fieldData,
  handleValueChange,
  options,
  ...props
}) => {
  const renderInput = () => (
    <SelectChipContainer
      options={options}
      selectedOptions={fieldData?.val}
      onChange={(newSelection) => {
        handleValueChange(
          fieldData.type,
          Array.isArray(newSelection)
            ? newSelection.filter((f) => f !== "")
            : newSelection,
        );
      }}
      multiSelect={false}
    />
  );

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      renderFilter={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

SingleSelectField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SingleSelectField;
