import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import Container from "../../layout/Container";
import OutlinedButtonBase from "../../input/button/base/OutlinedButtonBase";
import {generateStyles} from "../../../theme/styleUtilities";

const useStyles = (theme) =>
  buildStyles({
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: "50%",
      backgroundColor: theme.colors.primary100,
      color: theme.colors.primary,
    },
    actionRow: {
      display: "flex",
      justifyContent: "flex-start",
      gap: theme.spacing(2),
    },
  });

/**
 * CardBase is a versatile component that renders a card-like structure.
 * It supports optional customization for its content through default slots (icon, text, action row)
 * or allows full override with the `customContent` prop.
 *
 * @param {Object} props - The props for the CardBase component.
 * @param {React.ElementType} [props.buttonBase=ButtonBase] - The component to render for the card base (defaults to ButtonBase).
 * @param {React.ReactNode} [props.icon=null] - The icon to display inside the card (default slot).
 * @param {React.ReactNode} [props.title=null] - The main text content to display inside the card (default slot).
 * @param {React.ReactNode} [props.description=null] - The secondary text content to display inside the card (default slot).
 * @param {React.ReactNode} [props.actionRow=null] - The action row content, usually buttons or links, to display at the bottom of the card (default slot).
 * @param {React.ReactNode} [props.customContent=null] - If provided, this overrides all default slots and renders custom content inside the card.
 * @param {Object} [props.sx={}] - The style object that can be used to override default card styles.
 * @param {function} [props.onClick] - Callback when the card is clicked.
 * @param {Object} props.rest - Any other props that should be passed to the card base component.
 *
 * @returns {JSX.Element} The rendered CardBase component.
 */
const CardBase = ({
  buttonBase: ButtonBaseComponent = OutlinedButtonBase,
  icon = null,
  title = null,
  description = null,
  actionRow = null,
  customContent = null,
  onClick,
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const defaultStyles = {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(5),
    alignItems: "flex-start",
    gap: theme.spacing(3),
  };

  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <ButtonBaseComponent sx={styles} onClick={onClick}>
      {customContent || (
        <>
          {icon && <Container className={classes.icon}>{icon}</Container>}
          {title && (
            <Container
              sx={{textAlign: "left", display: "block", width: "100%"}}
            >
              {title}
            </Container>
          )}
          {description && (
            <Container
              sx={{textAlign: "left", display: "block", width: "100%"}}
            >
              {description}
            </Container>
          )}

          {actionRow && (
            <Container className={classes.actionRow}>{actionRow}</Container>
          )}
        </>
      )}
    </ButtonBaseComponent>
  );
};

CardBase.propTypes = {
  buttonBase: PropTypes.elementType,
  icon: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  actionRow: PropTypes.node,
  customContent: PropTypes.node,
  sx: PropTypes.object,
};

export default CardBase;
