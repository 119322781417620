import React from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import ListComponent from "../../../../connectui/component/layout/list/ListComponent";
import ToggleCardComponent from "../../../../connectui/component/display/card/ToggleCardComponent";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import Text from "../../../../connectui/component/display/Text";
import ButtonText from "../../../../connectui/component/input/button/ButtonText";
import Container from "../../../../connectui/component/layout/Container";

export default function PhoneConnectPanel({setStageId}) {
  const {theme} = useTheme();

  const [selectedOption, setSelectedOption] = React.useState(0);

  return (
    <FlexContainer
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={theme.spacing(5)}
      sx={{paddingBottom: theme.spacing(3), width: "460px"}}
    >
      <Text variant={"displayMD"} weight={"bold"}>
        Connect Your Phone
      </Text>
      <Text variant={"textLG"}>Connect Your Phone Subtitle</Text>

      <ListComponent>
        <ToggleCardComponent
          title="Display Your Own Number"
          description="Your number will be shown in guest communications, such as the Boarding Pass and emails, but cannot be used for direct calls or messages through Enso. This is ideal if you handle communication externally."
          isSelected={selectedOption === 0}
          onToggle={() => setSelectedOption(0)}
        />
        <ToggleCardComponent
          title="Use Enso Number"
          description="Enso will generate and display a unique number for guest communication. You can receive calls and messages directly through Enso."
          isSelected={selectedOption === 1}
          onToggle={() => setSelectedOption(1)}
        />
      </ListComponent>

      <ButtonText
        label={"Continue"}
        typographyVariant="textSM"
        weight="semibold"
        sx={{width: "100%"}}
        color="white"
        onClick={() => {
          if (selectedOption === 0) {
            setStageId(2);
          } else if (selectedOption === 1) {
            setStageId(4);
          }
        }}
      />
    </FlexContainer>
  );
}
