import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import Container from "../layout/Container";

const useStyles = (theme) =>
  buildStyles({
    radioWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: "50%",
      border: `2px solid ${theme.colors.gray400}`,
      backgroundColor: theme.colors.gray100,
      cursor: "pointer",
      transition: "border-color 0.2s ease, box-shadow 0.2s ease",
      "&:hover": {
        borderColor: theme.colors.primary,
      },
      "&.checked": {
        borderColor: theme.colors.primary,
        backgroundColor: theme.colors.primary100,
      },
    },
    radioInner: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: "50%",
      backgroundColor: theme.colors.primary,
      transform: "scale(0)",
      transition: "transform 0.2s ease",
    },
    radioInnerChecked: {
      transform: "scale(1)",
    },
  });

/**
 * RadioButton is a custom component to act as a styled radio button.
 *
 * @param {Object} props - Props for the RadioButton component.
 * @param {boolean} props.checked - Whether the radio button is selected.
 * @param {function} props.onChange - Callback when the radio button state changes.
 * @param {string} props.name - The name of the radio button group.
 * @param {Object} props.sx - Custom styles for the radio button wrapper.
 * @returns {JSX.Element} The rendered RadioButton component.
 */
const RadioButton = ({checked, onChange, name, sx = {}, ...props}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <Container
      className={`${classes.radioWrapper} ${checked ? "checked" : ""}`}
      sx={sx}
      onClick={onChange}
      role="radio"
      aria-checked={checked}
      tabIndex={0}
      {...props}
    >
      <Container
        className={`${classes.radioInner} ${
          checked ? classes.radioInnerChecked : ""
        }`}
      />
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={() => {}}
        style={{display: "none"}}
      />
    </Container>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  sx: PropTypes.object,
};

RadioButton.defaultProps = {
  name: "",
  sx: {},
};

export default RadioButton;
