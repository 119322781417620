import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import {makeStyles} from "@material-ui/core/styles"; // Custom
import SuccessSnackbar from "core/snackbars/SuccessSnackbar";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import Tag from "core/chips/Tag"; // Utils
import {format, isAfter, isBefore} from "date-fns";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  listItemTextRoot: {margin: 0},
  passwordInput: {
    marginRight: theme.spacing(2),
    "& input": {
      padding: theme.spacing(1, 2),
      width: 90,
    },
  },
}));

export default function DeviceAccessCodeItem({
  visible,
  deviceCode = {},
  disabled,
  onDelete,
  editable = false,
  toggleVisibility = () => {},
}) {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [deviceNames, setDeviceNames] = useState([]);
  const devices = useSelector((state) => state.defaultReducer.devices_dict);
  let codeNotAvailable =
    !deviceCode.access_code ||
    deviceCode.status === "setting" ||
    deviceCode.status === "failed";

  useEffect(() => {
    let newDeviceNames = [];
    deviceCode.device_ids.forEach((d_id) => {
      if (!!devices[d_id]?.name) {
        newDeviceNames.push(devices[d_id].name);
      }
    });
    setDeviceNames((prev) => newDeviceNames);
  }, [deviceCode, devices]);

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setAlertOpen((prev) => true);
  };

  const onAlertClose = () => setAlertOpen((prev) => false);

  return (
    <>
      <SuccessSnackbar
        open={alertOpen}
        onClose={onAlertClose}
        label="Copied access code to clipboard!"
      />
      <ListItem key={deviceCode.code_id} className={classes.container}>
        <ListItemText
          disableTypography
          classes={{root: classes.listItemTextRoot}}
          primary={
            <Box display={"flex"} flexDirection={"column"}>
              <Box
                mb={1}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
              >
                <Typography variant="subtitle2" component="div">
                  {`${deviceCode.name}${!!deviceNames.length ? ": " : ""}`}
                  {deviceNames.length > 2
                    ? `${deviceNames[0]}, ${deviceNames[1]}, +${deviceNames.length - 2} more`
                    : `${deviceNames.join(", ")}`}
                </Typography>
                {deviceCode.status !== "setting" && (
                  <Box ml={2}>
                    <Tag
                      square
                      readOnly
                      error={deviceCode.status === "unset"}
                      label={
                        deviceCode.status === "unset"
                          ? "Unset"
                          : !!deviceCode.end
                            ? isAfter(
                                new Date(Number(deviceCode.start)),
                                new Date(),
                              )
                              ? `Active from ${format(new Date(Number(deviceCode.start)), "MMM d")} to ${format(new Date(Number(deviceCode.end)), "MMM d")}`
                              : isBefore(
                                    new Date(Number(deviceCode.end)),
                                    new Date(),
                                  )
                                ? "Expired"
                                : `Expires on ${format(new Date(Number(deviceCode.end)), "MMM d")}`
                            : "Permanent"
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                {!editable ? (
                  <Box mr={2}>
                    <Typography>
                      {!visible
                        ? deviceCode.access_code?.replace(/./g, "*")
                        : deviceCode.access_code}
                    </Typography>
                  </Box>
                ) : !!deviceCode.access_code &&
                  deviceCode.status !== "setting" ? (
                  <FilledTextField
                    disabled
                    value={deviceCode.access_code}
                    className={classes.passwordInput}
                    type={!visible ? "password" : "text"}
                  />
                ) : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{fontStyle: "italic"}}
                  >
                    {"Code not available"}
                  </Typography>
                )}
                {!codeNotAvailable && (
                  <IconButton
                    style={{padding: 0}}
                    aria-label="toggle password visibility"
                    onClick={() => toggleVisibility(deviceCode.access_code)}
                  >
                    {visible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )}
                {!codeNotAvailable && (
                  <Box ml={2}>
                    <PrimaryButton
                      label="Copy"
                      color="secondary"
                      variant="text"
                      onClick={() => handleCopyCode(deviceCode.access_code)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          }
        />
        {["setting", "unset"].includes(deviceCode.status) && (
          <ListItemText
            style={{color: THEME.error, alignSelf: "flex-end"}}
            primary={"Synchronizing with lock"}
          />
        )}
        {["failed", "unset", "setting"].includes(deviceCode.status) && (
          <ListItemText
            style={{color: THEME.error, alignSelf: "flex-end"}}
            primary={deviceCode.error_message || "Failed to generate code"}
          />
        )}
        <Box
          mb={2}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          alignSelf={"flex-end"}
        >
          {!disabled && (
            <DeleteIconButton
              disablePadding
              onClick={() => onDelete(deviceCode.access_code)}
            />
          )}
        </Box>
      </ListItem>
    </>
  );
}
