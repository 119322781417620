import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import FilledTextField from "../../../../core/inputs/FilledTextField";
import SelectChipContainer from "connectui/component/input/SelectChipContainer";
import _ from "lodash";

const renderInput = ({fieldData, handleValueChange}) => {
  return (
    <div style={{width: "100%", height: "100%"}}>
      <FilledTextField
        fullWidth
        label="Value"
        type="number"
        inputProps={{min: 0, max: 100000}}
        value={fieldData.val}
        onChange={(e) => handleValueChange(fieldData.type, e.target.value)}
      />
    </div>
  );
};

const renderFilter = ({fieldData, handleValueChange}) => {
  const {val, operators} = fieldData;

  const options = {
    $0: [{path: fieldData.field, operator: "==", value: 0}],
    "$1 - $25": [
      {path: fieldData.field, operator: ">", value: 0},
      {path: fieldData.field, operator: "<=", value: 25},
    ],
    "$26 - $50": [
      {path: fieldData.field, operator: ">", value: 25},
      {path: fieldData.field, operator: "<=", value: 50},
    ],
    "$51 - $100": [
      {path: fieldData.field, operator: ">", value: 51},
      {path: fieldData.field, operator: "<=", value: 100},
    ],
    "Above $100": [{path: fieldData.field, operator: ">", value: 100}],
  };

  const selected = _.chain(Object.entries(options))
    .filter(
      (entry) =>
        entry[1][0].value === val[0] && entry[1][0].operator === operators[0],
    )
    .map((entry) => entry[0])
    .value();

  return (
    <SelectChipContainer
      options={Object.keys(options)}
      c
      selectedOptions={selected}
      multiSelect={false}
      onChange={(newSelection) =>
        handleValueChange("custom", options[newSelection])
      }
    />
  );
};

const EnsoTotalRevenueField = ({
  fieldData,
  handleValueChange,
  options,
  ...props
}) => {
  const getOperators = () => [
    "is",
    "is_not",
    "is_greater_than",
    "is_less_than",
    "exists",
    "not_exists",
  ];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={() => renderInput({fieldData, handleValueChange})}
      renderFilter={() => renderFilter({fieldData, handleValueChange})}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

EnsoTotalRevenueField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default EnsoTotalRevenueField;
