import React from "react";
import {Auth, Storage} from "aws-amplify";
import {useDispatch} from "react-redux";
import {Redirect, useLocation} from "react-router-dom";
import qs from "qs";
// UI
import {
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  CircularProgress,
  Link,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import useRegisterStyles from "styles/useRegisterStyles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {ReactComponent as EnsoLogo} from "assets/icons/Enso_Icon.svg";
// Custom
import RegisterForm from "./Register/RegisterForm";
// Actions
import {signUpChannel, setUser, signUp} from "redux/actions/accountsActions";
// Utils
import {getGreeting} from "utilities/helperFunctions";
import config, {THEME} from "configuration/settings.js";
import {PMS_CONSTS} from "configuration/constants.js";
import clsx from "clsx";

const VIDEO_LINKS = {
  onboarding: "https://ensoconnect.com/onboarding-kick-off", // defaults to this if no version provided
  personalized: "https://ensoconnect.com/personalized-demo",
};
const REDIRECT_INTEGRATION_TYPES = ["autohost", "bookingsync"];

export default function Register(props) {
  const classes = useRegisterStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    match: {params},
  } = props;
  const {channel_confirmation, channel, version} = params;
  const greeting = React.useRef(getGreeting());
  const [step, setStep] = React.useState(
    channel || location.state?.originParams?.channel,
  );
  const [demoVersion, setDemoVersion] = React.useState(
    version || location.state?.originParams?.version || "onboarding",
  );
  const [showInfo, setShowInfo] = React.useState(false);
  const [redirect, setRedirect] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [hostData, setHostData] = React.useState({
    email: "",
    business_name: "",
    phone: "",
    integration_data: null,
  });

  React.useEffect(() => {
    if (!channel_confirmation) return;
    setStep("connecting_channel");
    // if user is already logged in (i.e. session exists), redirect to admin page
    Auth.currentAuthenticatedUser().then((user) => {
      window.location.replace(window.location.href.replace("auth", "admin"));
      // TODO: other OAuth (BookingSync)
    });
    handleCallback();
  }, [channel_confirmation]);

  const handleCallback = () => {
    let searchParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (searchParams) {
      let body = {
        integration_type: channel_confirmation,
        signup_fields: searchParams,
      };
      dispatch(
        signUpChannel(
          body,
          (response) => {
            setHostData({
              email: response.create_enso_account.enso_user_email,
              business_name: response.create_enso_account.account_name,
              phone: response.create_enso_account.phone,
              integration_host_id:
                response.create_pms_account.integration_host_id,
              integration_data: response.create_pms_account.integration_data,
              create_pms_account_action_options: response.create_pms_account,
            });
            setStep("form");
          },
          (error) => {
            setErrorMessage(error?.message || "Failed to connect channel");
            setStep("error_connecting");
          },
        ),
      );
    } else {
      setErrorMessage("No credentials provided");
      setStep("error_connecting");
    }
  };

  const tryAuthSignUp = async (email, password) => {
    let cognitoAccount = null;
    try {
      cognitoAccount = await Auth.signUp({
        username: email,
        password: password,
        attributes: {email: email},
      });
    } catch (error) {
      setStep("error");
    }
    return cognitoAccount;
  };

  const createAPIAccount = (formData, ensoUserId, imageUrl) => {
    let data = {
      account_name: formData.company,
      email: formData.email,
      name: formData.name,
      phone: formData.phone,
      profile_picture: imageUrl ? config.s3.URL + imageUrl : false,
      enso_user_id: ensoUserId,
      description: false,
      integration_host_id: hostData.integration_host_id,
      integration_data: hostData.integration_data,
      integration_type: channel_confirmation,
      create_pms_account_action_options:
        hostData.create_pms_account_action_options,
    };
    dispatch(
      signUp(
        data,
        (response) => {
          dispatch(setUser(response.enso_user.enso_user_id));
          const videoLink = localStorage.getItem("signup_redirect_url");
          console.log("REDIRECT", videoLink);
          if (videoLink && videoLink.startsWith("direct")) {
            window.location.href = "/admin/listings";
          } else if (
            videoLink &&
            (videoLink.startsWith("https://ensoconnect.com/") ||
              videoLink.startsWith("https://console.ensoconnect.com/"))
          ) {
            window.location.href = videoLink;
          } else {
            window.location.replace(VIDEO_LINKS[demoVersion]);
          }
        },
        (error) => {
          console.error("ERROR", error);
          setStep("error");
        },
      ),
    );
  };

  const handleSave = async (data) => {
    console.log("data", data);
    setStep("granting_permissions");
    let cognitoAccount = await tryAuthSignUp(data.email, data.password);
    try {
      if (cognitoAccount) {
        await Auth.signIn(data.email, data.password);
        let imageKey = null;
        if (data.image.file) {
          setStep("uploading_photo");
          let imageResponse = await Storage.put(
            `profile_pictures/${cognitoAccount.userSub}`,
            data.image.file,
            {contentType: data.image.file.type},
          );
          imageKey = imageResponse.key;
          console.log("IMAGE RESPONSE");
          console.log(imageResponse);
        }
        setStep("creating_account");
        createAPIAccount(data, cognitoAccount.userSub, imageKey);
      }
    } catch (error) {
      console.error("ERROR", error);
      setStep("error");
    }
  };

  const handlePMSSelection = (selection) => (e) => {
    showInfo && setShowInfo(false);
    setStep(selection);
  };

  const handleInformationClick = () => {
    !showInfo && setShowInfo(true);
  };

  function getCardHeader() {
    switch (step) {
      case "selectaccount":
      case "channels":
      case "pms":
        return (
          <CardHeader
            title={`Good ${greeting.current},`}
            titleTypographyProps={{variant: "h6", className: classes.title}}
            subheader={
              <span>
                Get started below or{" "}
                <Link className={classes.link} href="/auth/login">
                  login
                </Link>
                .
              </span>
            }
            subheaderTypographyProps={{
              variant: "h5",
              className: classes.subheader,
            }}
          />
        );
      case "creating_account":
      case "uploading_photo":
      case "granting_permissions":
      case "connecting_channel":
        return (
          <CardHeader
            title={
              step === "connecting_channel"
                ? "Connecting Account"
                : step === "granting_permissions"
                  ? "Granting Permissions"
                  : step === "uploading_photo"
                    ? "Uploading Photo"
                    : "Creating Account"
            }
            titleTypographyProps={{variant: "h6", className: classes.title}}
          />
        );
      case "form":
        return (
          <CardHeader
            title={"Enso Connect Account Information"}
            titleTypographyProps={{variant: "h6", className: classes.title}}
            subheader="You can change this information later in your settings."
            subheaderTypographyProps={{variant: "body1"}}
          />
        );
      case "error_connecting":
      case "error":
        return (
          <CardHeader
            title={"Account Connection"}
            titleTypographyProps={{variant: "h6", className: classes.title}}
          />
        );
      default:
        return null;
    }
  }

  function getCardContent() {
    switch (step) {
      case "creating_account":
      case "uploading_photo":
      case "granting_permissions":
      case "connecting_channel":
        return (
          <div className={clsx(classes.column, classes.centered)}>
            <CircularProgress color="primary" />
          </div>
        );
      case "form":
        return <RegisterForm hostData={hostData} saveInfo={handleSave} />;
      case "error_connecting":
      case "error":
        return (
          <>
            <Typography variant="h2" className="mt-4" color="error">
              {errorMessage ||
                "There was an error connecting your account. Please try again or contact our client success team for assistance."}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.pmsSelectionBtn}
              onClick={() => setRedirect("/auth/register/pms")}
            >
              Go Back
            </Button>
          </>
        );
    }
  }

  if (!!redirect) return <Redirect push to={redirect} />;
  return (
    <div className={classes.root}>
      <div className={classes.quotesPanel}>
        <div className={classes.quotes}></div>
        <SvgIcon
          viewBox="0 0 687.57 283.26"
          className={classes.ensoLogo}
          component={EnsoLogo}
        />
      </div>
      <div className={classes.content}>
        <Card className={classes.card}>
          {getCardHeader()}
          <CardContent className={classes.cardContent}>
            {getCardContent()}
            {["selectaccount", "channels", "form"].includes(step) && (
              <Typography
                className={classes.greyLink}
                onClick={
                  step === "selectaccount"
                    ? handleInformationClick
                    : handlePMSSelection("selectaccount")
                }
              >
                {step === "selectaccount" ? "Why do we ask?" : "Go Back"}
              </Typography>
            )}
            {showInfo && (
              <Typography
                variant="body1"
                color="textSecondary"
                className="mt-2"
              >
                If you use a property management software, please connect with
                it directly to get the best experience.
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
