import * as types from "../actionTypes";
import * as AccountsAPI from "../api/accountsAPI";
import * as UsersAPI from "../api/usersAPI";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
  setStartKeys,
  errorResponseAlert,
} from "./settingsActions";
import {getInboxData} from "./guestsActions";

export function setUser(data) {
  return {type: types.SET_USER, current_user: data};
}

export function setUserProfile(data) {
  return {type: types.SET_USER_PROFILE, current_user: data};
}

export function editEnsoUser(new_enso_user) {
  return {type: types.EDIT_ENSO_USER, new_enso_user};
}

export function setKey(auth_key) {
  return {type: types.SET_KEY, auth_key: auth_key};
}

export function setBkpWizardUrl(bkpUrl) {
  return {type: types.SET_BKP_URL, bkpUrl};
}

export function setForceBilling(forceBilling) {
  return {type: types.FORCE_BILLING, forceBilling};
}

export function setSelectedViewItem(view, item, props) {
  return {type: types.SET_SELECTED_VIEW_ITEM, view, item, props};
}

// ---------- ACCOUNT ----------
export function getProfile(params, addUsers, onSuccess) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    dispatch(setApiStart("profile"));
    try {
      const response = await UsersAPI.getUser(params);
      const profile = !addUsers ? response : {...user, enso_users: response};
      console.log("GOT PROFILE", profile);
      dispatch(setUserProfile(profile));
      onSuccess && onSuccess(profile);
      dispatch(setApiSuccess("profile"));
    } catch (error) {
      console.log("ERROR - GET PROFILE", error);
      addUsers &&
        dispatch(setUserProfile({...user, enso_users: user.enso_users}));
      dispatch(setApiError("profile"));

      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getUserDetails(enso_user_id, onSuccess) {
  return async (dispatch, getState) => {
    try {
      const response = await UsersAPI.getUserDetails(enso_user_id);
      onSuccess && onSuccess(response);
    } catch (error) {
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function editBranding(body, onSuccess) {
  return (dispatch, getState) => {
    let state = getState().defaultReducer;
    AccountsAPI.postBranding({...state.current_user, body})
      .then((response) => {
        console.log("GOT NEW BRANDING", response);
        dispatch(setUserProfile(Object.assign(state.user_profile, body)));
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("ERROR - GET NEW ACCOUNT", error);
        let ensoError = error?.response?.data?.error?.[0];
        errorResponseAlert(dispatch, ensoError?.title, ensoError?.message);
      });
  };
}

export function createAccount(body, onSuccess, onError) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await UsersAPI.postUser(body);
      console.log("GOT NEW ACCOUNT", response);
      dispatch(
        setUserProfile({
          ...user,
          enso_users: [...user.enso_users, response],
        }),
      );
      dispatch(getProfile(`enso_key=${body.enso_key}`, true));
      successResponseAlert(dispatch, "User created");
      onSuccess && onSuccess();
    } catch (err) {
      console.log("ERROR - GET NEW ACCOUNT", err);
      errorResponseAlert(
        dispatch,
        "Failed to create user",
        "Something went wrong, please try again later",
        err,
      );
      onError && onError();
    }
  };
}

export function editAccount({
  body,
  successMsg,
  disableUpdate,
  disableAlerts,
  errorMsg,
  onSuccess,
  onError,
}) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    try {
      const response = await UsersAPI.patchUser(body);
      console.log("GOT EDITED ACCOUNT RESPONSE", response);
      if (!!response.success) {
        const selectedUser = user_profile.enso_users.find(
          (eu) => eu.enso_user_id === body.body.enso_user_id,
        );
        if (!disableUpdate && !!selectedUser) {
          const newEnsoUser = {...selectedUser, ...body.body.data};
          dispatch(editEnsoUser(newEnsoUser));
        }
        if (
          !disableUpdate &&
          user_profile.enso_user_id === body.body.enso_user_id
        ) {
          dispatch(setUserProfile({...user_profile, ...body.body.data}));
        }
        !disableAlerts &&
          successResponseAlert(
            dispatch,
            successMsg ?? "User updated correctly",
          );
        onSuccess && onSuccess(response);
      } else {
        !disableAlerts &&
          errorResponseAlert(
            dispatch,
            errorMsg ?? "Failed to update user",
            "Something went wrong, please try again later",
          );
        onError && onError();
      }
    } catch (err) {
      console.log("ERROR - EDIT ACCOUNT", err);
      !disableAlerts &&
        errorResponseAlert(
          dispatch,
          errorMsg ?? "Failed to update user",
          "Something went wrong, please try again later",
          err,
        );
      onError && onError();
    }
  };
}

export function deleteAccount(
  enso_key,
  enso_user_id,
  onSuccess = () => null,
  onError = () => null,
) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    const users = user_profile.enso_users;
    try {
      const response = await UsersAPI.delUser(enso_key, enso_user_id);
      console.log(`GOT DELETED ACCOUNT RESPONSE`, response);
      dispatch(
        setUserProfile({
          ...user_profile,
          enso_users: users.filter((u) => u.enso_user_id !== enso_user_id),
        }),
      );
      successResponseAlert(dispatch, "User deleted");
      onSuccess();
    } catch (err) {
      console.log("ERROR - DELETE ACCOUNT", err);
      errorResponseAlert(
        dispatch,
        "Failed to delete user",
        "Something went wrong, please try again later",
        err,
      );
      onError();
    }
  };
}

// --------------- VIEWS ----------------

export function createView({
  view,
  isPrivate = true,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    const newView = {...view, created_by_user: user_profile.enso_user_id};
    const backupViews = {
      public_views: [...(user_profile.public_views ?? [])],
      views: [...(user_profile.views ?? [])],
    };
    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {
        ...user,
        view: {
          view_type: isPrivate ? "private" : "public",
          added_view: newView,
        },
      },
    };

    dispatch(setApiStart("views"));
    dispatch(updateRootUserViews("create", newView, isPrivate));
    try {
      const response = await UsersAPI.patchUser({body});
      if (response.success) {
        successResponseAlert(dispatch, "View created correctly");
        dispatch(getInboxData());
        onSuccess();
        dispatch(setApiSuccess("views"));
      } else {
        dispatch(restoreViews(backupViews));
        errorResponseAlert(
          dispatch,
          "Failed to create view",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("views"));
        onError();
      }
    } catch (error) {
      console.log("ERROR - CREATE VIEW", error);
      dispatch(restoreViews(backupViews));
      errorResponseAlert(
        dispatch,
        "Failed to create view",
        "Something went wrong, please try again later",
      );
      dispatch(setApiError("views"));
      onError();
    }
  };
}

export function editView({view, onSuccess = () => {}, onError = () => {}}) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    const isPrivate = user_profile.views.find((v) => v.id === view.id);
    const backupViews = {
      public_views: [...(user_profile.public_views ?? [])],
      views: [...(user_profile.views ?? [])],
    };
    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {
        ...user,
        view: {
          view_type: isPrivate ? "private" : "public",
          edited_view: view,
        },
      },
    };

    dispatch(setApiStart("views"));
    dispatch(updateRootUserViews("edit", view, isPrivate));
    try {
      const response = await UsersAPI.patchUser({body});
      if (response.success) {
        successResponseAlert(dispatch, "View updated correctly");
        dispatch(getInboxData());
        onSuccess();
        dispatch(setApiSuccess("views"));
      } else {
        dispatch(restoreViews(backupViews));
        errorResponseAlert(
          dispatch,
          "Failed to save changes",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("views"));
        onError();
      }
    } catch (error) {
      console.log("ERROR - EDIT VIEW", error);
      dispatch(restoreViews(backupViews));
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Something went wrong, please try again later",
      );
      dispatch(setApiError("views"));
      onError();
    }
  };
}

export function deleteView({view, onSuccess = () => {}, onError = () => {}}) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    const isPrivate = user_profile.views.find((v) => v.id === view.id);
    const backupViews = {
      public_views: [...(user_profile.public_views ?? [])],
      views: [...(user_profile.views ?? [])],
    };
    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {
        ...user,
        view: {
          view_type: isPrivate ? "private" : "public",
          removed_view: view,
        },
      },
    };

    dispatch(setApiStart("views"));
    dispatch(updateRootUserViews("delete", view, isPrivate));
    try {
      const response = await UsersAPI.patchUser({body});
      if (response.success) {
        successResponseAlert(dispatch, "View deleted correctly");
        dispatch(getInboxData());
        dispatch(setApiSuccess("views"));
        onSuccess();
      } else {
        dispatch(restoreViews(backupViews));
        errorResponseAlert(
          dispatch,
          "Failed to delete view",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("views"));
        onError();
      }
    } catch (error) {
      console.log("ERROR - DELETE VIEW", error);
      dispatch(restoreViews(backupViews));
      errorResponseAlert(
        dispatch,
        "Failed to delete view",
        "Something went wrong, please try again later",
      );
      dispatch(setApiError("views"));
      onError();
    }
  };
}

export function togglePublicView({
  view,
  oldId,
  makePublic = true,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    const backupViews = {
      public_views: [...(user_profile.public_views ?? [])],
      views: [...(user_profile.views ?? [])],
    };
    const bodyDelete = {
      enso_user_id: user_profile.enso_user_id,
      data: {
        ...user,
        view: {
          view_type: makePublic ? "private" : "public",
          removed_view: {...view, id: oldId},
        },
      },
    };
    const bodyCreate = {
      enso_user_id: user_profile.enso_user_id,
      data: {
        ...user,
        view: {view_type: makePublic ? "public" : "private", added_view: view},
      },
    };

    dispatch(setApiStart("views"));
    dispatch(updateRootUserViews("toggle_public", view, makePublic, oldId));
    try {
      const deleteResponse = await UsersAPI.patchUser({body: bodyDelete});
      if (deleteResponse.success) {
        backupViews[makePublic ? "views" : "public_views"] = backupViews[
          makePublic ? "views" : "public_views"
        ].filter((v) => v.id !== oldId);

        const createResponse = await UsersAPI.patchUser({body: bodyCreate});
        if (createResponse.success) {
          successResponseAlert(dispatch, "View updated correctly");
          dispatch(getInboxData());
          dispatch(setApiSuccess("views"));
          onSuccess();
        } else {
          dispatch(restoreViews(backupViews));
          errorResponseAlert(
            dispatch,
            "Failed to update the view",
            "Something went wrong, please try again later",
          );
          dispatch(setApiError("views"));
          onError();
        }
      } else {
        dispatch(restoreViews(backupViews));
        errorResponseAlert(
          dispatch,
          "Failed to update the view",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("views"));
        onError();
      }
    } catch (error) {
      console.log("ERROR - MAKE VIEW PUBLIC/PRIVATE", error);
      dispatch(restoreViews(backupViews));
      errorResponseAlert(
        dispatch,
        "Failed to update the view",
        "Something went wrong, please try again later",
      );
      dispatch(setApiError("views"));
      onError();
    }
  };
}

// --------------- ACCOUNT ACTIONS ----------------

export function signUp(body, onSuccess, onError) {
  return async (dispatch) => {
    try {
      let account = await AccountsAPI.signUp({body: body});
      console.log("SIGN UP RESPONSE", account);
      onSuccess && onSuccess(account);
    } catch (error) {
      errorResponseAlert(dispatch, null, null, error);
      onError && onError(error);
    }
  };
}

export function deleteRootAccount(enso_key, onSuccess, onError) {
  return async (dispatch) => {
    try {
      let account = await AccountsAPI.delRootAccount(enso_key);
      console.log("SUCCESS DELETING ACCOUNT");
      onSuccess && onSuccess(account);
    } catch (error) {
      errorResponseAlert(dispatch, null, null, error);
      onError && onError(error);
    }
  };
}

export function signUpChannel(body, onSuccess, onError) {
  return async (dispatch) => {
    try {
      let account = await AccountsAPI.signUpChannel({body: body});
      console.log("SIGN UP RESPONSE", account);
      onSuccess && onSuccess(account);
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        // If no error handler, show a meaningful error popup
        const errorMessage = error.message || "Failed to connect channel";
        const errorSeverity = error.severity || "error";
        errorResponseAlert(dispatch, errorSeverity, errorMessage, error);
      }
    }
  };
}

export function signUpUrl(body, onSuccess, onError) {
  return async (dispatch) => {
    try {
      let account = await AccountsAPI.signUpUrl({body: body});
      console.log("SIGN UP URL RESPONSE", account);
      onSuccess && onSuccess(account);
    } catch (error) {
      errorResponseAlert(dispatch, null, null, error);
      onError && onError(error);
    }
  };
}

export function registerDevice(token, deviceType, userId, onSuccess, onError) {
  return async (dispatch) => {
    const body = {
      enso_user_id: userId,
      data: {
        mobile_registrations: [
          {
            mobile_registration_id: token,
            device_type: deviceType,
            action: "CREATE",
          },
        ],
      },
    };

    try {
      const response = await UsersAPI.patchUser({body});
      console.log("SUCCESS REGISTER DEVICE", response);
      onSuccess && onSuccess(response);
    } catch (error) {
      console.log("ERROR - REGISTER DEVICE", error);
      onError && onError();
    }
  };
}

// ------------ MANAGED EMAILS ------------
export function sendManagedEmail(body) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await AccountsAPI.postManagedEmail({body: body});
      dispatch(
        setUserProfile({
          ...user,
          managed_emails: response.root.managed_emails,
        }),
      );
    } catch (error) {
      console.log("ERROR - SEND MANAGED EMAIL PROFILE", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function updateManagedPhone(body) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await AccountsAPI.postManagedPhone({body: body});
      dispatch(
        setUserProfile({
          ...user,
          managed_phones: response.root.managed_phones,
        }),
      );
      successResponseAlert(dispatch, "Phone successfully updated");
    } catch (error) {
      console.log("ERROR - SEND MANAGED EMAIL PROFILE", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function purchasePhoneNumber(body) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await AccountsAPI.purchasePhoneNumber({body: body});
      dispatch(
        setUserProfile({
          ...user,
          managed_phones: response.root.managed_phones,
        }),
      );
      successResponseAlert(dispatch, "Phone successfully purchased");
    } catch (error) {
      console.log("ERROR - PURCHASE PHONE", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function deleteManagedPhone(phone) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await AccountsAPI.deleteManagedPhone(phone);
      dispatch(
        setUserProfile({
          ...user,
          managed_phones: response.root.managed_phones,
        }),
      );
    } catch (error) {
      console.log("ERROR - SEND MANAGED EMAIL PROFILE", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function deleteManagedEmail(email) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const response = await AccountsAPI.deleteManagedEmail(email);
      dispatch(
        setUserProfile({
          ...user,
          managed_emails: response.root.managed_emails,
        }),
      );
    } catch (error) {
      console.log("ERROR - SEND MANAGED EMAIL PROFILE", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getManagedEmailSetupStatus(body) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const managedEmailStatus =
        await AccountsAPI.getManagedEmailSetupStatus(body);

      let managedEmailIndex = user.managed_emails.findIndex(
        (me) => me.managed_email === body.managed_email,
      );
      let capabilities =
        user.managed_emails[managedEmailIndex]?.capabilities || [];

      if (
        managedEmailStatus.sending === "success" &&
        !capabilities.includes("send")
      ) {
        capabilities.push("send");
      }
      if (
        managedEmailStatus.receiving === "success" &&
        !capabilities.includes("receive")
      ) {
        capabilities.push("receive");
      }

      user.managed_emails[managedEmailIndex].capabilities = capabilities;
      dispatch(setUserProfile({...user}));
    } catch (error) {
      console.log("GOT EMAIL STATUS ERROR", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function sendManagedEmailVerification(body) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const managedEmailStatus = await AccountsAPI.postManagedEmailSetup({
        body: body,
      });
      let managedEmailIndex = user.managed_emails.findIndex(
        (me) => me.managed_email === body.managed_email,
      );
      let capabilities =
        user.managed_emails[managedEmailIndex]?.capabilities || [];

      if (
        managedEmailStatus.sending === "success" &&
        !capabilities.includes("send")
      ) {
        capabilities.push("send");
      }
      if (
        managedEmailStatus.receiving === "success" &&
        !capabilities.includes("receive")
      ) {
        capabilities.push("receive");
      }

      user.managed_emails[managedEmailIndex].capabilities = capabilities;
      dispatch(setUserProfile({...user}));
      successResponseAlert(dispatch, "Verification email sent");
    } catch (error) {
      console.error("ERROR - SEND VERIFICATION EMAIL", error);
    }
  };
}

// --------------- BRANDING ----------------

export function createBranding(body, onSuccess, onError) {
  return (dispatch) => {
    AccountsAPI.postBranding(body)
      .then((response) => {
        console.log("SUCCESS CREATING BRANDING", response);
        successResponseAlert(dispatch, "Branding created");
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        console.log("ERROR - CREATING BRANDING", error);
        errorResponseAlert(dispatch, null, null, error);
        onError && onError(error);
      });
  };
}

export function updateBranding(body, isOldRoot, onSuccess) {
  return (dispatch, getState) => {
    console.log("POSTING BRANDING");
    let enso_key = getState().defaultReducer.current_user;
    let currentUser = getState().defaultReducer.user_profile;
    if (isOldRoot) {
      AccountsAPI.postRootBranding({...body, enso_key: enso_key})
        .then((response) => {
          //if (!!response.success) {
          onSuccess && onSuccess(response);
          successResponseAlert(dispatch, "Branding successfully updated");
          const newEnsoUser = {...currentUser, ...body};
          dispatch(editEnsoUser(newEnsoUser));
          /*} else {
            console.log("SAVE BRANDING ERROR");
            errorResponseAlert(
              dispatch,
              "Failed to update Branding",
              "Something went wrong, please try again later",
            );
          }*/
        })
        .catch((error) => {
          console.log("SAVE BRANDING ERROR", error);
          errorResponseAlert(dispatch, null, null, error);
        });
    } else {
      AccountsAPI.patchBranding({...body, enso_key: enso_key})
        .then((response) => {
          onSuccess && onSuccess(response);
        })
        .catch((error) => {
          console.log("SAVE BRANDING ERROR", error);
          errorResponseAlert(dispatch, null, null, error);
        });
    }
  };
}

export function deleteBranding(brandingId, onSuccess, onError) {
  return (dispatch) => {
    AccountsAPI.deleteBranding(brandingId)
      .then((response) => {
        console.log("SUCCESS DELETING BRANDING", response);
        onSuccess && onSuccess(brandingId);
      })
      .catch((error) => {
        console.log("ERROR - DELETING BRANDING", error);
        errorResponseAlert(dispatch, null, null, error);
        onError && onError(error);
      });
  };
}

// ---------- CHANNELS ----------
export function getChannels() {
  return async (dispatch, getState) => {
    const user = getState().defaultReducer.user_profile;
    const params = `enso_key=${user.enso_key}`;
    try {
      dispatch(setApiStart("connected_channel_accounts"));
      const channels = await AccountsAPI.getChannels(params);
      const start_key = channels.length === 50;
      dispatch(setStartKeys("connected_channel_accounts", start_key));
      dispatch(
        setUserProfile({
          ...user,
          connected_channels: channels,
        }),
      );
      dispatch(setApiSuccess("connected_channel_accounts"));
    } catch (error) {
      console.log("ERROR - GET CHANNELS", error);
      dispatch(setApiError("connected_channel_accounts"));
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function syncAccount(body, onSuccess, onError) {
  return (dispatch) => {
    AccountsAPI.postAccountsSync({body: body})
      .then((response) => {
        console.log("GOT ACCOUNT DATA", response);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        console.log("ERROR IN SYNCING ACCOUNT", error);
        errorResponseAlert(dispatch, null, null, error);
        onError && onError(error);
      });
  };
}

export function postChannels(body, onSuccess, onError) {
  return (dispatch) => {
    AccountsAPI.postAccount({body: body})
      .then((response) => {
        console.log("GOT SYNC RESPONSE", response);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        console.log("ERROR - POST CHANNELS", error);
        onError && onError(error);
        errorResponseAlert(dispatch, null, null, error);
      });
  };
}

export function deleteConnectedAccount(params, onSuccess) {
  return (dispatch) => {
    AccountsAPI.deleteAccount(params)
      .then((response) => {
        console.log("GOT RESPONSE - DELETE CHANNEL", response);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("ERROR - DELETE CHANNEL", error);
        errorResponseAlert(dispatch, null, null, error);
      });
  };
}

// -------- LINKS -------------
export function getLink(link_type, onSuccess) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const link = await AccountsAPI.getLink(
        `?enso_key=${user.enso_key}&link_type=${link_type}`,
      );
      !!link && dispatch(setBkpWizardUrl(link));
      console.log("GOT LinkL", link);
      onSuccess && onSuccess(link);
    } catch (error) {
      console.log("ERROR - GET Link", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getGuestKeycardLink(guest_id, booking_id, onSuccess) {
  return async (dispatch, getState) => {
    let user = getState().defaultReducer.user_profile;
    try {
      const link = await AccountsAPI.getLink(
        `?enso_key=${user.enso_key}&guest_id=${guest_id}&booking_id=${booking_id}&link_type=keycard`,
      );
      !!link && dispatch(setBkpWizardUrl(link));
      console.log("GOT LinkL", link);
      onSuccess && onSuccess(link);
    } catch (error) {
      console.log("ERROR - GET Link", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

// HELPER FUNCTIONS
function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}

export function updateRootUserViews(operation, view, isPrivate, oldID = null) {
  return (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;

    const currentViews = {
      views: [...(user_profile.views ?? [])],
      public_views: [...(user_profile.public_views ?? [])],
    };

    switch (operation) {
      case "create":
        dispatch(
          setUserProfile({
            ...user_profile,
            views: [...currentViews.views, view],
          }),
        );
        break;
      case "edit":
        dispatch(
          setUserProfile({
            ...user_profile,
            [isPrivate ? "views" : "public_views"]: currentViews[
              isPrivate ? "views" : "public_views"
            ].map((v) => (v.id === view.id ? view : v)),
          }),
        );
        break;
      case "delete":
        dispatch(
          setUserProfile({
            ...user_profile,
            [isPrivate ? "views" : "public_views"]: currentViews[
              isPrivate ? "views" : "public_views"
            ].filter((v) => v.id !== view.id),
          }),
        );
        break;
      case "duplicate":
        dispatch(
          setUserProfile({
            ...user_profile,
            views: [...currentViews.views, view],
          }),
        );
        break;
      case "toggle_public":
        let removeFrom = isPrivate ? "views" : "public_views";
        let addTo = !isPrivate ? "views" : "public_views";
        dispatch(
          setUserProfile({
            ...user_profile,
            [removeFrom]: currentViews[removeFrom].filter(
              (v) => v.id !== oldID,
            ),
            [addTo]: [...currentViews[addTo], view],
          }),
        );
        break;

      default:
        break;
    }
  };
}

export function restoreViews(oldViews) {
  return (dispatch, getState) => {
    const user_profile = getState().defaultReducer.user_profile;
    dispatch(
      setUserProfile({
        ...user_profile,
        ...oldViews,
      }),
    );
  };
}
