import PropTypes from "prop-types";

/**
 * @description Handles differentiation of custom component rendering for any field in the platform.
 * @param {Object} props The properties passed to the component.
 * @param {string} props.type Type of display to render.
 * @param {function} props.renderInput Function to render the input type.
 * @param {function} props.renderFilter Function to render the filter type.
 * @param {function} props.renderDisplay Function to render the display type.
 * @param {function} props.renderCustom Function to render the custom type.
 * @param getLabel
 * @param getOperators
 * @param {function} props.renderLabel Function to render the text type.
 */
const BaseFieldComponent = ({
  type,
  renderInput = () => null,
  renderFilter = () => null,
  renderDisplay = () => null,
  renderCustom = () => null,
  getLabel = () => null,
  getOperators = () => [],
  ...props
}) => {
  switch (type) {
    case "input":
      return renderInput(props);
    case "filter":
      return renderFilter(props);
    case "display":
      return renderDisplay(props);
    case "custom":
      return renderCustom(props);
    case "label":
      return getLabel(props);
    case "operators":
      return getOperators(props);
    default:
      return null;
  }
};

BaseFieldComponent.propTypes = {
  type: PropTypes.oneOf([
    "input",
    "display",
    "custom",
    "label",
    "operators",
    "filter",
  ]).isRequired,
  getOperators: PropTypes.func,
  renderInput: PropTypes.func,
  renderFilter: PropTypes.func,
  renderDisplay: PropTypes.func,
  renderCustom: PropTypes.func,
  getLabel: PropTypes.func,
};

export default BaseFieldComponent;
