import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import headerBG from "assets/img/product_header_bg.jfif";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import ImageSelector from "components/MultiOption/ImageSelector";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    // background: `linear-gradient(0deg, #F5F5F5, #F5F5F5), url(${headerBG})`,
    marginLeft: -30,
    marginRight: -30,
    marginTop: -30,
    marginBottom: 20,
  },
  header: {
    ...THEME.customTypography.dialogHeader,
    "&.light": {color: "#FFF"},
  },
  dialogHeader: {
    ...THEME.customTypography.dialogHeaderVariant,
    "&.light": {color: "#FFF"},
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: theme.spacing(5),
    marginTop: 100,
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  paper: {
    position: "relative",
    borderRadius: 10,
    minWidth: 350,
    width: (props) =>
      !!props.fullWidth ? "calc(100% - 100px)" : "fit-content",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)",
    maxHeight: (props) => props.maxHeight ?? "calc(100% - 100px)",
    "&.-customHeight": {height: (props) => props.customHeight},
    "&.-maxHeight": {maxHeight: "100%"},
  },
  dialogContent: {
    padding: (props) => (!!props.disableContentPadding ? 0 : theme.spacing(5)),
    "&::-webkit-scrollbar": {width: "0!important"},
    "&.preview": {
      overflow: "hidden",
      display: "flex",
      padding: 0,
    },
  },
  helperButton: {
    color: "#A8A8A8",
    fontSize: 14,
    padding: 0,
    minWidth: "fit-content",
    textDecoration: "underline !important",
    backgroundColor: "transparent !important",
  },
  actions: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(4),
    gap: theme.spacing(2),
  },
  confirmBtn: {
    textTransform: "none",
    minWidth: "64.88px",
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: "#2361cc",
      border: "1px solid #2361cc",
    },
  },
  columnsContainer: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&.left": {
      flex: 1,
      overflow: "auto",
      padding: theme.spacing(5),
      "&::-webkit-scrollbar": {width: "0!important"},
    },
    "&.right": {
      background: "rgba(217, 217, 217, 0.10)",
    },
  },
  // Cover entire dialog
  overlay: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: theme.spacing(5),
  },
}));

export default function ContentEditorDialog({
  open,
  title,
  content,
  labelCancel,
  labelConfirm,
  disableConfirm,
  maxWidth = "xs",
  fullWidth = false,
  actionCancel,
  actionConfirm,
  onClose,
  disableExit,
  titleVariant = "title",
  disableXClose = false,
  disableActions = false,
  transitionDuration,
  customConfirmBtn,
  dialogContentExtraClass,
  maxHeight = null,
  customHeight = null,
  disableEnforceFocus = false,
  headerAction = null,
  labelHelperButton,
  actionHelperButton,
  headerClass = null,
  headerLight = false,
  titleIcon = null,
  loadingAction,
  confirmBtnID,
  disableContentPadding,
  headerImageEditable = false,
  headerImageProps = null,
  previewContent = null,
  overlay = null,
}) {
  const classes = useStyles({
    maxHeight,
    customHeight,
    fullWidth,
    headerLight,
    disableContentPadding,
  });

  const handleClose = (event, reason) => {
    if (disableExit && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEnforceFocus={disableEnforceFocus}
      onClose={handleClose}
      classes={{
        paper: clsx(classes.paper, {
          "-customHeight": !!customHeight,
          "-maxHeight": !!previewContent,
        }),
      }}
      disableEscapeKeyDown={disableExit}
      transitionDuration={transitionDuration}
      TransitionProps={{mountOnEnter: true, unmountOnExit: true, timeout: 0}}
    >
      {/* Overlay on top */}
      {overlay && <div className={classes.overlay}>{overlay}</div>}

      {/* 
        Wrap the content and actions in a Box so we can apply a blur filter.
        If 'overlay' is present, the Box is blurred by 5px.
      */}
      <DialogContent
        className={clsx(classes.dialogContent, dialogContentExtraClass, {
          preview: !!previewContent,
        })}
      >
        {previewContent ? (
          <Box className={classes.columnsContainer}>
            <Box className={clsx(classes.col, "left")}>
              <Box className={clsx(classes.dialogTitle, headerClass)}>
                <div className={classes.headerRow}>
                  <div className={classes.titleRow}>
                    {titleIcon}
                    <Typography
                      className={clsx({
                        [classes.dialogHeader]: titleVariant === "header",
                        [classes.header]: titleVariant !== "header",
                        light: !!headerLight,
                      })}
                    >
                      {title}
                    </Typography>
                  </div>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {!!headerImageEditable && (
                      <ImageSelector
                        light={headerLight}
                        {...headerImageProps}
                      />
                    )}
                    {!!headerAction && headerAction}
                  </Box>
                </div>
              </Box>
              {content}
            </Box>
            <Box className={clsx(classes.col, "right")}>
              {!overlay && (
                <Box
                  p={4}
                  width="100%"
                  textAlign="end"
                  style={{marginBottom: -50, zIndex: 100}}
                >
                  <CloseIconButton sm black onClick={onClose} />
                </Box>
              )}
              {previewContent}
            </Box>
          </Box>
        ) : (
          content
        )}
      </DialogContent>

      {!disableActions && (
        <DialogActions disableSpacing className={classes.actions}>
          {labelHelperButton && (
            <Box flex={1}>
              <PrimaryButton
                variant="text"
                size="small"
                className={classes.helperButton}
                label={labelHelperButton}
                onClick={actionHelperButton}
              />
            </Box>
          )}
          {labelCancel && (
            <PrimaryButton
              size="small"
              variant="outlined"
              subdued
              label={labelCancel}
              onClick={actionCancel}
            />
          )}
          {customConfirmBtn ??
            (labelConfirm && (
              <PrimaryButton
                size="small"
                color="secondary"
                id={confirmBtnID}
                className={classes.confirmBtn}
                label={
                  loadingAction ? (
                    <ButtonLabelLoader variant="white" />
                  ) : (
                    labelConfirm
                  )
                }
                onClick={actionConfirm}
                disabled={disableConfirm}
              />
            ))}
        </DialogActions>
      )}
    </Dialog>
  );
}
